import { useTranslation } from "react-i18next";
import {
  Grid
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { changeLang } from "../../store/langSlice";
import { changeLangIndex } from "../../store/langIndexSlice";
import language from "../../utils/language";

export default function Index({appName, privacyPolicUrl, type}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [keyIndex, setKeyIndex] = useState(0)
  const navigate = useNavigate();
  const toNext = function() {
    navigate(`/deleteAccountInfo?lang=${localStorage.getItem('lang') || 'en_GB'}&privacyPolicUrl=${privacyPolicUrl}&type=${type}&backUrl=${location.pathname}`)
  }
  const query = new URLSearchParams(location.search).get('lang');
  useEffect(()=> {
    let temp = {
      sysLanguageId: 7,
      sysLanguageCode: "en_GB",
      sysLanguageName: "English",
    }
    if (query) {
      const langs = language
      for (let i in langs) {
        if (langs[i].sysLanguageCode == query) {
          temp = langs[i]
          setKeyIndex(i)
          break;
        }
      }
    }
    i18n.changeLanguage(temp.sysLanguageCode);
    localStorage.setItem("lang", temp.sysLanguageCode);
    localStorage.setItem("langIndex", keyIndex);
    dispatch(changeLang(temp.sysLanguageCode));
    dispatch(changeLangIndex(keyIndex));
  }, [query]) // eslint-disable-line

  return (
    <Grid container sx={{padding: '0 16px'}}>
      <Grid item xs={12} sx={{
            color: '#957ec2',
            fontSize: 22,
            textAlign: 'center',
            fontWeight: 600,
            marginTop: '11px',
            marginBottom: '16px'
      }}>
        {t("deleteUser.info1")}
      </Grid>
      <Grid item xs={12} sx={{
            fontSize: 15,
            fontWeight: 400,
            boxSizing: 'border-box',
            textIndent: '25px'
      }}>
        {
          t("deleteUser.info2").replace(new RegExp("########", "gm"), appName)
        }
      </Grid>
      <Grid item xs={12} sx={{
        padding: '16px 22px',
        width: 'calc(100% - 1.6rem)',
        border: '.02667rem solid #2d2d2d',
        marginTop: '22px',
      }} onClick={toNext}>
        <div style={{
          fontSize: '17px',
          fontWeight: 700,
          color: '#2d2d2d'
        }}>{t("deleteUser.info1")}</div>
        <div style={{
            fontSize: 15,
            fontWeight: 400,
            boxSizing: 'border-box',
            textIndent: '25px',
            padding: '0 16px',
            marginTop: '11px'
      }}>{t("deleteUser.info4")}</div>
        <div style={{
            color: '#957ec2',
            fontSize: 17,
            textAlign: 'end',
            fontWeight: 700,
            marginTop: '11px'
      }}>{t("deleteUser.info5")}</div>
      </Grid>
      <Grid item xs={12} sx={{position: 'fixed',bottom: 0,left: 0, textAlign: 'center', fontSize: 13, alignContent: 'flex-end'}}>
        {t("deleteUser.info6")}
      </Grid>
    </Grid>
  );
}
