import App from "../App";
import Home from "../view/home/index";
import Feature from '../view/feature/Web';
import Hardware from '../view/hardware/index';
import ContactUs from '../view/contactUs/index';
import Help from '../view/help/index';
import Admin from '../view/businessType/admin';
import Kitchen from '../view/businessType/kitchen';
import Pos from '../view/businessType/pos';
import Sale from '../view/businessType/sale';
import Selfhelp from '../view/businessType/selfhelp';
import Shop from '../view/businessType/shop';
import DiningCar from '../view/businessType/diningCar';
import Scan from '../view/businessType/scan';
import Customer from '../view/businessType/customer';

import Bar from '../view/scene/bar';
import Cafe from '../view/scene/cafe';
import Clothing from '../view/scene/clothing';
import Minimart from '../view/scene/minimart';
import Restaurant from '../view/scene/restaurant';
import DiningCar2 from '../view/scene/diningCar';

import Price from '../view/price/index';
import Partners from '../view/partners/page';

import HardwareInfo from '../view/hardware/goods/info';

import PrivacyPolic from '../view/privacyPolic';
import DeleteUser from '../view/deleteUser';
import DeleteAccountInfo from '../view/deleteUser/info';
import UserAgreement from '../view/userAgreement';
import ToposfyWebSite from '../view/toposfyWebSite';

import {
  createHashRouter
} from "react-router-dom";

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "feature/index",
        element: <Feature />,
      },
      {
        path: "hardware/index",
        element: <Hardware />,
      },
      {
        path: "partners/index",
        element: <Partners />,
      },
      {
        path: "contactUs/index",
        element: <ContactUs />,
      },
      {
        path: "help/index",
        element: <Help />,
      },

      {
        path: "type/admin",
        element: <Admin />,
      },
      {
        path: "type/kitchen",
        element: <Kitchen />,
      },
      {
        path: "type/pos",
        element: <Pos />,
      },
      {
        path: "type/sale",
        element: <Sale />,
      },
      {
        path: "type/selfhelp",
        element: <Selfhelp />,
      },
      {
        path: "type/shop",
        element: <Shop />,
      },
      {
        path: "type/diningCar",
        element: <DiningCar />,
      },
      {
        path: "type/scan",
        element: <Scan />,
      },
      {
        path: "type/customer",
        element: <Customer />,
      },

      {
        path: "scene/bar",
        element: <Bar />,
      },
      {
        path: "scene/clothing",
        element: <Clothing />,
      },
      {
        path: "scene/restaurant",
        element: <Restaurant />,
      },
      {
        path: "scene/cafe",
        element: <Cafe />,
      },
      {
        path: "scene/minimart",
        element: <Minimart />,
      },
      {
        path: "scene/diningCar",
        element: <DiningCar2 />,
      },
      {
        path: "price/index",
        element: <Price />,
      },
      {
        path: "hardware/goods/info/:id",
        element: <HardwareInfo />,
      },
    ]
  },
  // 收银
  {
    path: "privacyPolic",
    element: <PrivacyPolic appName="Toposfy POS" />
  },
  {
    path: "userAgreement",
    element: <UserAgreement appName="Toposfy POS" />,
  },
  {
    path: "deleteAccountIndex",
    element: <DeleteUser appName="Toposfy POS" privacyPolicUrl="privacyPolic" type="1" />,
  },
  {
    path: "deleteAccountInfo",
    element: <DeleteAccountInfo />,
  },
  // 统计APP
  {
    path: "statPrivacyPolic",
    element: <PrivacyPolic appName="Toposfy Dashboard" />,
  },
  {
    path: "statUserAgreement",
    element: <UserAgreement appName="Toposfy Dashboard" />,
  },
  {
    path: "deleteStatAccountIndex",
    element: <DeleteUser appName="Toposfy Dashboard" privacyPolicUrl="statPrivacyPolic" type="2" />,
  },
  // 交通费APP
  {
    path: "ontaxePrivacyPolic",
    element: <PrivacyPolic appName="Ontaxe POS" />,
  },
  {
    path: "ontaxeUserAgreement",
    element: <UserAgreement appName="Ontaxe POS" />,
  },
  {
    path: "deleteOntaxeAccountIndex",
    element: <DeleteUser appName="Ontaxe POS" privacyPolicUrl="ontaxePrivacyPolic" type="1" />,
  },
  // 交通费统计APP
  {
    path: "statOntaxePrivacyPolic",
    element: <PrivacyPolic appName="Ontaxe Dashboard" />,
  },
  {
    path: "statOntaxeUserAgreement",
    element: <UserAgreement appName="Ontaxe Dashboard" />,
  },
  {
    path: "deleteOntaxeDashboardAccountIndex",
    element: <DeleteUser appName="Ontaxe Dashboard" privacyPolicUrl="statOntaxePrivacyPolic" type="2" />,
  },
  // 在线商城
  {
    path: "shopPrivacyPolic",
    element: <PrivacyPolic appName="Toposfy" />,
  },
  {
    path: "shopUserAgreement",
    element: <UserAgreement appName="Toposfy" />,
  },
  {
    path: "deleteShopAccountIndex",
    element: <DeleteUser appName="Toposfy" privacyPolicUrl="shopPrivacyPolic" type="3" />,
  },
  // 后厨显示屏
  {
    path: "kdsPrivacyPolic",
    element: <PrivacyPolic appName="Toposfy KDS" />,
  },
  {
    path: "kdsUserAgreement",
    element: <UserAgreement appName="Toposfy KDS" />,
  },
  {
    path: "deleteKdsAccountIndex",
    element: <DeleteUser appName="Toposfy KDS" privacyPolicUrl="kdsPrivacyPolic" type="5" />,
  },
  // 自助点餐机
  {
    path: "kioskPrivacyPolic",
    element: <PrivacyPolic appName="Toposfy Kiosk" />,
  },
  {
    path: "kioskUserAgreement",
    element: <UserAgreement appName="Toposfy Kiosk" />,
  },
  {
    path: "deleteKioskAccountIndex",
    element: <DeleteUser appName="Toposfy Kiosk" privacyPolicUrl="kioskPrivacyPolic" type="4" />,
  },
  // 顾客显示屏
  {
    path: "cdsPrivacyPolic",
    element: <PrivacyPolic appName="Toposfy CDS" />,
  },
  {
    path: "cdsUserAgreement",
    element: <UserAgreement appName="Toposfy CDS" />,
  },
  {
    path: "deletecdsAccountIndex",
    element: <DeleteUser appName="Toposfy CDS" privacyPolicUrl="cdsPrivacyPolic" type="6" />,
  },
  // 运营APP
  {
    path: "CRMPrivacyPolic",
    element: <PrivacyPolic appName="CRM" />,
  },
  {
    path: "CRMUserAgreement",
    element: <UserAgreement appName="CRM" />,
  },
  {
    path: "deleteCRMAccountIndex",
    element: <DeleteUser appName="CRM" privacyPolicUrl="CRMPrivacyPolic" type="1" />,
  },
  {
    path: "toposfyWebSite",
    element: <ToposfyWebSite/>,
  },
]);
export default router;
