export const datas = {
  en_GB: [
    " ######## Privacy Policy ",
    " 【Effective Date】：2024-7-23 ",
    " 【Update time】：2024-7-23 ",
    "",
    " Prompt terms ",
    " Before using any product or service of [########], please be sure to read this policy carefully and thoroughly understand it, especially the terms marked in bold/bold underline. You should read them carefully and start using them only after you have fully understood and agreed to them. If you have any questions, comments or suggestions about the content of this policy, you can contact us through the various contact methods provided by [########]. ",
    "",
    ' Your trust is very important to us. We are fully aware of the importance of personal information to you. We will take corresponding security protection measures in accordance with the requirements of laws and regulations and try our best to protect your personal information. In view of this, [#######] service provider (or "we" or "[########]") formulates this privacy policy (hereinafter referred to as "this policy/this privacy policy") and reminds you: This policy applies to all products and services provided by [Toposfy Dashboard] ',
    "",
    " It should be noted that this policy does not apply to services provided to you by other third parties, nor does it apply to products or services that have independently set up privacy policies in [########]. ",
    "",
    " Part I. Definitions ",
    " 【########】Service provider: 【Chengdu Huitong Times Software Co., Ltd】 ",
    "",
    " Personal information: refers to any information recorded electronically or otherwise that can identify a specific natural person alone or in combination with other information or reflect the activities of a specific natural person. ",
    "",
    " Personal sensitive information: includes email addresses and order information. ",
    "",
    " Personal information deletion: refers to the act of removing personal information from systems involved in implementing daily business functions so that it remains inaccessible and cannot be retrieved. ",
    "",
    " Children: refers to minors under the age of fourteen. ",
    "",
    " Unless otherwise agreed, the definitions used in this policy have the same meanings as those in the [########] Platform User Agreement. ",
    "",
    " Second Section Privacy Policy ",
    " This privacy policy section will help you understand the following: ",
    "",
    " I. How do we collect and use your information ",
    "",
    " 2. How do we use Cookies ",
    "",
    " 3. How do we share, transfer, and publicly disclose your information ?",
    "",
    " IV. How do we store your information ?",
    "",
    " V. How do we protect your information ?",
    "",
    " VI. How do you manage your information ?",
    "",
    " VII. How your information is transferred globally ",
    "",
    " VIII. How to update this privacy policy ",
    "",
    " IX. How to contact us ",
    "",
    " I. How do we collect and use your information ",
    " When you use our products and/or services, we need/need to collect and use your personal information, including the following two types: ",
    "",
    " 1. In order to provide you with the basic functions of our products and/or services, we will collect and obtain your personal information and device information (IMEI, device MAC address, clipboard, device serial number, andridID), and application installation list in accordance with relevant regulations. If you refuse to provide the corresponding information, you will not be able to use our products and/or services normally, but it will not affect your browsing of our web pages and client pages; ",
    "",
    " 2. In order to provide you with additional functions of our products and/or services, you may choose to authorize us to collect and use your personal sensitive information. If you refuse to provide it, you will not be able to use the relevant additional functions normally or achieve the functional effects we intend to achieve, but it will not affect your normal use of the basic functions of our products and/or services. ",
    "",
    " You understand and agree that: ",
    "",
    " 1. We are committed to creating a variety of products and services to meet your needs. Because we provide you with a wide variety of products and services, and different users choose to use different specific product/service scopes, the basic/additional functions and the types and scopes of personal information collected and used will be different. Please refer to the specific product/service functions; ",
    "",
    " 2. In order to bring you better product and service experience, we are continuously working to improve our technology. As a result, we will launch new or optimized features from time to time, which will require re-collection and use of your personal information or change the purpose or method of using personal information. In this regard, we will update this policy, pop-up windows, page prompts to explain to you the purpose, scope and use of the corresponding information, and seek your explicit consent. In this process, if you have any questions, comments or suggestions, you can contact us through the various contact methods provided by [########], and we will answer you as soon as possible. ",
    "",
    " We will collect and use your personal information to achieve the following functions in this policy: ",
    "",
    " (I) Helping you become our registered user ",
    "",
    " 1. Basic registered user services ",
    "",
    " To register as a registered user and use our registered user services, you need to provide your mobile phone number, email address, and intended registered user name and password to create a [########] platform account. If you only need to use browsing and search services, you do not need to register as our registered user and provide the above information. ",
    "",
    " When you log in to your account, we will verify your registered user identity based on the above information you provide to ensure that we are providing services for you personally. If there is a scenario where your registered user identity needs to be determined according to law (including the identification of relevant subjects in administrative law enforcement or judicial proceedings), you authorize us to obtain the relevant authentication information of your corresponding payment account for the above purpose. ",
    "",
    " We will provide you with the basic rights and interests corresponding to the corresponding registered user based on your registered user account usage. ",
    "",
    " 2. Additional registered user services ",
    "",
    " If you choose to provide your real name, gender, date of birth, and place of residence, we can provide you with more personalized registered user services. To ensure transaction identification, your account nickname and avatar will be publicly displayed. ",
    "",
    " Authorized login: We will obtain your third-party account information from a third party based on your authorization, and bind it to your [########] platform account, so that you can directly log in and use our products and/or services through a third-party account. We will use your relevant information within the scope of your authorization. ",
    "",
    " 3. Account information display: If you already have a [########] platform account, we will display your above personal information in the [########] platform services, as well as the operations you perform on the [########] platform or in products and services associated with the [########] platform account (you can use our products or services through the [########] platform account at the [########] portal or other product/service portals we provide), including centralized display of your personal information and transaction orders through the [########] platform account. We will respect your choices regarding the [########] platform services and [########] platform account settings. ",
    "",
    " (ii) Provide you with information display of goods or services ",
    "",
    " During your use of our services, in order to identify abnormal account status, understand product adaptability, and provide you with page displays and search results that better meet your needs, we will automatically collect your usage information and store it as network log information, including: ",
    "",
    " Device information: We will receive and record information about the device you are using (including device model, operating system version, device settings, unique device identifier, device environment software and hardware feature information), and device location information (including your authorized GPS location) based on your specific operations during software installation and/or use. ",
    "",
    " Service log information: When you use the products or services provided by our website or client, we will automatically collect your detailed usage of our services and save them as service logs, including browsing, clicking, searching, collecting, adding to shopping cart, transactions, after-sales, sharing information, publishing information, as well as IP address, browser type, telecom operator, language used, access date and time. ",
    "",
    " Please note that individual device information and service log information cannot identify a specific natural person. If we combine such non-personal information with other information to identify a specific natural person, or use it in combination with personal information, such non-personal information will be considered personal information during the combined use period. We will anonymize and de-identify such information unless you authorize us or otherwise required by laws and regulations. ",
    "",
    "",
    " In addition, we will also use your above information to continuously improve and optimize the above functions. ",
    "",
    "",
    " In order for us to promptly learn and confirm your payment progress and status and provide you with after-sales and dispute resolution services, you agree that we may collect payment progress information from your selected transaction partner, payment company or other financial institution of your choice. ",
    "",
    " (iii) To help deliver goods or services to you ",
    "",
    " To ensure that the goods and/or services you purchased can be delivered smoothly, safely and accurately, we will disclose the order-related delivery information to the logistics and distribution company that provides logistics information systems and technical services for the [########] platform, and the logistics and distribution company will synchronize the relevant delivery information with the corresponding logistics and distribution entity according to the designation of the entity providing the goods and/or services. You know and agree that the corresponding logistics and distribution entity will inevitably know and use your delivery information for the purpose of completing the delivery. ",
    "",
    "",
    " (IV) Customer Service and Dispute Resolution ",
    "",
    " When you contact us or submit an application for after-sales service or dispute resolution, in order to protect the security of your account and system, we need you to provide necessary personal information to verify your identity as a registered user. ",
    "",
    " In order to facilitate contact with you, help you solve the problem as soon as possible or record the solution and results of the relevant problem, we will save your communication/call records and related content (including account information, order information, other information you provide to prove relevant facts, or contact information you leave). If you make inquiries, complaints or provide suggestions for specific orders, we will use your account information and order information. ",
    "",
    " (V) Providing you with security ",
    "",
    " In order to improve the security of your use of services provided by us and our affiliates and partners, protect the personal and property safety of you or other users or the public from infringement, better prevent phishing websites, fraud, network vulnerabilities, computer viruses, network attacks, network intrusion security risks, and more accurately identify violations of laws and regulations or relevant agreement rules of [########], we use or integrate your registered user information, transaction information, device information, service log information, and information shared by our affiliates and partners with your authorization or in accordance with the law to comprehensively judge your account and transaction risks, conduct identity authentication, detect and prevent security incidents, and take necessary recording, auditing, analysis, and disposal measures in accordance with the law. ",
    "",
    " (VI) Provide you with other additional services ",
    "",
    " In order to provide you with more convenient, higher quality and personalized products and/or services and strive to improve your experience, we will collect, cache and use your personal information in the following additional services provided to you. If you do not provide this information, it will not affect your use of the basic browsing and search services of [########], but you will not be able to obtain the user experience brought to you by these additional services. These additional services include: ",
    "",
    " 1. Additional services based on camera/camera: You can use this function after turning on the camera (camera) permission. Even if you have agreed to turn on the camera (camera) permission, we will only obtain photo information through the camera when you actively click the corresponding icon or record a video. ",
    "",
    " 2. Additional services based on reading and writing external storage cards: After enabling storage permissions, you can use this function to upload your photos/pictures/videos to post comments/share or provide proof when communicating with customer service. ",
    "",
    " 3. Additional services based on reading mobile phone status and identity information: After you enable the permission, we will collect your IMEI, device MAC address, device serial number, Android ID, and installed application list, and confirm your account security based on your device to achieve security risk control. ",
    "",
    " 4. Additional functions based on viewing WLAN connection: view wifi connection status to ensure network reliability. ",
    "",
    "",
    " 5. Additional services based on photo album (photo library) image access and upload, external storage, caching, and publishing image functions: After enabling the photo album permission, you can use this function to upload your photos/images, post comments/share, take photos to shop, or communicate with customer service to provide proof. We will use the photos/images you upload to identify the goods or services you need to purchase, or use the comment information containing the photos or images you uploaded. If you refuse to authorize, you will not be able to use this function, but it will not affect your normal use of other functions of [########]. ",
    "",
    " 6. Additional services based on installed applications: The platform will regularly update the application client. When updating, you can open the APP to directly update, install and use the new version. ",
    "",
    " 7. Additional services based on the application to obtain information about the currently running tasks: When the platform collects statistics on APP data, it will judge the APP page. ",
    "",
    " 8. Additional services based on keeping the APP awake: When you use this application, the background will automatically synchronize data. ",
    "",
    " 9. In order to provide you with more comprehensive information about the products or services you need and provide you with personalized or non-personalized services, we will extract the characteristics of your browsing, search preferences, behavioral habits, and location information based on your browsing and search history, device information, location information, and order information.",
    "",
    " You understand and agree that the above additional services require you to enable access to your location information (geolocation), camera, photo album, calendar, and external storage in your device to collect and use the information involved in these permissions. Please note that by enabling any permission, you authorize us to collect and use relevant personal information to provide you with the corresponding services. Once you close any permission, it means that you have cancelled the authorization. We will no longer continue to collect and use relevant personal information based on the corresponding permission, and we will not be able to provide you with the services corresponding to the permission. However, your decision to close the permission will not affect the collection and use of information previously based on your authorization. ",
    "",
    " (VII) Others ",
    "",
    " 1. If the information you provide contains other users' personal information, you must ensure that you have obtained legal authorization before providing such personal information to [########]. If it involves children's personal information, you must obtain the consent of the corresponding child's guardian before publishing. In the above circumstances, the guardian has the right to contact us through the means of Article 10 of this Policy to request correction or deletion of content involving children's personal information. ",
    "",
    " 2. If we use information for other purposes not specified in this policy, or use information collected for a specific purpose for other purposes, or if we actively obtain your personal information from a third party, we will obtain your consent in advance. ",
    "",
    " If we obtain your information indirectly from a third party, we will clearly require the third party to collect personal information in writing after obtaining your consent in accordance with the law before collection, and inform you of the shared information content. Sensitive information must be confirmed by you before it is provided to us for use. We require the third party to make a commitment to the legality and compliance of the source of personal information. If the third party violates the law, we will clearly require the other party to bear the corresponding legal responsibility. At the same time, we will strengthen the security of personal information (including sensitive information reporting, encrypted storage of sensitive information, and access control). We will protect indirectly obtained personal information with protection means and measures no less than those we use for our own user personal information. ",
    "",
    " 3. Exceptions to obtaining authorization consent ",
    "",
    " You fully understand and agree that we do not need your authorization to collect and use your personal information in the following circumstances, and we will not respond to your requests for correction/modification, deletion, cancellation, withdrawal of consent, or request for information: ",
    "",
    " (1) related to national security and national defense security; ",
    "",
    " (2) related to public safety, public health, or major public interests; ",
    "",
    " (3) related to criminal investigation, prosecution, trial and execution of judgments or administrative law enforcement; ",
    "",
    " (4) For the purpose of protecting the major legal rights and interests of you or other individuals related to life and property, but it is difficult to obtain the consent of the individual; ",
    "",
    " (5) Personal information that you disclose to the public on your own initiative; ",
    "",
    " (6) Collecting personal information from legally disclosed information, such as legal news reports and government information disclosure channels. ",
    "",
    " (7) Necessary for signing and performing relevant agreements or other written documents with you; ",
    "",
    " (8) Necessary for maintaining the safe and stable operation of the products and/or services provided, and for discovering and handling product and/or service failures; ",
    "",
    " (9) Necessary for legitimate news reporting; ",
    "",
    " (10) When academic research institutions conduct statistical or academic research based on public interests and provide the results of academic research or descriptions to the public, they shall de-identify the personal information contained in the results; ",
    "",
    " (11) Other circumstances prescribed by laws and regulations. ",
    "",
    " Please be aware that, in accordance with applicable laws, if we take technical measures and other necessary measures to process personal information so that the data recipient cannot re-identify a specific individual and cannot restore it, or we conduct de-identified research, statistical analysis and prediction on the collected information to improve the content and layout of [########], provide product or service support for business decisions, and improve our products and services (including using anonymous data for machine learning or model algorithm training), then the use of such processed data does not require additional notification to you and your consent. ",
    "",
    " 4. If we cease to operate [########] products or services, we will promptly stop collecting your personal information, notify you of the cessation of operations by individual delivery or announcement, and delete or anonymize the personal information we hold related to the discontinued business. If it involves children's personal information, we will promptly inform the children's guardians of the cessation of operations. ",
    "",
    " 2. How do we use Cookies ",
    " To provide you with an easier access experience and recommend content that interests you, we will store small data files called cookies on your mobile device. Cookies usually contain identifiers, site names, and some numbers and characters. With cookies, we can store your account information, product records, order records, and product data. ",
    "",
    " If you do not agree that we store small data files of cookies on your mobile device, you can stop using [########]. ",
    "",
    " 3. How do we share, transfer, and publicly disclose your information ?",
    " （一）Sharing ",
    "",
    " We will not share your personal information with companies, organizations, and individuals other than [########] service providers, except in the following circumstances: ",
    "",
    " 1. Sharing under legal circumstances: We will share your personal information in accordance with laws and regulations, litigation, dispute resolution needs, or requirements made by administrative and judicial authorities in accordance with the law. ",
    "",
    " 2. Sharing with explicit consent: We will share your personal information with other parties after obtaining your explicit consent. ",
    "",
    " 3. Sharing with your active choice: When you purchase goods or services through the [########] platform, we will share the necessary transaction-related information in your order information with the providers of relevant goods or services based on your choice to meet your transaction and after-sales service needs. ",
    "",
    " 4. Sharing with affiliated companies: In order to facilitate us to provide you with products and services based on the [########] platform account, recommend information that interests you, identify abnormalities in registered user accounts, and protect the personal and property safety of [########] affiliated companies or other users or the public from infringement, your personal information will be shared with our affiliated companies and/or their designated service providers. We will only share necessary personal information and will be subject to the purposes stated in this privacy policy. If we share your personal sensitive information or an affiliated company changes the use and processing purpose of personal information, we will ask for your authorization and consent again. ",
    "",
    " 5. Sharing with authorized partners: We entrust authorized partners to provide you with certain services or perform functions on our behalf. We will only share your information for the legitimate, proper, necessary, specific and clear purposes stated in this Privacy Policy. Authorized partners can only access the information required to perform their duties and may not use this information for any other purpose. We do not allow partners to sub-entrust with personal information involving children. ",
    "",
    " Currently, our authorized partners include the following types: ",
    "",
    " (1) Merchants, service providers and other partners. We send information to merchants, service providers and other partners who support our business. Such support includes providing technical infrastructure services entrusted by us, analyzing the use of our services, providing customer service, payment convenience or conducting academic research and surveys. This information is difficult to associate with your identity information. This information will help us analyze the effectiveness of related services. ",
    "",
    " In order to ensure the stable operation and function realization of our client and enable you to use and enjoy more services and functions, our application will embed the SDK of authorized partners. We will conduct strict security testing on the application program interface (API) and software tool development kit (SDK) used by authorized partners to obtain relevant information, and agree with the authorized partners on strict data protection measures to process personal information in accordance with this policy and any other relevant confidentiality and security measures. ",
    "",
    " Specifications are as follows: ",
    "",
    " ",
    " SDK name: KTPsdk ",
    "",
    " Manufacturer: Shenzhen Jintaiyi Electronics Co., Ltd. ",
    "",
    " Collect information type: Get the mobile phone Bluetooth list and scan the Bluetooth devices around the mobile phone ",
    "",
    " Purpose: Used to read the Bluetooth list on a specific device, connect to Bluetooth, and send information via Bluetooth. ",
    " (Specific equipment: wireless POS terminal produced by Shenzhen Jintaiyi Electronics Co., Ltd.) ",
    "",
    " Sensitive system permissions used: Bluetooth scanning permission, Bluetooth connection permission, location permission. ",
    "",
    " Specific usage scenarios: connect to Bluetooth, Bluetooth connection permission. Scan nearby Bluetooth, Bluetooth scanning permission, location permission. ",
    "",
    "",
    "",
    " SDK name: flutter_bluetooth_serial ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Collect information type: Get the mobile phone Bluetooth list and scan the Bluetooth devices around the mobile phone ",
    "",
    " Purpose: Read device Bluetooth list, connect to Bluetooth, and send information via Bluetooth. ",
    "",
    " Sensitive system permissions used: Bluetooth scanning permission, Bluetooth connection permission, location permission. ",
    "",
    " Specific usage scenarios: connect to Bluetooth, Bluetooth connection permission. Scan nearby Bluetooth, Bluetooth scanning permission, location permission. ",
    "",
    "",
    " SDK name: image_picker ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Type of information collected: obtain mobile phone read and write storage permissions, obtain camera usage permissions ",
    "",
    " Purpose: Used to select images from the image library and to take new photos using the camera. ",
    "",
    " Sensitive system permissions used: read and write external storage permissions, camera permissions ",
    "",
    " Specific usage scenarios: Setting and modifying product photo functions using camera permissions and storage permissions. ",
    "",
    "",
    "",
    " SDK name: vibration ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Collect information type: obtain phone vibration permission ",
    "",
    " Purpose: vibrate the phone after successful code scanning. ",
    "",
    " Sensitive system permissions used: phone vibration permission ",
    "",
    " Specific usage scenario: vibrate the phone after successful code scanning, vibration permission. ",
    "",
    "",
    "",
    " SDK name: qr_code_scanner ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Collect information type: Get camera usage permission ",
    "",
    " Purpose: Scan QR codes and barcodes. ",
    "",
    " Sensitive system permissions used: camera usage permissions ",
    "",
    " Specific usage scenarios: Scan product barcodes, camera usage permissions, scan receipt QR codes, camera usage permissions. ",
    "",
    "",
    "",
    " SDK name: path_provider ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Collect information type: obtain mobile phone read and write storage permissions ",
    "",
    " Purpose: Used to find common locations on the file system. ",
    "",
    " Sensitive system permissions used: read and write external storage permissions ",
    "",
    " Specific usage scenarios: Get the external storage address of the APP, read and write external storage permissions, create a folder in the external storage address, and read and write external storage permissions. ",
    "",
    "",
    " SDK name: open_filex ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Collect information type: obtain mobile phone read and write storage permissions ",
    "",
    " Purpose: Used to open the specified file in the file system. ",
    "",
    " Sensitive system permissions used: read and write external storage permissions ",
    "",
    " Specific usage scenario: After the software update installation package is downloaded to the external storage, open the file to complete the update, read and write external storage permissions. ",
    "",
    "",
    "",
    " SDK name: flutter_downloader ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Collected information type: obtain mobile phone read and write storage permissions, obtain mobile phone notification permissions ",
    "",
    " Purpose: A plugin for creating and managing download tasks. ",
    "",
    " Sensitive system permissions used: read and write external storage permissions, get phone notification permissions ",
    "",
    " Specific usage scenarios: software download update installation package external storage, read and write external storage permissions, notification bar display download information and progress, notification permissions. ",
    "",
    "",
    "",
    " SDK name: permission_handler ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Information type collected: No specific permissions required ",
    "",
    " Purpose: A plug-in for managing and requesting application permissions. ",
    "",
    " Sensitive system permissions used: related to the permissions requested (e.g. camera, location, contacts) ",
    "",
    " Specific usage scenarios: request user authorization to access device features (such as camera, location), check and manage permission status. ",
    "",
    "",
    " SDK name: share_plus ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Information type collected: No specific permissions required ",
    "",
    " Purpose: A plug-in for sharing content within the app. ",
    "",
    " Sensitive system permissions used: No specific sensitive permissions ",
    "",
    " Specific usage scenarios: Users can use plugins to share text, images, files and other content to other applications or platforms, such as social media, messaging apps or email clients. ",
    "",
    "",
    "",
    " SDK name: camera ",
    "",
    " Manufacturer: third-party open source plug-in ",
    "",
    " Information type collected: No specific permissions required ",
    "",
    " Purpose: A plug-in for accessing and controlling the device camera in your app. ",
    "",
    " Sensitive system permissions used: Camera permissions (used to access the device camera) ",
    "",
    " Specific usage scenarios: taking photos, recording videos, previewing camera contents, etc., usually used in applications that require camera functions. ",
    "",
    "",
    "",
    " ",
    " Personal information field definition and exhaustive list ",
    "",
    " Device MAC address: The MAC address of the device, confirming the location of the mobile device ",
    "",
    " Unique device identification code: IDFA for iOS devices, IMEI, oaid, AndroidId, UUID for Android devices (Android will collect multiple identification codes) ",
    "",
    " Device IP address: mobile Internet connection protocol, confirm the operator service of the network connection ",
    "",
    " Device information: device brand, device model, operating system, operating system version ",
    "",
    " Network information: current network environment (such as WIFI, 5G, 4G, 3G, 2G) ",
    "",
    "",
    " (II) Transfer ",
    "",
    " We will not transfer your personal information to any company, organization or individual, except in the following circumstances: ",
    "",
    " 1. Transfer with explicit consent: We will transfer your personal information to other parties with your explicit consent; ",
    "",
    " 2. In the event of a merger, acquisition, or bankruptcy liquidation of the service provider of [########], or other circumstances involving a merger, acquisition, or bankruptcy liquidation, if personal information is transferred, we will require the new company or organization holding your personal information to continue to be bound by this policy, otherwise we will require the company, organization, or individual to re-ask your authorization and consent. ",
    "",
    " (III) Public Disclosure ",
    "",
    " We will only disclose your personal information publicly in the following circumstances: ",
    "",
    " 1. With your explicit consent or based on your active choice, we will publicly disclose your personal information; ",
    "",
    " 2. If we determine that you have violated laws and regulations or seriously violated the relevant agreements and rules of the [########] platform, or to protect the personal and property safety of [########] platform users or the public from infringement, we will disclose your personal information in accordance with laws and regulations or with your consent, including relevant violations and the measures that the [########] platform has taken against you. ",
    "",
    " (IV) Exceptions to prior authorization and consent when sharing, transferring, or publicly disclosing personal information ",
    "",
    " In the following circumstances, sharing, transferring, and publicly disclosing your personal information does not require your prior authorization and consent: ",
    "",
    " 1. Related to national security and national defense security; ",
    "",
    " 2. Related to public safety, public health, and major public interests; ",
    "",
    " 3. Related to criminal investigation, prosecution, trial and execution of judgment or administrative law enforcement; ",
    "",
    " 4. For the purpose of protecting the major legal rights and interests of you or other individuals related to life and property, but it is difficult to obtain the consent of the individual; ",
    "",
    " 5. Personal information that you disclose to the public on your own initiative; ",
    "",
    " 6. Collecting personal information from legally disclosed information, such as legal news reports and government information disclosure. ",
    "",
    " Please be aware that, in accordance with applicable laws, if we take technical measures and other necessary measures to process personal information so that the data recipient cannot re-identify a specific individual and cannot restore it, the sharing, transfer, and public disclosure of such processed data does not require additional notification to you and your consent. ",
    "",
    " IV. How do we store your information ?",
    " (I) Storage location ",
    "",
    " The personal information we collect and generate during our operations will be stored in the server hosting region. In the following circumstances, we will provide your personal information to the relevant entity after fulfilling the legal obligations: ",
    "",
    " 1. The applicable law has clear provisions; ",
    "",
    " 2. Obtain your explicit authorization; ",
    "",
    " 3. Your personal initiative in conducting cross-border transactions through the Internet. ",
    "",
    " For the above situations, we will ensure that your personal information is protected to a degree no less than that specified in this policy through a contract. ",
    "",
    " (II) Storage period ",
    "",
    " We will take reasonable and feasible measures to try our best to avoid collecting and processing irrelevant personal information. We will only retain your personal information for the period necessary to achieve the purposes stated in this policy, unless there is a mandatory retention requirement by law. The transaction information will be retained for no less than three years from the date of completion of the transaction. We mainly refer to the following standards to determine the storage period of personal information and the longer one shall prevail: ",
    "",
    " 1. Complete the transaction purpose related to you, maintain the corresponding transaction and business records, and respond to your inquiries or complaints; ",
    "",
    " 2. Ensure the safety and quality of the services we provide to you; ",
    "",
    " 3. Do you agree to a longer retention period? ",
    "",
    " 4. Whether there are other special agreements on retention periods. ",
    "",
    " After the retention period expires, we will delete your personal information or anonymize it within 15 days in accordance with applicable laws. In addition, after canceling your account, we will stop providing you with products and services within 15 days and delete your personal information or anonymize it according to your request, unless otherwise provided by laws and regulations. ",
    "",
    " V. How do we protect your information ?",
    " (I) We have taken reasonable and feasible security measures that meet industry standards to protect your information and prevent personal information from being accessed, disclosed, used, modified, damaged or lost without authorization. The data exchanged between your browser and the server is protected by SSL encryption; we also provide HTTPS secure browsing for the [########] website; we will use encryption technology to improve the security of personal information; we will use trusted protection mechanisms to prevent personal information from being maliciously attacked; we will deploy access control mechanisms to ensure that only authorized personnel can access personal information. ",
    "",
    "",
    "",
    " (II) We will take reasonable and feasible measures to try our best to avoid collecting irrelevant personal information. We will only retain your personal information for the period necessary to achieve the purposes stated in this policy, unless there is a mandatory retention requirement by law. The transaction information will be retained for no less than three years from the date of completion of the transaction. The criteria we use to determine the aforementioned period include: ",
    "",
    " 1. Complete the transaction purpose related to you, maintain the corresponding transaction and business records, and respond to your inquiries or complaints; ",
    "",
    " 2. Ensure the safety and quality of the services we provide to you; ",
    "",
    " 3. Do you agree to a longer retention period? ",
    "",
    " 4. Whether there are other special agreements on retention periods. ",
    "",
    " After your personal information exceeds the retention period, we will delete your personal information or anonymize it in accordance with applicable laws. ",
    "",
    " (III) The Internet is not an absolutely safe environment. When using the [########] platform services, we strongly recommend that you do not use communication methods other than those recommended by the [########] platform to send your information. You can establish connections and share with each other through our services. When you create communications, transactions or sharing through our services, you can independently choose the object of communication, transaction or sharing, as a third party that can see your transaction content, contact information, communication information or sharing content related information. ",
    "",
    " When using the [########] service to conduct online transactions, you will inevitably disclose your personal information, such as contact information or contact address, to the transaction counterparty or potential transaction counterparty. Please properly protect your personal information and provide it to others only when necessary. If you find that your personal information, especially your account or password, has been leaked, please contact [########] customer service immediately so that we can take appropriate measures based on your application. ",
    "",
    " Please note that the information you voluntarily share or even publicly share when using our services may involve your or others' personal information or even personal sensitive information. Please consider more carefully whether to share or even publicly share relevant information when using our services. ",
    "",
    " Please use a complex password to help us ensure the security of your account. We will do our best to ensure the security of any information you send to us. If our physical, technical or management protection facilities are damaged, resulting in unauthorized access, public disclosure, tampering or destruction of information, resulting in damage to your legitimate rights and interests, we will bear the corresponding legal liability. ",
    "",
    " (IV) We will update and disclose information related to security risks and personal information security impacts from time to time. You can obtain it through the [########] announcement. ",
    "",
    " (V) In the unfortunate event of a personal information security incident, we will inform you in accordance with the requirements of laws and regulations: the basic situation and impact of the security incident, the disposal measures we have taken or will take, suggestions for you to prevent and reduce risks on your own, and remedial measures for you. We will inform you of the relevant circumstances of the incident by email, phone call, or push notification. When it is difficult to inform the personal information subject one by one, we will take reasonable and effective means to issue an announcement. ",
    "",
    "",
    " VI. How do you manage your information ?",
    " You can access and manage your information in the following ways: ",
    "",
    " (I) Query, correct and supplement your information ",
    "",
    " You have the right to query, correct or supplement your information. You can do this by yourself in the following ways: ",
    "",
    ' 1. Log in to the [########] client, enter the "Account" page, and correct personal information and personal account related information; ',
    " 2. Authorize relevant management personnel to make modifications ",
    "",
    " (II) Delete your information ",
    "",
    "",
    " You may request us to delete your personal information in the following circumstances: ",
    "",
    " 1. If our handling of personal information violates laws and regulations; ",
    "",
    " 2. If we collect and use your personal information without your explicit consent; ",
    "",
    " 3. If our handling of personal information seriously violates the agreement with you; ",
    "",
    " 4. If you no longer use our products or services, or you actively apply to cancel your account; ",
    "",
    " 5. If we no longer provide you with products or services permanently. ",
    "",
    " If we decide to respond to your deletion request, we will also notify the subject that obtained your personal information from us and request it to delete it in a timely manner (unless otherwise provided by laws and regulations, or these subjects have independently obtained your authorization). ",
    "",
    " After you or we assist you in deleting the relevant information, we are unable to immediately delete the corresponding information from the backup system due to applicable laws and security technology. We will safely store your personal information and isolate it from any further processing until the backup can be cleared or anonymized. ",
    "",
    " (III) Change the scope of your authorization ",
    "",
    " If you no longer choose to provide us with your personal information or refuse to use some of the services we provide, you can turn off some of the permissions you authorized to [#######] by setting up your smart mobile device. ",
    "",
    " Once you withdraw your consent, we will no longer process the corresponding personal information. However, your decision to withdraw your consent will not affect the previous processing of personal information based on your authorization. ",
    "",
    " (IV) Cancel your account ",
    "",
    " You can apply to cancel your account in the following ways: ",
    "",
    " 1. You can contact your backend administrator to cancel your account. ",
    "",
    " After you cancel your account, we will stop providing you with goods or services and delete your personal information or make it anonymous in accordance with applicable laws. ",
    "",
    " (5) Constraint information system automatic decision-making ",
    "",
    " In certain business functions, we only make decisions based on non-artificial automatic decision-making mechanisms including information systems and algorithms. If these decisions significantly affect your legitimate rights and interests, you have the right to request an explanation from us, and we will also Provide complaint methods without infringing on [########]'s business secrets or other user rights and social public interests. ",
    "",
    " (6) Respond to your above request ",
    "",
    " For security reasons, you will need to provide a written request or prove your identity in other ways. We will ask you to verify your identity before processing your request. ",
    "",
    " We will respond within 15 days. If you are not satisfied, you can also file a complaint through [########] customer service. ",
    "",
    " In principle, we do not charge you for reasonable requests, but we will charge a certain fee for repeated requests that exceed reasonable limits. We will reject requests that do not directly relate to your identity, are unnecessarily repeated, require too many technical means (need to develop new systems or fundamentally change current practices), pose risks to the legitimate rights and interests of others, or are impractical. ",
    "",
    " In the following circumstances, we will not be able to respond to your request in accordance with laws and regulations: ",
    "",
    " 1. Related to national security and national defense security; ",
    "",
    " 2. Related to public safety, public health, and major public interests; ",
    "",
    " 3. Related to criminal investigation, prosecution, trial and execution of judgment; ",
    "",
    " 4. There is sufficient evidence to show that the subject of personal information has subjective malice or abuses his rights; ",
    "",
    " 5. Responding to your request will cause serious damage to the legitimate rights and interests of you or other individuals or organizations; ",
    "",
    " 6. Involving commercial secrets. ",
    "",
    "",
    " VII. How your information is transferred globally ",
    "",
    " 1. The applicable law has clear provisions; ",
    "",
    " 2. Obtain your explicit authorization; ",
    "",
    " 3. Your personal initiative to conduct cross-border transactions through the Internet. ",
    "",
    " For the above situations, we will ensure that your personal information is adequately protected in accordance with this Privacy Policy. ",
    "",
    " VIII. How to update this privacy policy ",
    " Changes to our privacy policy. ",
    "",
    " We will not limit your rights under this Privacy Policy without your explicit consent. We will post updates to the Privacy Policy on the App. ",
    "",
    " For major changes, we will also provide more prominent notifications (including notifications through [########] public announcements or even providing you with pop-up prompts). ",
    "",
    " Major changes referred to in this policy include but are not limited to: ",
    "",
    " 1. Our service model has undergone major changes. Such as the purpose of processing personal information, the type of personal information processed, and the way personal information is used; ",
    "",
    " 2. We have major changes in control, such as changes in information controllers caused by mergers and acquisitions; ",
    "",
    " 3. The main objects of sharing, transfer or public disclosure of personal information have changed; ",
    "",
    " 4. Your rights to participate in the processing of personal information and the way in which they are exercised have undergone significant changes; ",
    "",
    " 5. Our responsible department for handling personal information security, contact information and complaint channels have changed; ",
    "",
    " 6. The personal information security impact assessment report shows that there is a high risk. ",
    "",
    " IX. How to contact us ",
    " You can contact us by the following means and we will respond to your request within 15 days: ",
    "",
    " 1. If you have any questions, comments or suggestions about the content of this policy, you can contact us through the [########] customer service email: [support@########.com]. ",
    "",
    " 2. If you find that your personal information has been leaked, you can contact our customer service staff to make a complaint; ",
    "",
    " If you are not satisfied with our response, especially if you believe that our processing of personal information has harmed your legal rights and interests, you can also seek a solution by filing a lawsuit in a court with jurisdiction over the defendant's place of residence. ",
  ],
  zh_CN: [
    "########隐私政策",
    "【生效时间】：2024-7-23",
    "【更新时间】：2024-7-23",
    "",
    "提示条款",
    "在使用【########】各项产品或服务前，请您务必仔细阅读并透彻理解本政策，特别是以粗体/粗体下划线标识的条款，您应重点阅读，在确认充分理解并同意后再开始使用。如对本政策内容有任何疑问、意见或建议，您可通过【########】提供的各种联系方式与我们联系。",
    "",
    " 您的信任对我们非常重要，我们深知个人信息对您的重要性，我们将按法律法规要求，采取相应安全保护措施，尽力保护您的个人信息安全可控。鉴于此，【########】服务提供者（或简称“我们”或“【########】”）制定本隐私政策（下称“本政策 /本隐私政策”）并提醒您：本政策适用于【 Toposfy Dashboard】提供的所有产品和服务",
    "",
    "需要特别说明的是，本政策不适用于其他第三方向您提供的服务，也不适用于【########】中已另行独立设置隐私政策的产品或服务。",
    "",
    "第一部分 定义",
    "【########】服务提供者：【 成都慧通时代软件有限公司】",
    "",
    "个人信息：指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。",
    "",
    "个人敏感信息：指包括邮件地址、订单信息。",
    "",
    "个人信息删除：指在实现日常业务功能所涉及的系统中去除个人信息的行为，使其保持不可被检索、访问的状态。",
    "",
    "儿童：指不满十四周岁的未成年人。",
    "",
    "除另有约定外，本政策所用定义与【########】平台用户协议中的定义具有相同的涵义。",
    "",
    "第二部分 隐私政策",
    "本隐私政策部分将帮助您了解以下内容：",
    "",
    "一、我们如何收集和使用您的信息",
    "",
    "二、我们如何使用Cookie",
    "",
    "三、我们如何共享、转让、公开披露您的信息",
    "",
    "四、我们如何存储您的信息",
    "",
    "五、我们如何保护您的信息",
    "",
    "六、您如何管理您的信息",
    "",
    "七、您的信息如何在全球范围转移",
    "",
    "八、本隐私政策如何更新",
    "",
    "九、如何联系我们",
    "",
    "一、我们如何收集和使用您的信息",
    "在您使用我们的产品及/或服务时，我们需要/需要收集和使用的您的个人信息包括如下两种：",
    "",
    "1、为实现向您提供我们产品及/或服务的基本功能，我们将按照相关规定收集、我们将会获取您的个人信息以及设备信息(IMEI、设备MAC地址、剪切板、设备序列号、 andridID)、应用安装列表。如您拒绝提供相应信息，您将无法正常使用我们的产品及/或服务，但不影响您浏览我们的网页及客户端页面；",
    "",
    "2、为实现向您提供我们产品及/或服务的附加功能，您可选择授权我们收集、使用您的个人敏感信息。如您拒绝提供，您将无法正常使用相关附加功能或无法达到我们拟达到的功能效果，但并不会影响您正常使用我们产品及/或服务的基本功能。",
    "",
    "您理解并同意：",
    "",
    "1、我们致力于打造多样的产品和服务以满足您的需求。因我们向您提供的产品和服务种类众多，且不同用户选择使用的具体产品/服务范围存在差异，因此基本/附加功能及收集使用的个人信息类型、范围会有所区别，请以具体的产品/服务功能为准；",
    "",
    "2、为给您带来更好的产品和服务体验，我们在持续努力改进我们的技术，随之我们会不时推出新的或优化后的功能，需要重新收集、使用您的个人信息或变更个人信息使用目的或方式。对此，我们将通过更新本政策、弹窗、页面提示方式另行向您说明对应信息的收集目的、范围及使用方式，并征求您的明示同意。在此过程中，如果您有任何疑问、意见或建议的，您可通过【########】提供的各种联系方式与我们联系，我们会尽快为您作出解答。",
    "",
    "我们会为实现本政策下述的各项功能，收集和使用您的个人信息：",
    "",
    "（一） 帮助您成为我们的注册用户",
    "",
    "1、基础注册用户服务",
    "",
    "注册成为注册用户并使用我们的注册用户服务，您需要提供手机号码、邮箱和拟使用的注册用户名和密码用于创建【########】平台账户。如果您仅需使用浏览、搜索服务，您不需要注册成为我们的注册用户及提供上述信息。",
    "",
    "在您登录帐户时，我们会根据您提供的上述信息校验您的注册用户身份，确保我们是在为您本人提供服务。若存在依法需确定您注册用户身份的场景（包括行政执法或司法诉讼中相关主体认定）时，您授权我们可获取您对应支付账户的相关认证信息用于上述目的。",
    "",
    "我们会根据您的注册用户账户使用情况为您提供相应注册用户所对应的基本权益。",
    "",
    "2、附加注册用户服务",
    "",
    "如果您选择提供真实姓名、性别、出生年月日、居住地个人信息，我们可以为您提供更加个性化的注册用户服务。为保证交易辨识度，您的帐户昵称、头像将公开显示。",
    "",
    "授权登录：我们会根据您的授权从第三方处获取您的第三方账户信息，并与您的【########】平台账户进行绑定，使您可通过第三方账户直接登录、使用我们的产品及/或服务。我们将在您授权同意的范围内使用您的相关信息。",
    "",
    "3、账户信息展示：如果您已拥有【########】平台账户，我们会在【########】平台服务中显示您的上述个人信息，以及您在【########】平台上或与【########】平台账户相关联的产品和服务中执行的操作（您可通过【########】平台账户在我们提供的【########】入口或其他产品/服务入口使用我们提供的产品或服务），包括通过【########】平台账户集中展示您的个人资料、交易订单。我们会尊重您对【########】平台服务和【########】平台账户设置所做的选择。",
    "",
    "（二） 为您提供商品或服务信息展示",
    "",
    "在您使用我们服务过程中，为识别账号异常状态、了解产品适配性、向您提供更契合您需求的页面展示和搜索结果，我们会自动收集您的使用情况并存储为网络日志信息，包括：",
    "",
    "设备信息：我们会根据您在软件安装及/或使用中的具体操作，接收并记录您所使用的设备相关信息（包括设备型号、操作系统版本、设备设置、唯一设备标识符、设备环境软硬件特征信息）、设备所在位置相关信息（包括您授权的GPS位置）。",
    "",
    "服务日志信息：当您使用我们的网站或客户端提供的产品或服务时，我们会自动收集您对我们服务的详细使用情况，作为服务日志保存，包括浏览、点击查看、搜索查询、收藏、添加至购物车、交易、售后、关注分享信息、发布信息，以及IP地址、浏览器类型、电信运营商、使用语言、访问日期和时间。",
    "",
    "请注意，单独的设备信息、服务日志信息是无法识别特定自然人身份的信息。如果我们将这类非个人信息与其他信息结合用于识别特定自然人身份，或者将其与个人信息结合使用，则在结合使用期间，这类非个人信息将被视为个人信息，除取得您授权或法律法规另有规定外，我们会将这类信息做匿名化、去标识化处理。",
    "",
    "",
    "此外，我们也会为了不断改进和优化上述的功能来使用您的上述信息。",
    "",
    "",
    "为使我们及时获悉并确认您的支付进度及状态，为您提供售后与争议解决服务，您同意我们可自您所选择的交易对象、支付公司或您选择的其他金融机构处收集与支付进度相关信息。",
    "",
    "（三） 帮助向您完成商品或服务的交付",
    "",
    "为保证您购买的商品及/或服务能够顺利、安全、准确送达，我们会向为【########】平台提供物流信息系统和技术服务的物流配送公司披露订单相关配送信息，并由其根据商品及/或服务提供主体的指定向相应的物流配送主体同步相关配送信息。您知晓并同意相应物流配送主体不可避免地获知及使用您的配送信息，用于完成交付目的。",
    "",
    "",
    "（四） 客服及争议处理",
    "",
    "当您与我们联系或提出售中售后、争议纠纷处理申请时，为了保障您的账户及系统安全，我们需要您提供必要的个人信息以核验您的注册用户身份。",
    "",
    "为便于与您联系、尽快帮助您解决问题或记录相关问题的处理方案及结果，我们会保存您与我们的通信/通话记录及相关内容（包括账号信息、订单信息、您为了证明相关事实提供的其他信息，或您留下的联系方式信息），如果您针对具体订单进行咨询、投诉或提供建议的，我们会使用您的账号信息和订单信息。",
    "",
    "（五） 为您提供安全保障",
    "",
    "为提高您使用我们及我们关联公司、合作伙伴提供服务的安全性，保护您或其他用户或公众的人身财产安全免遭侵害，更好地预防钓鱼网站、欺诈、网络漏洞、计算机病毒、网络攻击、网络侵入安全风险，更准确地识别违反法律法规或【########】相关协议规则的情况，我们使用或整合您的注册用户信息、交易信息、设备信息、服务日志信息以及我们关联公司、合作伙伴取得您授权或依据法律共享的信息，来综合判断您账户及交易风险、进行身份验证、检测及防范安全事件，并依法采取必要的记录、审计、分析、处置措施。",
    "",
    "（六）为您提供其他附加服务",
    "",
    "为向您提供更便捷、更优质、个性化的产品及/或服务，努力提升您的体验，我们在向您提供的以下附加服务中会收集、缓存和使用您的个人信息。如果您不提供这些信息，不会影响您使用【########】的浏览、搜索基本服务，但您无法获得这些附加服务给您带来的用户体验。这些附加服务包括：",
    "",
    "1、基于相机/摄像头的附加服务：您可在开启相机（摄像头）权限后使用该功能。即使您已同意开启相机（摄像头）权限，我们也仅会在您主动点击相应图标或录制视频时通过相机获取照片信息。。",
    "",
    "2、基于读取、写入外置存储卡的附加服务：您可以在开启存储权限后，使用该功能上传您的照片/图片/视频，以实现发表评论/分享或与客服沟通提供证明功能。",
    "",
    "3.基于读取手机状态和身份信息的附加服务：我们将在您开启权限后收集您的IMEI、设备MAC地址、设备序列号、android ID、安装应用列表，根据您的设备确认您的账户安全，实现安全风控。",
    "",
    "4、基于查看WLAN连接的附加功能：查看wifi连接状态，确保网络可靠性。",
    "",
    "",
    "5、基于相册（图片库）的图片访问及上传、外部存储、缓存、发布图片功能的附加服务：您可在开启相册权限后使用该功能上传您的照片/图片，发表评论/分享、拍照购物或与客服沟通提供证明功能。我们会通过您所上传的照片/图片来识别您需要购买的商品或服务，或使用包含您所上传照片或图片的评论信息。您如果拒绝授权提供，将无法使用此功能，但不影响您正常使用【########】的其他功能。",
    "",
    "6、基于安装应用的附加服务：平台会定期更新应用的客户端，您可以在更新的时候，打开APP直接进行新版本的更新安装及使用。",
    "",
    "7、基于应用程序获得当前运行任务的信息的附加服务：平台在统计APP数据的情况下，会对APP的页面进行判断。",
    "",
    "8、基于保持APP在唤醒状态下的附加服务：您在本应用使用的情况下，后台会自动进行数据同步。",
    "",
    "9、为更加全面提供您所需要的商品或服务信息、向您提供个性化或非个性化的服务，我们会根据您的浏览及搜索记录、设备信息、位置信息、订单信息，提取您的浏览、搜索偏好、行为习惯、位置信息的特征。”",
    "",
    "您理解并同意，上述附加服务需要您在您的设备中开启您的位置信息 （地理位置）、摄像头（相机）、相册（图片库）、日历、外部存储的访问权限，以实现这些权限所涉及信息的收集和使用。请您注意，您开启任一权限即代表您授权我们可以收集和使用相关个人信息来为您提供对应服务，您一旦关闭任一权限即代表您取消了授权，我们将不再基于对应权限继续收集和使用相关个人信息，也无法为您提供该权限所对应的服务。但是，您关闭权限的决定不会影响此前基于您的授权所进行的信息收集及使用。",
    "",
    "（七）其他",
    "",
    "1、若你提供的信息中含有其他用户的个人信息，在向【########】提供这些个人信息之前，您需确保您已经取得合法的授权。若其中涉及儿童个人信息的，您需在发布前取得对应儿童监护人的同意，前述情形下监护人有权通过本政策第十条的途径联系我们，要求更正或删除涉及儿童个人信息的内容。",
    "",
    "2、若我们将信息用于本政策未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的，或者我们主动从第三方处获取您的个人信息，均会事先获得您的同意。",
    "",
    "若我们从第三方处间接获取您的信息的，我们会在收集前明确以书面形式要求该第三方在已依法取得您同意后收集个人信息，并向您告知共享的信息内容，且涉及敏感信息的在提供给我们使用前需经过您的明确确认，要求第三方对个人信息来源的合法性和合规性作出承诺，如第三方有违反行为的，我们会明确要求对方承担相应法律责任；同时，我们对个人信息会进行安全加固（包括敏感信息报备、敏感信息加密存储、访问权限控制）。我们会使用不低于我们对自身用户个人信息同的保护手段与措施对间接获取的个人信息进行保护。",
    "",
    "3、征得授权同意的例外",
    "",
    "您充分理解并同意，我们在以下情况下收集、使用您的个人信息无需您的授权同意，且我们不会响应您提出的更正/修改、删除、注销、撤回同意、索取信息的请求：",
    "",
    "（1）与国家安全、国防安全有关的；",
    "",
    "（2）与公共安全、公共卫生、重大公共利益有关的；",
    "",
    "（3）与犯罪侦查、起诉、审判和判决执行司法或行政执法有关的；",
    "",
    "（4）出于维护您或其他个人的生命、财产相关的重大合法权益但又很难得到本人同意的；",
    "",
    "（5）您自行向社会公众公开的个人信息；",
    "",
    "（6）从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开渠道。",
    "",
    "（7）根据与您签订和履行相关协议或其他书面文件所必需的；",
    "",
    "（8）用于维护所提供的产品及/或服务的安全稳定运行所必需的，发现、处置产品及/或服务的故障；",
    "",
    "（9）为合法的新闻报道所必需的；",
    "",
    "（10）学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；",
    "",
    "（11）法律法规规定的其他情形。",
    "",
    "请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，或我们会对收集的信息进行去标识化地研究、统计分析和预测，用于改善【########】的内容和布局，为商业决策提供产品或服务支撑，以及改进我们的产品和服务（包括使用匿名数据进行机器学习或模型算法训练），则此类处理后数据的使用无需另行向您通知并征得您的同意。",
    "",
    "4、如我们停止运营【########】产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的与已关停业务相关的个人信息进行删除或匿名化处理。涉及儿童个人信息的，我们会并将停止运营的通知及时告知儿童监护人。",
    "",
    "二、我们如何使用Cookie",
    "为使您获得更轻松的访问体验、向您推荐您感兴趣的内容，我们会在您的移动设备上存储名为Cookie的小数据文件。Cookie通常包含标识符、站点名称以及一些号码和字符。借助Cookie，我们能够存储您的账户信息、商品记录、订单记录、商品的数据。",
    "",
    "若您不同意我们在您的移动设备上存储Cookie的小数据文件，您可停止使用【########】。",
    "",
    "三、我们如何共享、转让、公开披露您的信息",
    "（一）共享",
    "",
    "我们不会与【########】服务提供者以外的公司、组织和个人共享您的个人信息，但以下情况除外：",
    "",
    "1、在法定情形下的共享：我们会根据法律法规规定、诉讼、争议解决需要，或按行政、司法机关依法提出的要求，对外共享您的个人信息。",
    "",
    "2、在获取明确同意的情况下共享：获得您的明确同意后，我们会与其他方共享您的个人信息。",
    "",
    "3、在您主动选择情况下共享：您通过【########】平台购买商品或服务，我们会根据您的选择，将您的订单信息中与交易有关的必要信息共享给相关商品或服务的提供者，以实现您的交易及售后服务需求。",
    "",
    "4、与关联公司间共享：为便于我们基于【########】平台账户向您提供产品和服务，推荐您感兴趣的信息，识别注册用户账号异常，保护【########】关联公司或其他用户或公众的人身财产安全免遭侵害，您的个人信息会与我们的关联公司和/或其指定的服务提供商共享。我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束，如果我们共享您的个人敏感信息或关联公司改变个人信息的使用及处理目的，将再次征求您的授权同意。",
    "",
    "5、与授权合作伙伴共享：我们委托授权合作伙伴为您提供某些服务或代表我们履行职能，我们仅会出于本隐私政策声明的合法、正当、必要、特定、明确的目的共享您的信息，授权合作伙伴只能接触到其履行职责所需信息，且不得将此信息用于其他任何目的。对于涉及儿童个人信息的，我们不允许合作伙伴进行转委托。",
    "",
    "目前，我们的授权合作伙伴包括以下类型：",
    "",
    "（1）商家、服务提供商和其他合作伙伴。我们将信息发送给支持我们业务的商家、服务提供商和其他合作伙伴，这些支持包括受我们委托提供的技术基础设施服务、分析我们服务的使用方式、提供客户服务、支付便利或进行学术研究和调查，这些信息难以与您的身份信息相关联，这些信息将帮助我们分析相关服务的有效性。",
    "",
    "为保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK。我们会对授权合作伙伴获取有关信息的应用程序接口（API）、软件工具开发包（SDK）进行严格的安全检测，并与授权合作伙伴约定严格的数据保护措施，令其按照本政策以及其他任何相关的保密和安全措施来处理个人信息。",
    "",
    "具体如下：",
    "",
    " ",
    "SDK名称：KTPsdk",
    "",
    "厂家：深圳市金泰谊电子有限公司",
    "",
    "收集信息类型：获取手机蓝牙列表，扫描手机周围蓝牙设备",
    "",
    "使用目的：用于在特定设备上读取蓝牙列表，连接蓝牙，通过蓝牙发送信息。",
    "（特定设备：深圳市金泰谊电子有限公司生产的无线POS终端）",
    "",
    "使用的敏感系统权限：蓝牙扫描权限，蓝牙连接权限，定位权限。",
    "",
    "具体使用场景：连接蓝牙，蓝牙连接权限。扫描附近蓝牙，蓝牙扫描权限，定位权限。",
    "",
    "",
    "",
    "SDK名称：flutter_bluetooth_serial",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：获取手机蓝牙列表，扫描手机周围蓝牙设备",
    "",
    "使用目的：读取设备蓝牙列表，连接蓝牙，通过蓝牙发送信息。",
    "",
    "使用的敏感系统权限：蓝牙扫描权限，蓝牙连接权限，定位权限。",
    "",
    "具体使用场景：连接蓝牙，蓝牙连接权限。扫描附近蓝牙，蓝牙扫描权限，定位权限。",
    "",
    "",
    "SDK名称：image_picker",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：获取手机读写存储权限，获取相机使用权限",
    "",
    "使用目的：用于从图像库中挑选图像， 以及使用相机拍摄新照片。",
    "",
    "使用的敏感系统权限：读写外部存储权限，相机权限",
    "",
    "具体使用场景：设置，修改商品照片功能使用照相权限，存储权限。",
    "",
    "",
    "",
    "SDK名称：vibration",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：获取手机震动权限",
    "",
    "使用目的：扫码成功后震动手机。",
    "",
    "使用的敏感系统权限：手机震动权限",
    "",
    "具体使用场景：扫码成功后震动手机，震动权限。",
    "",
    "",
    "",
    "SDK名称：qr_code_scanner",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：获取相机使用权限",
    "",
    "使用目的：扫描二维码和条形码。",
    "",
    "使用的敏感系统权限：相机使用权限",
    "",
    "具体使用场景：扫描商品条形码，相机使用权限，扫描单据二维码，相机使用权限。",
    "",
    "",
    "",
    "SDK名称：path_provider",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：获取手机读写存储权限",
    "",
    "使用目的：用于在文件系统上查找常用位置。",
    "",
    "使用的敏感系统权限：读写外部存储权限",
    "",
    "具体使用场景：获取APP外部储存地址，读写外部存储权限，在外部储存地址创建文件夹，读写外部存储权限。",
    "",
    "",
    "SDK名称：open_filex",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：获取手机读写存储权限",
    "",
    "使用目的：用于在文件系统内打开指定文件。",
    "",
    "使用的敏感系统权限：读写外部存储权限",
    "",
    "具体使用场景：软件更新安装包下载到外部储存后打开该文件以完成更新，读写外部存储权限。",
    "",
    "",
    "",
    "SDK名称：flutter_downloader",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：获取手机读写存储权限，获取手机通知权限",
    "",
    "使用目的：用于创建和管理下载任务的插件。",
    "",
    "使用的敏感系统权限：读写外部存储权限，获取手机通知权限",
    "",
    "具体使用场景：软件下载更新安装包外部储存，读写外部存储权限，通知栏展示下载信息和进度，通知权限。",
    "",
    "",
    "",
    "SDK名称：permission_handler",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：无特定权限要求",
    "",
    "使用目的：用于管理和请求应用权限的插件。",
    "",
    "使用的敏感系统权限：与所请求的权限相关（例如相机、位置、联系人）",
    "",
    "具体使用场景：请求用户授权访问设备功能（如相机、位置），检查和管理权限状态。",
    "",
    "",
    "SDK名称：share_plus",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：无特定权限要求",
    "",
    "使用目的：用于在应用内分享内容的插件。",
    "",
    "使用的敏感系统权限：无特定敏感权限",
    "",
    "具体使用场景：用户可以通过插件将文本、图片、文件等内容分享到其他应用程序或平台，例如社交媒体、消息应用或邮件客户端。",
    "",
    "",
    "",
    "SDK名称：camera",
    "",
    "厂家：第三方开源插件",
    "",
    "收集信息类型：无特定权限要求",
    "",
    "使用目的：用于在应用中访问和控制设备相机的插件。",
    "",
    "使用的敏感系统权限：相机权限（用于访问设备摄像头）",
    "",
    "具体使用场景：拍照、录制视频、预览相机内容等操作，通常用于需要相机功能的应用。",
    "",
    "",
    "",
    " ",
    "个人信息字段定义以及穷举",
    "",
    "设备MAC地址：设备的mac地址，确认移动设备位置的地址",
    "",
    "唯一设备识别码：iOS设备为IDFA，Android设备为IMEI、oaid、AndroidId、UUID（Android以上识别码会收集多个）",
    "",
    "设备IP地址：移动互联网链接协议，确认网络连接的运营商服务",
    "",
    "设备信息：设备品牌、设备型号、操作系统、操作系统版本",
    "",
    "网络信息：当前网络环境（如WIFI、5G、4G、3G、2G）",
    "",
    "",
    "（二）转让",
    "",
    "我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：",
    "",
    "1、在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；",
    "",
    "2、在【########】服务提供者发生合并、收购或破产清算情形，或其他涉及合并、收购或破产清算情形时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本政策的约束，否则我们将要求该公司、组织和个人重新向您征求授权同意。",
    "",
    "（三）公开披露",
    "",
    "我们仅会在以下情况下，公开披露您的个人信息：",
    "",
    "1、获得您明确同意或基于您的主动选择，我们会公开披露您的个人信息；",
    "",
    "2、如果我们确定您出现违反法律法规或严重违反【########】平台相关协议及规则的情况，或为保护【########】平台用户或公众的人身财产安全免遭侵害，我们依据法律法规或征得您同意的情况下披露关于您的个人信息，包括相关违规行为以及【########】平台已对您采取的措施。",
    "",
    "（四）共享、转让、公开披露个人信息时事先征得授权同意的例外",
    "",
    "以下情形中，共享、转让、公开披露您的个人信息无需事先征得您的授权同意：",
    "",
    "1、与国家安全、国防安全有关的；",
    "",
    "2、与公共安全、公共卫生、重大公共利益有关的；",
    "",
    "3、与犯罪侦查、起诉、审判和判决执行司法或行政执法有关的；",
    "",
    "4、出于维护您或其他个人的生命、财产相关的重大合法权益但又很难得到本人同意的；",
    "",
    "5、您自行向社会公众公开的个人信息；",
    "",
    "6、从合法公开披露的信息中收集个人信息的，如合法的新闻报道、政府信息公开。",
    "",
    "请知悉，根据适用的法律，若我们对个人信息采取技术措施和其他必要措施进行处理，使得数据接收方无法重新识别特定个人且不能复原，则此类处理后数据的共享、转让、公开披露无需另行向您通知并征得您的同意。",
    "",
    "四、我们如何存储您的信息",
    "（一）存储地点",
    "",
    "我们在运营中收集和产生的个人信息，将存储在服务器托管地域。以下情形下，我们会在履行了法律规定的义务后，向相关实体提供您的个人信息：",
    "",
    "1、适用的法律有明确规定；",
    "",
    "2、获得您的明确授权；",
    "",
    "3、您通过互联网进行跨境交易个人主动行为。",
    "",
    "针对以上情形，我们会通过合同形式确保以不低于本政策规定的程度保护您的个人信息。",
    "",
    "（二）存储期限",
    "",
    "我们会采取合理可行的措施，尽力避免收集和处理无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的留存要求，交易信息保存时间自交易完成之日起不少于三年。我们判断个人信息的存储期限主要参考以下标准并以其中较长者为准：",
    "",
    "1、完成与您相关的交易目的、维护相应交易及业务记录，以应对您的查询或投诉；",
    "",
    "2、保证我们为您提供服务的安全和质量；",
    "",
    "3、您是否同意更长的留存期间；",
    "",
    "4、是否存在保留期限的其他特别约定。",
    "",
    "在超出保留期间后，我们会在15天内根据适用法律的要求删除您的个人信息，或使其匿名化处理。此外，在注销账户后，我们将在15天内停止为您提供产品和服务，并根据您的要求，删除您的个人信息或匿名化处理，法律法规另有规定的除外。",
    "",
    "五、我们如何保护您的信息",
    "（一）我们已采取符合业界标准、合理可行的安全防护措施保护您的信息，防止个人信息遭到未经授权访问、公开披露、使用、修改、损坏或丢失。在您的浏览器与服务器之间交换数据时受 SSL协议加密保护；我们同时对【########】网站提供HTTPS协议安全浏览方式；我们会使用加密技术提高个人信息的安全性；我们会使用受信赖的保护机制防止个人信息遭到恶意攻击；我们会部署访问控制机制，尽力确保只有授权人员才可访问个人信息。",
    "",
    "",
    "",
    "（二）我们会采取合理可行的措施，尽力避免收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非法律有强制的存留要求，交易信息保存时间自交易完成之日起不少于三年。而我们判断前述期限的标准包括：",
    "",
    "1、完成与您相关的交易目的、维护相应交易及业务记录、应对您的查询或投诉；",
    "",
    "2、保证我们为您提供服务的安全和质量；",
    "",
    "3、您是否同意更长的留存期间；",
    "",
    "4、是否存在保留期限的其他特别约定。",
    "",
    "在您的个人信息超出保留期间后，我们会根据适用法律的要求删除您的个人信息，或使其匿名化处理。",
    "",
    "（三）互联网并非绝对安全的环境，使用【########】平台服务时，我们强烈建议您不要使用非【########】平台推荐的通信方式发送您的信息。您可以通过我们的服务建立联系和相互分享。当您通过我们的服务创建交流、交易或分享时，您可以自主选择沟通、交易或分享的对象，作为能够看到您的交易内容、联络方式、交流信息或分享内容相关信息的第三方。",
    "",
    "在使用【########】服务进行网上交易时，您不可避免地要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或联系地址。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。如您发现自己的个人信息尤其是您的账户或密码发生泄露，请您立即联络【########】客服，以便我们根据您的申请采取相应措施。",
    "",
    "请注意，您在使用我们服务时自愿共享甚至公开分享的信息，会涉及您或他人的个人信息甚至个人敏感信息。请您更加谨慎地考虑，是否在使用我们的服务时共享甚至公开分享相关信息。",
    "",
    "请使用复杂密码，协助我们保证您的账号安全。我们将尽力保障您发送给我们的任何信息的安全性。如果我们的物理、技术或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。",
    "",
    "（四）我们将不定期更新并公开安全风险、个人信息安全影响有关内容，您可通过【########】公告方式获得。",
    "",
    "（五）在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施。事件相关情况我们将以邮件、电话、推送通知的方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。",
    "",
    "",
    "六、您如何管理您的信息",
    "您可以通过以下方式访问及管理您的信息：",
    "",
    "（一）查询、更正和补充您的信息",
    "",
    "您有权查询、更正或补充您的信息。您可以通过以下方式自行进行：",
    "",
    "1、登录【########】客户端，进入“账号”页面，更正个人资料及个人账户相关信息；",
    "2、授权相关管理人员进行修改",
    "",
    "（二）删除您的信息",
    "",
    "",
    "在以下情形中，您可以向我们提出删除个人信息的请求：",
    "",
    "1、如果我们处理个人信息的行为违反法律法规；",
    "",
    "2、如果我们收集、使用您的个人信息，却未征得您的明确同意；",
    "",
    "3、如果我们处理个人信息的行为严重违反了与您的约定；",
    "",
    "4、如果您不再使用我们的产品或服务，或您主动申请注销了账号；",
    "",
    "5、如果我们永久不再为您提供产品或服务。",
    "",
    "若我们决定响应您的删除请求，我们还将同时尽通知从我们处获得您的个人信息的主体，并要求其及时删除（除非法律法规另有规定，或这些主体已独立获得您的授权）。",
    "",
    "当您或我们协助您删除相关信息后，因为适用的法律和安全技术，我们无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并将其与任何进一步处理隔离，直到备份可以清除或实现匿名。",
    "",
    "（三）改变您授权同意的范围",
    "",
    "如果您不再选择将您的个人信息提供给我们或拒绝使用我们提供的部分服务，您可以通过设置您的智能移动设备关闭您授权给【########】的部分权限。",
    "",
    "当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。",
    "",
    "（四）注销您的账户",
    "",
    "您可以通过以下方式申请注销您的账户：",
    "",
    "1、您可以联系你的后台管理员为您注销账户。",
    "",
    "在您注销账户之后，我们将停止为您提供商品或服务，并根据适用法律的要求删除您的个人信息，或使其匿名化处理。",
    "",
    "（五）约束信息系统自动决策",
    "",
    "在某些业务功能中，我们仅依据信息系统、算法在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害【########】商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。",
    "",
    "（六）响应您的上述请求",
    "",
    "为保障安全，您需要提供书面请求，或以其他方式证明您的身份。我们会先要求您验证自己的身份，然后再处理您的请求。",
    "",
    "我们将在15天内做出答复。如您不满意，还可以通过【########】客服发起投诉。",
    "",
    "对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将酌情收取一定费用。对于与您的身份不直接关联的信息、无端重复信息，或者需要过多技术手段（需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者不切实际的请求，我们会予以拒绝。",
    "",
    "在以下情形中，按照法律法规要求，我们将无法响应您的请求：",
    "",
    "1、与国家安全、国防安全有关的；",
    "",
    "2、与公共安全、公共卫生、重大公共利益有关的；",
    "",
    "3、与犯罪侦查、起诉、审判和执行判决有关的；",
    "",
    "4、有充分证据表明个人信息主体存在主观恶意或滥用权利的；",
    "",
    "5、响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；",
    "",
    "6、涉及商业秘密的。",
    "",
    "",
    "七、您的信息如何在全球范围转移",
    "",
    "1、适用的法律有明确规定；",
    "",
    "2、获得您的明确授权；",
    "",
    "3、您通过互联网进行跨境交易的个人主动行为。",
    "",
    "针对以上情形，我们会确保依据本隐私政策对您的个人信息提供足够的保护。",
    "",
    "八、本隐私政策如何更新",
    "我们的隐私政策变更。",
    "",
    "未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在App上发布对隐私政策的更新。",
    "",
    "对于重大变更，我们还会提供更为显著的通知（包括我们会通过【########】公示的方式进行通知甚至向您提供弹窗提示）。",
    "",
    "本政策所指的重大变更包括但不限于：",
    "",
    "1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式；",
    "",
    "2、我们在控制权方面发生重大变化。如并购重组引起的信息控制者变更；",
    "",
    "3、个人信息共享、转让或公开披露的主要对象发生变化；",
    "",
    "4、您参与个人信息处理方面的权利及其行使方式发生重大变化；",
    "",
    "5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化；",
    "",
    "6、个人信息安全影响评估报告表明存在高风险。",
    "",
    "九、如何联系我们",
    "您可以通过以下方式与我们联系，我们将在15天内回复您的请求：",
    "",
    "1、如对本政策内容有任何疑问、意见或建议，您可通过【########】客服邮箱：【support@########.com】与我们联系。",
    "",
    "2、如发现个人信息被泄露，您可以联系我们的客服人员进行投诉举报；",
    "",
    "如果您对我们的回复不满意，特别是您认为我们的个人信息处理行为损害了您的合法权益，您还可以通过向被告住所地有管辖权的法院提起诉讼来寻求解决方案",
  ],
  zh_TW: [
    " ########隱私權政策",
    " 【生效時間】：2024-7-23 ",
    " 【更新時間】：2024-7-23 ",
    "",
    "提示條款",
    "在使用【########】各項產品或服務前，請您務必仔細閱讀並透徹理解本政策，特別是以粗體/粗體下劃線標識的條款，您應重點閱讀，在確認充分瞭解並同意後再開始使用。",
    "",
    "您的信任對我們非常重要，我們深知個人資訊對您的重要性，我們將按法律法規要求，採取相應安全保護措施，盡力保護您的個人資訊安全可控。鑑於此，【### #####】服務提供者（或簡稱「我們」或「【########】」）制定本隱私權政策（下稱「本政策/本隱私權政策」）並提醒您：本保單適用於【 Toposfy Dashboard】提供的所有產品與服務",
    "",
    "需要特別說明的是，本政策不適用於其他第三方向您提供的服務，也不適用於【########】中已另行獨立設定隱私權政策的產品或服務。 ",
    "",
    "第一部分定義",
    " 【########】服務提供者：【 成都慧通時代軟體有限公司】 ",
    "",
    "個人資訊：指以電子或其他方式記錄的能夠單獨或與其他資訊結合識別特定自然人身分或反映特定自然人活動情況的各種資訊。 ",
    "",
    "個人敏感資訊：指包含郵件地址、訂單資訊。 ",
    "",
    "個人資訊刪除：指在實現日常業務功能所涉及的系統中去除個人資訊的行為，使其保持不可被檢索、存取的狀態。 ",
    "",
    "兒童：指不滿十四歲的未成年人。 ",
    "",
    "除另有約定外，本政策所用定義與【########】平台使用者協議中的定義具有相同的涵義。 ",
    "",
    "第二部分隱私權政策",
    "本隱私權政策部分將協助您了解以下內容： ",
    "",
    "一、我們如何收集及使用您的資料",
    "",
    "二、我們如何使用Cookie ",
    "",
    "三、我們如何分享、轉讓、公開揭露您的資訊",
    "",
    "四、我們如何儲存您的資料",
    "",
    "五、我們如何保護您的資訊",
    "",
    "六、您如何管理您的資訊",
    "",
    "七、您的資訊如何在全球範圍轉移",
    "",
    "八、本隱私權政策如何更新",
    "",
    "九、如何聯絡我們",
    "",
    "一、我們如何收集及使用您的資料",
    "當您使用我們的產品及/或服務時，我們需要/需要收集和使用的您的個人資訊包括以下兩種： ",
    "",
    " 1、為實現向您提供我們產品及/或服務的基本功能，我們將按照相關規定收集、我們將會獲取您的個人資訊以及設備資訊(IMEI、設備MAC地址、剪切板、設備序號、 andridID)、應用程式安裝清單。",
    "",
    " 2、為實現提供我們產品及/或服務的附加功能，您可選擇授權我們收集、使用您的個人敏感資訊。如您拒絕提供，您將無法正常使用相關附加功能或無法達到我們擬達到的功能效果，但不會影響您正常使用我們產品及/或服務的基本功能。",
    "",
    "您瞭解並同意： ",
    "",
    " 1、我們致力於打造多樣的產品和服務以滿足您的需求。因我們向您提供的產品和服務種類眾多，且不同用戶選擇使用的具體產品/服務範圍存在差異，因此基本/附加功能及收集使用的個人資訊類型、範圍會有所區別，請以特定的產品/服務功能為準； ",
    "",
    " 2、為為您帶來更好的產品和服務體驗，我們在持續努力改進我們的技術，隨之我們會不時推出新的或優化後的功能，需要重新收集、使用您的個人資訊或變更個人資訊使用目的或方式。提示方式另行向您說明對應資訊的收集目的、範圍及使用方式，並徵求您的明示同意。 ##】提供的各種聯絡方式與我們聯繫，我們會盡快為您作出解答。 ",
    "",
    "我們將為實現本政策下述的各項功能，收集和使用您的個人資訊： ",
    "",
    " （一） 幫助您成為我們的註冊用戶",
    "",
    " 1、基礎註冊用戶服務",
    "",
    "註冊成為註冊用戶並使用我們的註冊用戶服務，您需要提供手機號碼、郵箱和擬使用的註冊用戶名和密碼用於創建【########】平台帳戶。如果您只需使用瀏覽、搜尋服務，您不需要註冊成為我們的註冊用戶及提供上述資訊。",
    "",
    "在您登入帳戶時，我們會根據您提供的上述資訊校驗您的註冊用戶身份，確保我們是在為您本人提供服務。若存在依法需確定您註冊用戶身份的場景（包括行政執法或司法訴訟中相關主體認定）時，您授權我們可取得您對應付款帳戶的相關認證資訊用於上述目的。",
    "",
    "我們會根據您的註冊用戶帳戶使用情況為您提供相應註冊用戶所對應的基本權益。 ",
    "",
    " 2、附加註冊用戶服務",
    "",
    "如果您選擇提供真實姓名、性別、出生年月日、居住地個人信息，我們可以為您提供更加個性化的註冊用戶服務。為保證交易辨識度，您的帳戶暱稱、頭像將公開顯示。 ",
    "",
    "授權登入：我們會根據您的授權從第三方處獲取您的第三方帳戶信息，並與您的【########】平台帳戶進行綁定，使您可透過第三方帳戶直接登入、使用我們的產品及/或服務。",
    "",
    " 3.帳戶資訊展示：如果您已擁有【########】平台帳戶，我們會在【########】平台服務中顯示您的上述個人資訊，以及您在【 ########】平台上或與【########】平台帳戶相關聯的產品和服務中執行的操作（您可透過【########】平台帳戶在我們提供的【########】入口或其他產品/服務入口使用我們提供的產品或服務），包括透過【########】平台帳戶集中展示您的個人資料、交易訂單。 ",
    "",
    " （二） 為您提供商品或服務資訊展示",
    "",
    "在您使用我們服務流程中，為識別帳號異常狀態、了解產品適配性、向您提供更契合您需求的頁面展示和搜尋結果，我們會自動收集您的使用情況並儲存為網絡日誌信息，包括： ",
    "",
    "設備資訊：我們會根據您在軟體安裝及/或使用中的特定操作，接收並記錄您所使用的設備相關資訊（包括設備型號、作業系統版本、設備設定、唯一設備識別碼、設備環境軟硬體特徵資訊）、設備所在位置相關資訊（包括您授權的GPS位置）。",
    "",
    "服務日誌資訊：當您使用我們的網站或用戶端提供的產品或服務時，我們會自動收集您對我們服務的詳細使用情況，作為服務日誌保存，包括瀏覽、點擊查看、搜尋查詢、收藏、新增至購物車、交易、售後、關注分享資訊、發布訊息，以及IP位址、瀏覽器類型、電信業者、使用語言、存取日期和時間。",
    "",
    "請注意，單獨的設備資訊、服務日誌資訊是無法識別特定自然人身份的資訊。如果我們將這類非個人資訊與其他資訊結合用於識別特定自然人身份，或將其與個人資訊結合使用，則在結合使用期間，這類非個人資訊將被視為個人訊息，除取得您授權或法律法規另有規定外，我們會將這類資訊做匿名化、去識別化處理。",
    "",
    "",
    "此外，我們也會為了不斷改進和優化上述的功能來使用您的上述資訊。 ",
    "",
    "",
    "為使我們及時獲悉並確認您的支付進度及狀態，為您提供售後與爭議解決服務，您同意我們可自您所選擇的交易對象、支付公司或您選擇的其他金融機構處收集與支付進度相關資訊。",
    "",
    " （三） 幫助向您完成商品或服務的交付",
    "",
    "為確保您購買的商品及/或服務能夠順利、安全、準確送達，我們會向為【########】平台提供物流資訊系統和技術服務的物流配送公司揭露訂單相關配送信息，並由其根據商品及/或服務提供主體的指定向相應的物流配送主體同步相關配送信息。​",
    "",
    "",
    " （四） 客服及爭議處理",
    "",
    "當您與我們聯絡或提出售中售後、爭議糾紛處理申請時，為了保障您的帳戶及系統安全，我們需要您提供必要的個人資訊以核驗您的註冊用戶身分。 ",
    "",
    "為方便與您聯繫、盡快幫助您解決問題或記錄相關問題的處理方案及結果，我們會保存您與我們的通信/通話記錄及相關內容（包括帳號資訊、訂單資訊、您為了證明相關事實提供的其他訊息，或您留下的聯絡資訊），如果您針對特定訂單進行諮詢、投訴或提供建議的，我們會使用您的帳號資訊和訂單資訊。",
    "",
    " （五） 為您提供安全保障",
    "",
    "為提高您使用我們及我們關聯公司、合作夥伴提供服務的安全性，保護您或其他用戶或公眾的人身財產安全免遭侵害，更好地預防釣魚網站、欺詐、網絡漏洞、電腦病毒、網絡攻擊、網路侵入安全風險，更準確地辨識違反法律法規或【########】相關協議規則的情況，我們使用或整合您的註冊用戶資訊、交易資訊、設備資訊、服務日誌資訊以及我們關聯公司、合作夥伴取得您授權或依據法律共享的信息，來綜合判斷您帳戶及交易風險、進行身份驗證、偵測及防範安全事件，並依法採取必要的記錄、稽核、分析、處置措施。 ",
    "",
    " （六）為您提供其他附加服務",
    "",
    "為向您提供更便利、更優質、個人化的產品及/或服務，努力提升您的體驗，我們在向您提供的以下附加服務中會收集、緩存和使用您的個人資訊。如果您不提供這些信息，不會影響您使用【########】的瀏覽、搜尋基本服務，但您無法獲得這些附加服務給您帶來的用戶體驗。",
    "",
    " 1、基於相機/相機的附加服務：您可在開啟相機（相機）權限後使用此功能。即使您已同意開啟相機（相機）權限，我們也僅會在您主動點擊對應圖示或錄影時透過相機取得照片資訊。",
    "",
    " 2、基於讀取、寫入外置存儲卡的附加服務：您可以在開啟存儲權限後，使用該功能上傳您的照片/圖片/視頻，以實現發表評論/分享或與客服溝通提供證明功能。​",
    "",
    " 3.基於讀取手機狀態和身分資訊的附加服務：我們將在您開啟權限後收集您的IMEI、裝置MAC位址、裝置序號、android ID、安裝應用程式列表，並根據您的裝置確認您的帳戶安全，實現安全風控。",
    "",
    " 4.基於檢視WLAN連線的附加功能：查看wifi連線狀態，確保網路可靠性。 ",
    "",
    "",
    " 5.基於相簿（圖片庫）的圖片存取及上傳、外部儲存、快取、發布圖片功能的附加服務：您可在開啟相簿權限後使用該功能上傳您的照片/圖片，發表評論/分享、拍照購物或與客服溝通提供證明功能。會透過您上傳的照片/圖片來識別您需要購買的商品或服務，或使用包含您所上傳照片或圖片的評論資訊。 【########】的其他功能。 ",
    "",
    " 6.基於安裝應用程式的附加服務：平台會定期更新應用程式的客戶端，您可以在更新的時候，開啟APP直接進行新版本的更新安裝及使用。 ",
    "",
    " 7.基於應用程式獲得目前運行任務的資訊的附加服務：平台在統計APP資料的情況下，會對APP的頁面進行判斷。 ",
    "",
    " 8.基於保持APP在喚醒狀態下的附加服務：您在本應用程式使用的情況下，後台會自動進行資料同步。 ",
    "",
    " 9、為更全面提供您所需要的商品或服務資訊、向您提供個人化或非個人化的服務，我們會根據您的瀏覽及搜尋記錄、設備資訊、位置資訊、訂單訊息，提取您的瀏覽、搜尋偏好、行為習慣、位置資訊的特徵。",
    "",
    "您瞭解並同意，上述附加服務需要您在您的裝置中開啟您的位置資訊（地理位置）、相機（相機）、相簿（圖片庫）、行事曆、外部儲存的存取權限，以實現這些權限所涉及資訊的收集和使用。以相關個人資訊來為您提供對應服務，您一旦關閉任一權限即代表您取消了授權，我們將不再基於對應權限繼續收集和使用相關個人信息，也無法為您提供該權限所對應的服務。 ",
    "",
    " （七）其他",
    "",
    " 1、若你提供的資訊中含有其他使用者的個人信息，在向【########】提供這些個人資訊之前，您需確保您已經取得合法的授權。若其中涉及兒童個人資訊的，您需在發布前取得對應兒童監護人的同意，前述情形下監護人有權透過本政策第十條的途徑聯絡我們，要求更正或刪除涉及兒童個人資訊的內容。",
    "",
    " 2、若我們將資訊用於本政策未載明的其他用途，或者將基於特定目的收集而來的信息用於其他目的，或者我們主動從第三方處獲取您的個人信息，均會事先獲得您的同意。",
    "",
    "若我們從第三方處間接獲取您的信息的，我們會在收集前明確以書面形式要求該第三方在已依法取得您同意後收集個人信息，並向您告知共享的信息內容，且涉及敏感資訊的在提供給我們使用前需經過您的明確確認，要求第三方對個人資訊來源的合法性和合規性作出承諾，如第三方有違反行為的，我們會明確要求對方承擔相應法律責任；同時，我們對個人資訊會進行安全加固（包括敏感資訊報備、敏感資訊加密儲存、存取權限控制）。不低於我們對自身使用者個人資訊同的保護手段與措施對間接取得的個人資訊進行保護。 ",
    "",
    " 3、徵得授權同意的例外",
    "",
    "您充分瞭解並同意，我們在以下情況下收集、使用您的個人資訊無需您的授權同意，且我們不會回應您提出的更正/修改、刪除、登出、撤回同意、索取資訊的請求： ",
    "",
    " （1）與國家安全、國防安全有關的； ",
    "",
    " （2）與公共安全、公共衛生、重大公共利益有關的； ",
    "",
    " （3）與犯罪偵查、起訴、審判及判決執行司法或行政執法有關的； ",
    "",
    " （4）出於維護您或其他個人的生命、財產相關的重大合法權益但又很難得到本人同意的； ",
    "",
    " （5）您自行對社會大眾公開的個人資訊； ",
    "",
    " （6）從合法公開揭露的資訊中收集個人資訊的，如合法的新聞報告、政府資訊公開管道。 ",
    "",
    " （7）根據與您簽訂和履行相關協議或其他書面文件所必需的； ",
    "",
    " （8）用於維護所提供的產品及/或服務的安全穩定運作所必需的，發現、處置產品及/或服務的故障； ",
    "",
    " （9）為合法的新聞報道所必需的； ",
    "",
    " （10）學術研究機構基於公共利益開展統計或學術研究所必要，且對外提供學術研究或描述的結果時，對結果中所包含的個人資訊進行去標識化處理的； ",
    "",
    " （11）法令規定的其他情形。 ",
    "",
    "請知悉，根據適用的法律，若我們對個人資訊採取技術措施和其他必要措施進行處理，使得資料接收方無法重新識別特定個人且不能復原，或我們會對收集的資訊進行去識別化地研究、統計分析和預測，用於改善【########】的內容和佈局，為商業決策提供產品或服務支撐，以及改進我們的產品和服務（包括使用匿名資料進行機器學習或模型演算法訓練），則此類處理後資料的使用無需另行向您通知並徵得您的同意。 ",
    "",
    " 4、如我們停止營運【########】產品或服務，我們將及時停止繼續收集您個人資訊的活動，將停止營運的通知以逐一送達或公告的形式通知您，並將我們所持有的與已關停業務相關的個人資訊進行刪除或匿名化處理。",
    "",
    "二、我們如何使用Cookie ",
    "為讓您獲得更輕鬆的存取體驗、向您推薦您感興趣的內容，我們會在您的行動裝置上儲存名為Cookie的小資料檔案。Cookie通常包含識別碼、網站名稱以及一些號碼和字元。​",
    "",
    "若您不同意我們在您的行動裝置上儲存Cookie的小數據文件，您可停止使用【########】。 ",
    "",
    "三、我們如何分享、轉讓、公開揭露您的資訊",
    " （一）共享",
    "",
    "我們不會與【########】服務提供者以外的公司、組織和個人分享您的個人訊息，但以下情況除外： ",
    "",
    " 1、在法定情形下的共享：我們會根據法律法規規定、訴訟、爭議解決需要，或按行政、司法機關依法提出的要求，對外共享您的個人資訊。 ",
    "",
    " 2、在取得明確同意的情況下分享：取得您的明確同意後，我們會與其他方分享您的個人資訊。 ",
    "",
    " 3、在您主動選擇情況下共享：您透過【########】平台購買商品或服務，我們會根據您的選擇，將您的訂單資訊中與交易相關的必要資訊分享給相關商品或服務的提供者，以實現您的交易及售後服務需求。",
    "",
    " 4.與關聯公司間共享：為便於我們基於【########】平台帳戶向您提供產品和服務，推薦您感興趣的信息，識別註冊用戶帳號異常，保護【#### ####】關聯公司或其他使用者或公眾的人身財產安全免遭侵害，您的個人資訊會與我們的關聯公司和/或其指定的服務提供者共享。改變個人資訊的使用及處理目的，將再次徵求您的授權同意。 ",
    "",
    " 5、與授權合作夥伴共享：我們委託授權合作夥伴為您提供某些服務或代表我們履行職能，我們僅會出於本隱私權政策聲明的合法、正當、必要、特定、明確的目的共享您的訊息，授權合作夥伴只能接觸到其履行職責所需信息，且不得將此信息用於其他任何目的。",
    "",
    "目前，我們的授權合作夥伴包括以下類型： ",
    "",
    " （1）商家、服務提供者和其他合作夥伴。我們將資訊傳送給支援我們業務的商家、服務提供者和其他合作夥伴，這些支援包括受我們委託提供的技術基礎設施服務、分析我們服務的使用方式、提供客戶服務、支付便利性或進行學術研究和調查，這些資訊難以與您的身份資訊相關聯，這些資訊將幫助我們分析相關服務的有效性。",
    "",
    "為確保我們客戶端的穩定運作、功能實現，使您能夠使用和享受更多的服務及功能，我們的應用中會嵌入授權合作夥伴的SDK。我們會對授權合作夥伴獲取有關資訊的應用程式接口（API）、軟體工具開發包（SDK）進行嚴格的安全檢測，並與授權合作夥伴約定嚴格的資料保護措施，令其按照本政策以及其他任何相關的保密和安全措施來處理個人資訊。",
    "",
    "具體如下： ",
    "",
    " ",
    " SDK名稱：KTPsdk ",
    "",
    "廠商：深圳市金泰誼電子有限公司",
    "",
    "收集資訊類型：取得手機藍牙列表，掃描手機周圍藍牙裝置",
    "",
    "使用目的：用於在特定裝置上讀取藍牙列表，連接藍牙，透過藍牙傳送訊息。 ",
    " （特定設備：深圳市金泰誼電子有限公司生產的無線POS終端） ",
    "",
    "使用的敏感系統權限：藍牙掃描權限，藍牙連線權限，定位權限。 ",
    "",
    "具體使用場景：連接藍牙，藍牙連線權限。掃描附近藍牙，藍牙掃描權限，定位權限。 ",
    "",
    "",
    "",
    " SDK名稱：flutter_bluetooth_serial ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：取得手機藍牙列表，掃描手機周圍藍牙裝置",
    "",
    "使用目的：讀取裝置藍牙列表，連接藍牙，透過藍牙傳送訊息。 ",
    "",
    "使用的敏感系統權限：藍牙掃描權限，藍牙連線權限，定位權限。 ",
    "",
    "具體使用場景：連接藍牙，藍牙連線權限。掃描附近藍牙，藍牙掃描權限，定位權限。 ",
    "",
    "",
    " SDK名稱：image_picker ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：取得手機讀寫儲存權限，取得相機使用權限",
    "",
    "使用目的：用於從影像庫中挑選影像， 以及使用相機拍攝新照片。 ",
    "",
    "使用的敏感系統權限：讀寫外部儲存權限，相機權限",
    "",
    "具體使用場景：設置，修改商品照片功能使用照相權限，存儲權限。 ",
    "",
    "",
    "",
    " SDK名稱：vibration ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：取得手機震動權限",
    "",
    "使用目的：掃碼成功後震動手機。 ",
    "",
    "使用的敏感系統權限：手機震動權限",
    "",
    "具體使用場景：掃碼成功後震動手機，震動權限。 ",
    "",
    "",
    "",
    " SDK名稱：qr_code_scanner ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：取得相機使用權限",
    "",
    "使用目的：掃描二維碼和條碼。 ",
    "",
    "使用的敏感系統權限：相機使用權限",
    "",
    "具體使用情境：掃描商品條碼，相機使用權限，掃描單據二維碼，相機使用權限。 ",
    "",
    "",
    "",
    " SDK名稱：path_provider ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：取得手機讀寫儲存權限",
    "",
    "使用目的：用於在檔案系統上尋找常用位置。 ",
    "",
    "使用的敏感系統權限：讀寫外部儲存權限",
    "",
    "具體使用場景：取得APP外部儲存位址，讀寫外部儲存權限，在外部儲存位址建立資料夾，讀寫外部儲存權限。 ",
    "",
    "",
    " SDK名稱：open_filex ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：取得手機讀寫儲存權限",
    "",
    "使用目的：用於在檔案系統內開啟指定檔案。 ",
    "",
    "使用的敏感系統權限：讀寫外部儲存權限",
    "",
    "具體使用場景：軟體更新安裝包下載到外部儲存後開啟該檔案以完成更新，讀寫外部儲存權限。 ",
    "",
    "",
    "",
    " SDK名稱：flutter_downloader ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：取得手機讀寫儲存權限，取得手機通知權限",
    "",
    "使用目的：用於建立和管理下載任務的外掛程式。 ",
    "",
    "使用的敏感系統權限：讀寫外部儲存權限，取得手機通知權限",
    "",
    "具體使用情境：軟體下載更新安裝包外部儲存，讀寫外部儲存權限，通知欄展示下載資訊和進度，通知權限。 ",
    "",
    "",
    "",
    " SDK名稱：permission_handler ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：無特定權限要求",
    "",
    "使用目的：用於管理和請求應用程式權限的外掛程式。 ",
    "",
    "使用的敏感系統權限：與所要求的權限相關（例如相機、位置、聯絡人） ",
    "",
    "具體使用場景：請求使用者授權存取設備功能（如相機、位置），檢查和管理權限狀態。 ",
    "",
    "",
    " SDK名稱：share_plus ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：無特定權限要求",
    "",
    "使用目的：用於在應用程式內分享內容的外掛程式。 ",
    "",
    "使用的敏感系統權限：無特定敏感權限",
    "",
    "具體使用場景：使用者可以透過外掛程式將文字、圖片、文件等內容分享到其他應用程式或平台，例如社群媒體、訊息應用程式或郵件用戶端。 ",
    "",
    "",
    "",
    " SDK名稱：camera ",
    "",
    "廠商：第三方開源插件",
    "",
    "收集資訊類型：無特定權限要求",
    "",
    "使用目的：用於在應用程式中存取和控制設備相機的插件。 ",
    "",
    "使用的敏感系統權限：相機權限（用於存取設備相機） ",
    "",
    "具體使用場景：拍照、錄影、預覽相機內容等操作，通常用於需要相機功能的應用。 ",
    "",
    "",
    "",
    " ",
    "個人資訊欄位定義以及窮舉",
    "",
    "設備MAC位址：設備的mac位址，確認行動裝置位置的位址",
    "",
    "唯一設備識別碼：iOS設備為IDFA，Android設備為IMEI、oaid、AndroidId、UUID（Android以上識別碼會收集多個） ",
    "",
    "設備IP位址：行動互聯網連結協議，確認網路連線的營運商服務",
    "",
    "設備資訊：設備品牌、設備型號、作業系統、作業系統版本",
    "",
    "網路資訊：目前網路環境（如WIFI、5G、4G、3G、2G） ",
    "",
    "",
    " （二）轉讓",
    "",
    "我們不會將您的個人資訊轉讓給任何公司、組織和個人，但以下情況除外： ",
    "",
    " 1、在取得明確同意的情況下轉讓：取得您的明確同意後，我們會向其他方轉讓您的個人資訊； ",
    "",
    " 2、在【########】服務提供者發生合併、收購或破產清算情形，或其他涉及合併、收購或破產清算情形時，如涉及到個人資訊轉讓，我們會要求新的持有您個人資訊的公司、組織繼續受本政策的約束，否則我們將要求該公司、組織和個人重新向您徵求授權同意。",
    "",
    " （三）公開揭露",
    "",
    "我們只會在下列情況下，公開揭露您的個人資訊： ",
    "",
    " 1、取得您明確同意或基於您的主動選擇，我們會公開揭露您的個人資訊； ",
    "",
    " 2、如果我們確定您出現違反法律法規或嚴重違反【########】平台相關協議及規則的情況，或為保護【########】平台用戶或公眾的人身財產安全免遭侵害，我們依據法律法規或徵得您同意的情況下披露關於您的個人信息，包括相關違規行為以及【########】平台已對您採取的措施。",
    "",
    " （四）分享、轉讓、公開揭露個人資料時事先徵得授權同意的例外",
    "",
    "以下情形中，分享、轉讓、公開揭露您的個人資訊無須事先徵得您的授權同意： ",
    "",
    " 1、與國家安全、國防安全有關的； ",
    "",
    " 2、與公共安全、公共衛生、重大公共利益有關的； ",
    "",
    " 3、與犯罪偵查、起訴、審判及判決執行司法或行政執法有關的； ",
    "",
    " 4、出於維護您或其他個人的生命、財產相關的重大合法權益但又很難得到本人同意的； ",
    "",
    " 5、您自行向社會大眾公開的個人資訊； ",
    "",
    " 6、從合法公開披露的資訊中收集個人資訊的，如合法的新聞報導、政府資訊公開。 ",
    "",
    "請知悉，根據適用的法律，若我們對個人資訊採取技術措施和其他必要措施進行處理，使得資料接收方無法重新識別特定個人且不能復原，則此類處理後資料的共享、轉讓、公開披露無需另行向您通知並徵得您的同意。",
    "",
    "四、我們如何儲存您的資料",
    " （一）存放地點",
    "",
    "我們在營運中收集和產生的個人信息，將儲存在伺服器託管地域。以下情況下，我們會在履行了法律規定的義務後，向相關實體提供您的個人資訊： ",
    "",
    " 1、適用的法律有明確規定； ",
    "",
    " 2、取得您的明確授權； ",
    "",
    " 3、您透過網路進行跨境交易個人主動行為。 ",
    "",
    "針對上述情形，我們會透過合約形式確保以不低於本政策規定的程度保護您的個人資訊。 ",
    "",
    " （二）存放期限",
    "",
    "我們會採取合理可行的措施，盡力避免收集和處理無關的個人資訊。我們只會在達成本政策所述目的所需的期限內保留您的個人信息，除非法律有強制的留存要求，交易信息保存時間自交易完成之日起不少於三年。",
    "",
    " 1、完成與您相關的交易目的、維護相應交易及業務記錄，以應對您的查詢或投訴； ",
    "",
    " 2、保證我們為您提供服務的安全與品質； ",
    "",
    " 3、您是否同意較長的留存期間； ",
    "",
    " 4、是否有保留期限的其他特別約定。 ",
    "",
    "在超出保留期間後，我們會在15天內根據適用法律的要求刪除您的個人信息，或使其匿名化處理。此外，在註銷帳戶後，我們將在15天內停止為您提供產品和服務，並根據您的要求，刪除您的個人資訊或匿名化處理，法律法規另有規定的除外。",
    "",
    "五、我們如何保護您的資訊",
    " （一）我們已採取符合業界標準、合理可行的安全防護措施保護您的訊息，防止個人資訊遭到未經授權存取、公開揭露、使用、修改、損壞或遺失。間交換資料時受SSL協定加密保護；我們同時對【## ######】網站提供HTTPS協定安全瀏覽方式；我們會使用加密技術來提高個人資訊的安全性；我們會使用受信賴的保護機制來防止個人資訊遭到惡意攻擊；我們會部署存取控制機制，盡力確保只有授權人員才可存取個人資訊。 ",
    "",
    "",
    "",
    " （二）我們會採取合理可行的措施，盡力避免收集無關的個人資訊。我們只會在達成本政策所述目的所需的期限內保留您的個人信息，除非法律有強制的存留要求，交易資訊保存時間自交易完成之日起不少於三年。",
    "",
    " 1、完成與您相關的交易目的、維護相應交易及業務記錄、應對您的查詢或投訴； ",
    "",
    " 2、保證我們為您提供服務的安全與品質； ",
    "",
    " 3、您是否同意較長的留存期間； ",
    "",
    " 4、是否有保留期限的其他特別約定。 ",
    "",
    "在您的個人資訊超出保留期間後，我們會根據適用法律的要求刪除您的個人訊息，或使其匿名化處理。 ",
    "",
    " （三）網路並非絕對安全的環境，使用【#######】平台服務時，我們強烈建議您不要使用非【########】平台推薦的通訊方式發送您的訊息。聯絡方式、交流資訊或分享內容相關資訊的第三方。",
    "",
    "在使用【########】服務進行線上交易時，您不可避免地要向交易對方或潛在的交易對方披露自己的個人信息，如聯絡方式或聯絡地址。請您妥善保護自己的個人訊息，僅在必要的情況下向他人提供。您的申請採取相應措施。",
    "",
    "請注意，您在使用我們服務時自願分享甚至公開分享的信息，會涉及您或他人的個人信息甚至個人敏感信息。請您更加謹慎地考慮，是否在使用我們的服務時共享甚至公開分享相關資訊. ",
    "",
    "請使用複雜密碼，協助我們確保您的帳號安全。我們將盡力保障您傳送給我們的任何資訊的安全性。如果我們的實體、技術或管理防護設施遭到破壞，導致資訊被非授權存取、公開揭露、竄改或毀，導致您的合法權益受損，我們將承擔相應的法律責任。",
    "",
    " （四）我們將不定期更新並公開安全風險、個人資訊安全影響有關內容，您可透過【########】公告方式取得。 ",
    "",
    " （五）在不幸發生個人資訊安全事件後，我們將按照法律法規的要求向您告知：安全事件的基本情況和的影響、我們已採取或將要採取的處置措施、您可自主防範和降低風險的建議、對您的補救措施。",
    "",
    "",
    "六、您如何管理您的資訊",
    "您可以透過以下方式存取及管理您的資訊： ",
    "",
    " （一）查詢、更正及補充您的資料",
    "",
    "您有權查詢、更正或補充您的資訊。您可以透過以下方式自行進行： ",
    "",
    " 1.登入【########】客戶端，進入「帳號」頁面，修正個人資料及個人帳號相關資料； ",
    " 2、授權相關管理人員進行修改",
    "",
    " （二）刪除您的資料",
    "",
    "",
    "在下列情形中，您可以向我們提出刪除個人資訊的請求： ",
    "",
    " 1、如果我們處理個人資訊的行為違反法律法規； ",
    "",
    " 2、如果我們蒐集、使用您的個人資訊，卻未徵得您的明確同意； ",
    "",
    " 3、如果我們處理個人資訊的行為嚴重違反了與您的約定； ",
    "",
    " 4、如果您不再使用我們的產品或服務，或您主動申請註銷了帳號； ",
    "",
    " 5、如果我們永久不再為您提供產品或服務。 ",
    "",
    "若我們決定回應您的刪除要求，我們還將同時盡通知從我們處獲得您的個人資訊的主體，並要求其及時刪除（除非法律法規另有規定，或這些主體已獨立獲得您的授權） 。​",
    "",
    "當您或我們協助您刪除相關資訊後，因為適用的法律和安全技術，我們無法立即從備份系統中刪除相應的信息，我們將安全地儲存您的個人資訊並將其與任何進一步處理隔離，直到備份可以清除或實現匿名。",
    "",
    " （三）改變您授權同意的範圍",
    "",
    "如果您不再選擇將您的個人資訊提供給我們或拒絕使用我們提供的部分服務，您可以透過設定您的智慧型行動裝置來關閉您授權給【########】的部分權限。 ",
    "",
    "當您收回同意後，我們將不再處理相應的個人資訊。但您收回同意的決定，不會影響先前基於您的授權而開展的個人資訊處理。 ",
    "",
    " （四）註銷您的帳戶",
    "",
    "您可以透過以下方式申請註銷您的帳戶： ",
    "",
    " 1、您可以聯絡您的後台管理員為您註銷帳戶。 ",
    "",
    "在您登出帳戶之後，我們將停止為您提供商品或服務，並根據適用法律的要求刪除您的個人信息，或使其匿名化處理。 ",
    "",
    "（五）约束信息系统自动决策",
    "",
    "在某些业务功能中，我们仅依据信息系统、算法在内的非人工自动决策机制做出决定。如果这些决定显著影响您的合法权益，您有权要求我们做出解释，我们也将在不侵害【########】商业秘密或其他用户权益、社会公共利益的前提下提供申诉方法。",
    "",
    "（六）响应您的上述请求",
    "",
    "為保障安全，您需要提供書面請求，或以其他方式證明您的身份。我們會先要求您驗證自己的身份，然後再處理您的請求。 ",
    "",
    "我們將在15天內做出答覆。如您不滿意，還可以透過【########】客服發起投訴。 ",
    "",
    "對於您合理的請求，我們原則上不收取費用，但對多次重複、超出合理限度的請求，我們將酌情收取一定費用。對於與您的身份不直接關聯的信息、無端重複信息，或者需要過多技術手段（需要開發新系統或從根本上改變現行慣例）、給他人合法權益帶來風險或不切實際的請求，我們會予以拒絕。",
    "",
    "在下列情形中，依照法規要求，我們將無法回應您的要求： ",
    "",
    " 1、與國家安全、國防安全有關的； ",
    "",
    " 2、與公共安全、公共衛生、重大公共利益有關的； ",
    "",
    " 3、與犯罪偵查、起訴、審判及執行判決有關的； ",
    "",
    " 4.有充分證據顯示個人資訊主體存在主觀惡意或濫用權利的； ",
    "",
    " 5、回應您的要求將導致您或其他個人、組織的合法權益受到嚴重損害的； ",
    "",
    " 6、涉及商業機密的。 ",
    "",
    "",
    "七、您的資訊如何在全球範圍轉移",
    "",
    " 1、適用的法律有明確規定； ",
    "",
    " 2、取得您的明確授權； ",
    "",
    " 3、您透過網路進行跨境交易的個人主動行為。 ",
    "",
    "針對以上情形，我們會確保依據本隱私權政策對您的個人資訊提供足夠的保護。 ",
    "",
    "八、本隱私權政策如何更新",
    "我們的隱私權政策變更。 ",
    "",
    "未经您明确同意，我们不会限制您按照本隐私政策所应享有的权利。我们会在App上发布对隐私政策的更新。",
    "",
    "对于重大变更，我们还会提供更为显著的通知（包括我们会通过【########】公示的方式进行通知甚至向您提供弹窗提示）。",
    "",
    "本政策所指的重大变更包括但不限于：",
    "",
    " 1.我們的服務模式發生重大變化。如處理個人資訊的目的、處理的個人資訊類型、個人資訊的使用方式； ",
    "",
    " 2、我們在控制權方面發生重大變化。如併購重組所引起的資訊控制者變更； ",
    "",
    " 3、個人資訊分享、轉讓或公開揭露的主要對象發生變更； ",
    "",
    " 4、您參與個人資訊處理方面的權利及其行使方式發生重大變化； ",
    "",
    " 5、我們負責處理個人資訊安全的責任部門、聯絡方式及投訴管道發生變化； ",
    "",
    " 6.個人資訊安全影響評估報告顯示存在高風險。 ",
    "",
    "九、如何聯絡我們",
    "您可以透過以下方式與我們聯繫，我們將在15天內回覆您的請求： ",
    "",
    " 1、如對本政策內容有任何疑問、意見或建議，您可透過【########】客服信箱：【support@########.com】與我們聯絡。 ",
    "",
    " 2、如發現個人資訊洩露，您可以聯絡我們的客服人員進行投訴舉報； ",
    "",
    "如果您對我們的回覆不滿意，特別是您認為我們的個人資訊處理行為損害了您的合法權益，您也可以透過向被告住所地有管轄權的法院提起訴訟來尋求解決方案",
  ],
  fr_FR: [
    " ######## Politique de confidentialité ",
    " 【Durée effective】 : 2024-7-23 ",
    " 【Heure de mise à jour】：2024-7-23 ",
    "",
    " Conseils ",
    " Avant d'utiliser les produits ou services de [########], assurez-vous de lire attentivement et de bien comprendre cette politique, en particulier les termes marqués en gras/gras soulignés. Vous devez les lire attentivement. Assurez-vous de comprenez et acceptez pleinement avant de commencer à l'utiliser. Si vous avez des questions, des commentaires ou des suggestions sur le contenu de cette politique, vous pouvez nous contacter via les différentes méthodes de contact fournies par [########] ",
    "",
    ' Votre confiance est très importante pour nous. Nous sommes bien conscients de l\'importance des informations personnelles pour vous. Nous prendrons les mesures de protection de sécurité appropriées conformément aux exigences légales et réglementaires et ferons de notre mieux pour protéger vos informations personnelles de manière sûre et contrôlable. Dans cette optique, [### #####] Le prestataire de services (ou dénommé " nous " ou " [########] ") formule la présente politique de confidentialité (ci-après dénommée. comme "cette politique/cette politique de confidentialité") et vous rappelle : La politique s\'applique à [Toposfy Tous les produits et services fournis par Dashboard ',
    "",
    " Il convient de noter que cette politique ne s'applique pas aux services qui vous sont fournis par d'autres tiers, ni aux produits ou services de [########] qui ont des politiques de confidentialité définies séparément. ",
    "",
    " Définition de la partie 1 ",
    " 【########】Prestataire de services : 【Chengdu Huitong Times Software Co., Ltd.】 ",
    "",
    " Informations personnelles : désigne diverses informations enregistrées électroniquement ou par d'autres moyens qui peuvent identifier l'identité d'une personne physique spécifique ou refléter les activités d'une personne physique spécifique, seules ou en combinaison avec d'autres informations. ",
    "",
    " Informations personnelles sensibles : inclut l'adresse e-mail et les informations de commande. ",
    "",
    " Suppression des informations personnelles : désigne l'acte de supprimer des informations personnelles des systèmes impliqués dans la réalisation des fonctions commerciales quotidiennes, afin qu'elles ne puissent pas être récupérées ou consultées. ",
    "",
    " Enfants : désigne les mineurs de moins de quatorze ans. ",
    "",
    " Sauf accord contraire, les définitions utilisées dans cette politique ont la même signification que celles contenues dans le Contrat d'utilisation de la plateforme [########]. ",
    "",
    " Partie 2 Politique de confidentialité ",
    " Cette section de politique de confidentialité vous aidera à comprendre ce qui suit : ",
    "",
    " 1. Comment nous collectons et utilisons vos informations ",
    "",
    " 2. Comment nous utilisons les cookies ",
    "",
    " 3. Comment nous partageons, transférons et divulguons publiquement vos informations ",
    "",
    " 4. Comment nous stockons vos informations ",
    "",
    " 5. Comment nous protégeons vos informations ",
    "",
    " 6. Comment gérez-vous vos informations ",
    "",
    " 7. Comment vos informations sont transférées à l'échelle mondiale ",
    "",
    " 8. Comment mettre à jour cette politique de confidentialité ",
    "",
    " 9. Comment nous contacter ",
    "",
    " 1. Comment nous collectons et utilisons vos informations ",
    " Lorsque vous utilisez nos produits et/ou services, les informations personnelles que nous avons besoin de collecter et d'utiliser comprennent les deux types suivants : ",
    "",
    " 1. Afin de vous fournir les fonctions de base de nos produits et/ou services, nous collecterons conformément aux réglementations en vigueur. Nous obtiendrons vos informations personnelles et celles de votre appareil (IMEI, adresse MAC de l'appareil, presse-papiers, numéro de série de l'appareil). , andridID), liste d'installation de l'application. Si vous refusez de fournir les informations correspondantes, vous ne pourrez pas utiliser normalement nos produits et/ou services, mais cela n'affectera pas votre navigation sur nos pages web et nos pages clients ",
    "",
    " 2. Afin de vous fournir des fonctions supplémentaires de nos produits et/ou services, vous pouvez choisir de nous autoriser à collecter et à utiliser vos informations personnelles sensibles. Si vous refusez de les fournir, vous ne pourrez pas utiliser les informations pertinentes. fonctions supplémentaires normalement ou atteindre ce que nous souhaitons. Les effets fonctionnels obtenus n'affecteront pas votre utilisation normale des fonctions de base de nos produits et/ou services ",
    "",
    " Vous comprenez et acceptez : ",
    "",
    " 1. Nous nous engageons à créer une variété de produits et de services pour répondre à vos besoins. Parce que nous vous fournissons de nombreux types de produits et services et que la gamme spécifique de produits/services que différents utilisateurs choisissent d'utiliser est différente, la base /fonctions supplémentaires et Le type et la portée des informations personnelles collectées et utilisées varient, veuillez vous référer aux fonctions spécifiques du produit/service ",
    "",
    " 2. Afin de vous offrir une meilleure expérience de produits et de services, nous continuons à travailler dur pour améliorer notre technologie et nous lancerons de temps en temps des fonctions nouvelles ou optimisées, ce qui nous oblige à collecter et utiliser à nouveau vos informations personnelles ou modifier vos informations personnelles. Le but ou la méthode d’utilisation des informations sera mis à jour en mettant à jour cette politique, les fenêtres contextuelles et les pages. La méthode d'invite vous expliquera séparément le but, la portée et l'utilisation des informations correspondantes, et demandera votre consentement exprès. Au cours de ce processus, si vous avez des questions, des commentaires ou des suggestions, vous pouvez nous contacter via [#####. #. ##】Contactez-nous en utilisant les différentes méthodes de contact proposées, et nous vous répondrons dans les plus brefs délais. ",
    "",
    " Nous collecterons et utiliserons vos informations personnelles pour mettre en œuvre les fonctions décrites ci-dessous dans cette politique : ",
    "",
    " (1) Vous aider à devenir notre utilisateur enregistré ",
    "",
    " 1. Service utilisateur enregistré de base ",
    "",
    " Pour vous inscrire en tant qu'utilisateur enregistré et utiliser nos services d'utilisateur enregistré, vous devez fournir votre numéro de téléphone mobile, votre adresse e-mail ainsi que le nom d'utilisateur et le mot de passe enregistrés prévus pour créer un compte sur la plateforme [########]. Si vous seulement besoin de parcourir, de rechercher un service, vous n'avez pas besoin de vous inscrire en tant qu'utilisateur enregistré et de fournir les informations ci-dessus ",
    "",
    " Lorsque vous vous connecterez à votre compte, nous vérifierons votre identité d'utilisateur enregistré sur la base des informations ci-dessus que vous avez fournies pour garantir que nous vous fournissons des services. S'il existe des scénarios dans lesquels il est nécessaire de déterminer votre identité d'utilisateur enregistré conformément à la loi (y compris l'application du droit administratif ou judiciaire Lorsque les parties concernées sont identifiées dans un litige), vous nous autorisez à obtenir les informations d'authentification pertinentes de votre compte de paiement correspondant aux fins ci-dessus ",
    "",
    " Nous vous fournirons les droits et intérêts fondamentaux correspondant à l'utilisateur enregistré correspondant en fonction de l'utilisation de votre compte utilisateur enregistré. ",
    "",
    " 2. Services supplémentaires pour les utilisateurs enregistrés ",
    "",
    " Si vous choisissez de fournir votre vrai nom, votre sexe, votre date de naissance et vos informations personnelles de résidence, nous pouvons vous fournir des services d'utilisateur enregistré plus personnalisés. Pour garantir l'identification de la transaction, le pseudonyme et l'avatar de votre compte seront affichés publiquement. ",
    "",
    " Connexion autorisée : nous obtiendrons les informations de votre compte tiers auprès d'un tiers sur la base de votre autorisation et les lierons à votre compte de plateforme [########] afin que vous puissiez y accéder directement via votre tiers. Connectez-vous et utilisez nos produits et/ou services. Nous utiliserons vos informations pertinentes dans le cadre de votre autorisation ",
    "",
    " 3. Affichage des informations sur le compte : si vous possédez déjà un compte sur la plateforme [########], nous afficherons vos informations personnelles ci-dessus dans le service de la plateforme [########], ainsi que votre Service de plateforme [########] ########]Opérations effectuées sur la plateforme ou dans les produits et services associés au compte de plateforme 【########] (vous pouvez utiliser 【########】 Les comptes de plateforme utilisent les produits ou services que nous fournissons sur le portail [########] ou sur d'autres portails de produits/services que nous proposons), y compris l'affichage centralisé de vos informations via le [########] compte de la plateforme Informations personnelles, ordres de trading Nous respecterons vos choix concernant les services de la plateforme [########] et les paramètres du compte de la plateforme [########]. ",
    "",
    " (2) Vous fournir un affichage d'informations sur le produit ou le service ",
    "",
    " Lorsque vous utilisez nos services, afin d'identifier un statut de compte anormal, de comprendre l'adéquation du produit et de vous fournir un affichage de page et des résultats de recherche mieux adaptés à vos besoins, nous collecterons automatiquement votre utilisation et la stockerons sous forme d'informations de journal Web. inclure : ",
    "",
    " Informations sur l'appareil : nous recevrons et enregistrerons les informations relatives à l'appareil que vous utilisez (y compris le modèle de l'appareil, la version du système d'exploitation, les paramètres de l'appareil, l'identifiant unique de l'appareil, le logiciel d'environnement de l'appareil, etc.) en fonction de vos opérations spécifiques lors de l'installation du logiciel et/ou utilisation. Informations sur les fonctionnalités du matériel), informations relatives à la localisation de l'appareil (y compris votre position GPS autorisée) ",
    "",
    " Informations du journal de service : lorsque vous utilisez les produits ou services fournis par notre site Web ou notre client, nous collecterons automatiquement votre utilisation détaillée de nos services et les enregistrerons sous forme de journaux de service, y compris la navigation, les clics pour afficher, les requêtes de recherche, les collections, Ajouter à panier, transaction, après-vente, suivre les informations de partage, publier des informations, ainsi que l'adresse IP, le type de navigateur, l'opérateur télécom, la langue utilisée, la date et l'heure d'accès ",
    "",
    " Veuillez noter que les informations distinctes sur l'appareil et les informations du journal de service sont des informations qui ne peuvent pas identifier une personne physique spécifique. Si nous combinons ces informations non personnelles avec d'autres informations pour identifier une personne physique spécifique, ou si nous les utilisons en combinaison avec des informations personnelles, alors pendant pendant la période d'utilisation combinée, ce type d'informations non personnelles sera traité comme des informations personnelles. Sauf disposition contraire de votre autorisation ou des lois et réglementations, nous anonymiserons et anonymiserons ce type d'informations ",
    "",
    "",
    " De plus, nous utiliserons également vos informations ci-dessus afin d'améliorer et d'optimiser continuellement les fonctions ci-dessus. ",
    "",
    "",
    " Afin que nous puissions connaître et confirmer la progression et le statut de votre paiement en temps opportun, et pour vous fournir des services après-vente et de résolution des litiges, vous acceptez que nous puissions collecter et payer des informations sur la progression du paiement auprès du partenaire de transaction choisi, de la société de paiement. ou autre institution financière de votre choix. Informations connexes ",
    "",
    " (3) Aider à finaliser la livraison de biens ou de services pour vous ",
    "",
    " Afin de garantir que les biens et/ou services que vous avez achetés peuvent être livrés de manière fluide, sûre et précise, nous divulguerons la livraison liée à la commande à la société de logistique et de distribution qui fournit des systèmes d'information logistiques et des services techniques pour le [### #####] Les informations de la plateforme et les informations de livraison pertinentes seront synchronisées avec les entités de logistique et de livraison correspondantes en fonction de la désignation des biens et/ou des prestataires de services. Vous savez et acceptez que les entités de logistique et de livraison correspondantes seront inévitablement. connaître et utiliser vos informations de livraison aux fins de la livraison ",
    "",
    "",
    " (4) Service client et gestion des litiges ",
    "",
    " Lorsque vous nous contactez ou soumettez une demande de vente ou de résolution d'un litige, afin de protéger la sécurité de votre compte et de votre système, nous avons besoin que vous fournissiez les informations personnelles nécessaires pour vérifier votre identité d'utilisateur enregistré. ",
    "",
    " Afin de faciliter le contact avec vous, de vous aider à résoudre le problème dans les plus brefs délais ou d'enregistrer les solutions et les résultats des problèmes associés, nous enregistrerons les enregistrements de communication/appel et le contenu associé entre vous et nous (y compris les informations de compte, les commandes informations et les informations que vous fournissez pour prouver des faits pertinents, d'autres informations ou les coordonnées que vous avez laissées), si vous faites des demandes de renseignements, des plaintes ou fournissez des suggestions pour des commandes spécifiques, nous utiliserons les informations de votre compte et les informations de commande ",
    "",
    " (5) Pour vous assurer la sécurité ",
    "",
    " Afin d'améliorer la sécurité de votre utilisation des services fournis par nous et nos sociétés affiliées et partenaires, de protéger votre sécurité personnelle et patrimoniale ou celle des autres utilisateurs ou du public contre les violations, et de mieux prévenir les sites Web de phishing, la fraude et les vulnérabilités des réseaux. , les virus informatiques et les attaques réseau et les risques de sécurité d'intrusion réseau, et identifier plus précisément les violations des lois et réglementations ou des réglementations de protocole associées à [########] Dans ce cas, nous utilisons ou intégrons vos informations d'utilisateur enregistré, vos informations de transaction, vos informations sur l'appareil, vos informations de journal de service et les informations que nos sociétés affiliées et partenaires ont obtenues votre autorisation ou partagées conformément à la loi pour juger de manière globale de votre compte et des risques de transaction. et conduite Vérifier l'identité, détecter et prévenir les incidents de sécurité, et prendre les mesures nécessaires d'enregistrement, d'audit, d'analyse et d'élimination conformément à la loi. ",
    "",
    " (6) Vous fournir d'autres services supplémentaires ",
    "",
    " Afin de vous fournir des produits et/ou services plus pratiques, de meilleure qualité et personnalisés, et de nous efforcer d'améliorer votre expérience, nous collecterons, mettrons en cache et utiliserons vos informations personnelles dans les services supplémentaires suivants qui vous sont fournis. Si vous ne fournissez pas ces informations n'affecteront pas votre utilisation des services de navigation et de recherche de base de [########], mais vous ne pourrez pas obtenir l'expérience utilisateur que vous offrent ces services supplémentaires. Ces services supplémentaires incluent : ",
    "",
    " 1. Services supplémentaires basés sur la caméra/caméra : vous pouvez utiliser cette fonction après avoir activé l'autorisation de la caméra (caméra). Même si vous avez accepté d'activer l'autorisation de la caméra (caméra), nous ne l'utiliserons que lorsque vous cliquez activement sur l'icône correspondante ou enregistrez une vidéo Obtenez des informations sur les photos via l'appareil photo .",
    "",
    " 2. Services supplémentaires basés sur la lecture et l'écriture de cartes mémoire externes : Après avoir activé l'autorisation de stockage, vous pouvez utiliser cette fonction pour télécharger vos photos/images/vidéos pour commenter/partager ou communiquer avec le service client pour fournir une preuve. . ",
    "",
    " 3. Services supplémentaires basés sur la lecture de l'état du téléphone et des informations d'identité : nous collecterons votre IMEI, l'adresse MAC de votre appareil, le numéro de série de votre appareil, votre identifiant Android et la liste des applications installées après avoir activé les autorisations, et confirmerons votre compte en fonction de la sécurité de votre appareil. parvenir à maîtriser les risques de sécurité ",
    "",
    " 4. Fonctions supplémentaires basées sur la visualisation des connexions WLAN : visualisation de l'état de la connexion wifi pour garantir la fiabilité du réseau. ",
    "",
    "",
    " 5. Services supplémentaires basés sur les fonctions d'accès et de téléchargement d'images d'album photo (bibliothèque d'images), de stockage externe, de mise en cache et de publication d'images : vous pouvez utiliser cette fonction pour télécharger vos photos/images, publier des commentaires/partages et prendre des photos pour faire du shopping après activer les autorisations de l'album. Ou communiquer avec le service client pour nous fournir des fonctions de preuve. Les biens ou services que vous devez acheter seront identifiés grâce aux photos/images que vous téléchargez, ou les informations d'évaluation contenant les photos ou images que vous téléchargez seront utilisées. Si vous refusez d'autoriser la fourniture, vous ne pourrez pas les utiliser. fonction, mais cela n'affectera pas votre utilisation normale des autres fonctions de [########]. ",
    "",
    " 6. Services supplémentaires basés sur les applications installées : La plateforme mettra régulièrement à jour le client de l'application. Lors de la mise à jour, vous pouvez ouvrir l'APP pour mettre à jour, installer et utiliser directement la nouvelle version. ",
    "",
    " 7. Services supplémentaires basés sur l'obtention par l'application d'informations sur les tâches en cours d'exécution : la plate-forme jugera la page APP lors du comptage des données APP. ",
    "",
    " 8. Services supplémentaires basés sur le maintien de l'application éveillée : lorsque vous utilisez cette application, les données seront automatiquement synchronisées en arrière-plan. ",
    "",
    " 9. Afin de fournir de manière plus complète les informations sur le produit ou le service dont vous avez besoin et de vous fournir des services personnalisés ou non personnalisés, nous extrairons vos informations en fonction de vos enregistrements de navigation et de recherche, des informations sur l'appareil, des informations de localisation et des informations de commande. Navigation, préférences de recherche, habitudes comportementales et caractéristiques des informations de localisation .",
    "",
    " Vous comprenez et acceptez que les services supplémentaires ci-dessus nécessitent que vous activiez l'accès à vos informations de localisation (emplacement géographique), à votre appareil photo (appareil photo), à votre album photo (bibliothèque d'images), à votre calendrier et au stockage externe de votre appareil pour répondre aux exigences de ces autorisations. Concernant la collecte et l’utilisation des informations, veuillez noter que lorsque vous activez une autorisation, vous nous autorisez à les collecter et à les utiliser. Utilisez les informations personnelles pertinentes pour vous fournir les services correspondants. Une fois que vous avez fermé une autorisation, cela signifie que vous avez annulé l'autorisation. Nous ne continuerons plus à collecter et à utiliser les informations personnelles pertinentes sur la base des autorisations correspondantes, et nous ne pourrons plus le faire. vous fournir les services correspondant à cette autorisation. Cependant, votre décision de désactiver l'autorisation n'affectera pas la collecte et l'utilisation antérieures des informations basées sur votre autorisation. ",
    "",
    " (7) Autres ",
    "",
    " 1. Si les informations que vous fournissez contiennent les informations personnelles d'autres utilisateurs, vous devez vous assurer que vous avez obtenu une autorisation légale avant de fournir ces informations personnelles à [########]. S'il s'agit d'informations personnelles sur des enfants, vous devez obtenir le consentement du tuteur de l'enfant correspondant avant de publier. Dans les circonstances ci-dessus, le tuteur a le droit de nous contacter via les canaux indiqués à l'article 10 de cette politique pour demander la correction ou la suppression du contenu impliquant des informations personnelles sur les enfants . ",
    "",
    " 2. Si nous utilisons les informations à d'autres fins non spécifiées dans cette politique, ou si nous utilisons les informations collectées dans un but spécifique à d'autres fins, ou si nous obtenons activement vos informations personnelles auprès d'un tiers, nous les obtiendrons à l'avance. Votre consentement .",
    "",
    " Si nous obtenons vos informations indirectement auprès d'un tiers, nous demanderons clairement par écrit au tiers avant la collecte de collecter des informations personnelles après avoir obtenu votre consentement conformément à la loi, et vous informerons du contenu des informations partagées, et si cela implique des informations sensibles. Votre confirmation explicite est requise avant que les informations nous soient fournies pour utilisation, et les tiers sont tenus de confirmer la légalité et la conformité de la source des informations personnelles. Nous promettons que si un tiers commet une violation, nous exigerons clairement que l'autre partie assume les responsabilités légales correspondantes, et nous renforcerons en même temps la sécurité des informations personnelles (y compris la déclaration d'informations sensibles, le stockage crypté des informations sensibles et ; contrôle d’accès). Nous protégerons les informations personnelles obtenues indirectement avec au moins les mêmes méthodes et mesures de protection que celles que nous utilisons pour les informations personnelles de nos propres utilisateurs. ",
    "",
    " 3. Exceptions avec consentement autorisé ",
    "",
    " Vous comprenez et acceptez parfaitement que nous n'avons pas besoin de votre autorisation et de votre consentement pour collecter et utiliser vos informations personnelles dans les circonstances suivantes, et nous ne répondrons pas à vos demandes de correction/modification, de suppression, d'annulation, de retrait de consentement ou de demande. pour information: ",
    "",
    " (1) Pertinent pour la sécurité nationale et la sécurité de la défense nationale ; ",
    "",
    " (2) Pertinent pour la sécurité publique, la santé publique et les principaux intérêts publics ; ",
    "",
    " (3) L'application des lois judiciaires ou administratives liées aux enquêtes pénales, aux poursuites, aux procès et à l'exécution des jugements ; ",
    "",
    " (4) Pour protéger vos principaux droits et intérêts légitimes ou ceux d'autres personnes liés à la vie et à la propriété, mais il est difficile d'obtenir votre consentement ; ",
    "",
    " (5) Les informations personnelles que vous divulguez vous-même au public ; ",
    "",
    " (6) Les informations personnelles sont collectées à partir d'informations légalement divulguées publiquement, telles que des rapports d'actualité juridique et des canaux de divulgation d'informations gouvernementales. ",
    "",
    " (7) Si nécessaire pour signer et exécuter des accords pertinents ou d'autres documents écrits avec vous ; ",
    "",
    " (8) Nécessaire pour maintenir le fonctionnement sûr et stable des produits et/ou services fournis, ainsi que pour découvrir et gérer les pannes de produits et/ou de services ; ",
    "",
    " (9) Nécessaire pour les reportages légitimes ; ",
    "",
    " (10) Lorsqu'il est nécessaire pour un établissement de recherche universitaire de mener des recherches statistiques ou universitaires fondées sur l'intérêt public, et lorsqu'il fournit les résultats d'une recherche universitaire ou une description au monde extérieur, les informations personnelles contenues dans les résultats seront divulguées. identifié ",
    "",
    " (11) Autres situations prévues par les lois et règlements. ",
    "",
    " Veuillez être informé que, conformément aux lois applicables, si nous prenons des mesures techniques et d'autres mesures nécessaires pour traiter les informations personnelles afin que le destinataire des données ne puisse pas réidentifier une personne spécifique et ne puisse pas la récupérer, ou que nous procéderons à la désidentification de les informations collectées, l'analyse statistique et la prévision pour améliorer. Le contenu et la présentation de [########], pour fournir un support produit ou service pour les décisions commerciales et pour améliorer nos produits et services (y compris l'utilisation de données anonymes pour l'apprentissage automatique ou la formation d'algorithmes de modèles), puis ce traitement Les données sont utilisées sans autre notification et sans votre consentement. ",
    "",
    " 4. Si nous cessons d'exploiter les produits ou services [########], nous cesserons rapidement de collecter vos informations personnelles, vous informerons de la cessation des opérations sous la forme d'une livraison ou d'une annonce individuelle, et Si les informations personnelles que nous détenons liées à l'entreprise fermée concernent des informations personnelles sur des enfants, nous informerons rapidement les tuteurs des enfants de la suspension des opérations ",
    "",
    " 2. Comment nous utilisons les cookies ",
    " Afin de vous offrir une expérience d'accès plus facile et de vous recommander du contenu qui vous intéresse, nous stockerons un petit fichier de données appelé Cookie sur votre appareil mobile. Les cookies contiennent généralement des identifiants, des noms de sites ainsi que certains chiffres et caractères. À l'aide de cookies, nous pouvons stocker les informations de votre compte, les enregistrements de produits, les enregistrements de commandes et les données de produits ",
    "",
    " Si vous n'êtes pas d'accord avec le stockage de petits fichiers de données cookies sur votre appareil mobile, vous pouvez cesser d'utiliser [########]. ",
    "",
    " 3. Comment nous partageons, transférons et divulguons publiquement vos informations ",
    " (1) Partage ",
    "",
    " Nous ne partagerons pas vos informations personnelles avec des sociétés, des organisations et des individus autres que les prestataires de services [########], sauf dans les circonstances suivantes : ",
    "",
    " 1. Partage dans des circonstances juridiques : Nous partagerons vos informations personnelles en externe conformément aux lois et réglementations, aux litiges, aux besoins de résolution des litiges, ou conformément aux exigences des autorités administratives et judiciaires conformément à la loi. ",
    "",
    " 2. Partage avec consentement explicite : Après avoir obtenu votre consentement explicite, nous partagerons vos informations personnelles avec d'autres parties. ",
    "",
    " 3. Partage lorsque vous choisissez activement : lorsque vous achetez des biens ou des services via la plateforme [########], nous partagerons les informations nécessaires liées à la transaction dans les informations de votre commande avec les fournisseurs de biens ou de services connexes. pour répondre à vos besoins en matière de transaction et de service après-vente ",
    "",
    " 4. Partage avec des sociétés affiliées : afin de nous permettre de vous fournir des produits et services basés sur le compte de la plateforme [########], de recommander les informations qui vous intéressent, d'identifier les anomalies dans les comptes d'utilisateurs enregistrés et protéger [#### ####] La sécurité personnelle et matérielle des sociétés affiliées ou des autres utilisateurs ou du public est protégée contre toute violation de votre droit personnel. Les informations seront partagées avec nos sociétés affiliées et/ou leurs fournisseurs de services désignés. Nous ne partagerons les informations personnelles nécessaires et sous réserve des objectifs énoncés dans la présente politique de confidentialité que si nous partageons vos informations personnelles sensibles ou avec nos sociétés affiliées. et le traitement des informations personnelles, votre autorisation et votre consentement seront à nouveau demandés. ",
    "",
    " 5. Partage avec des partenaires autorisés : Nous confions à des partenaires autorisés le soin de vous fournir certains services ou d'exécuter des fonctions en notre nom. Nous ne partagerons vos informations qu'aux fins légales, légitimes, nécessaires, spécifiques et claires indiquées dans cette politique de confidentialité. Autorisé les partenaires n'auront accès qu'aux informations nécessaires à l'exercice de leurs fonctions et ne pourront utiliser ces informations à d'autres fins. Nous n'autorisons pas les partenaires à sous-déléguer ",
    "",
    " Actuellement, nos partenaires agréés comprennent les types suivants : ",
    "",
    " (1) Commerçants, prestataires de services et autres partenaires. Nous envoyons des informations aux commerçants, prestataires de services et autres partenaires qui soutiennent notre activité. Ces supports comprennent les services d'infrastructure technique que nous avons confiés à fournir, l'analyse de nos services, les méthodes d'utilisation, le service client. , commodité de paiement ou mener des recherches universitaires et des enquêtes. Ces informations sont difficiles à associer à vos informations d'identité. Ces informations nous aideront à analyser l'efficacité des services associés ",
    "",
    " Afin d'assurer le fonctionnement stable et la réalisation des fonctions de notre client, afin que vous puissiez utiliser et profiter de plus de services et de fonctions, notre application intégrera le SDK des partenaires autorisés. Nous fournirons des interfaces de programmation d'application aux partenaires autorisés pour obtenir des informations pertinentes. . (API) et le kit de développement d'outils logiciels (SDK) sont soumis à des tests de sécurité stricts et conviennent de mesures strictes de protection des données avec des partenaires autorisés pour traiter les informations personnelles conformément à cette politique et à toute autre mesure de confidentialité et de sécurité pertinente .",
    "",
    " Les détails sont les suivants : ",
    "",
    " ",
    " Nom du SDK : KTPsdk ",
    "",
    " Fabricant : Shenzhen Jintaiyi Electronics Co., Ltd. ",
    "",
    " Type d'informations de collecte : obtenez la liste Bluetooth du téléphone mobile et scannez les appareils Bluetooth autour du téléphone mobile ",
    "",
    " Objectif d'utilisation : utilisé pour lire la liste Bluetooth sur un appareil spécifique, se connecter à Bluetooth et envoyer des informations via Bluetooth. ",
    " (Équipement spécifique : terminal de point de vente sans fil produit par Shenzhen Jintaiyi Electronics Co., Ltd.) ",
    "",
    " Autorisations du système sensible utilisées : autorisation de numérisation Bluetooth, autorisation de connexion Bluetooth, autorisation de positionnement. ",
    "",
    " Scénarios d'utilisation spécifiques : connexion à Bluetooth, autorisation de connexion Bluetooth. Scanner Bluetooth à proximité, autorisation de numérisation Bluetooth, autorisation de positionnement. ",
    "",
    "",
    "",
    " Nom du SDK : flutter_bluetooth_serial ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations de collecte : obtenez la liste Bluetooth du téléphone mobile et scannez les appareils Bluetooth autour du téléphone mobile ",
    "",
    " Objectif d'utilisation : lire la liste Bluetooth des appareils, se connecter à Bluetooth et envoyer des informations via Bluetooth. ",
    "",
    " Autorisations du système sensible utilisées : autorisation de numérisation Bluetooth, autorisation de connexion Bluetooth, autorisation de positionnement. ",
    "",
    " Scénarios d'utilisation spécifiques : connexion à Bluetooth, autorisation de connexion Bluetooth. Scanner Bluetooth à proximité, autorisation de numérisation Bluetooth, autorisation de positionnement. ",
    "",
    "",
    " Nom du SDK : image_picker ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations collectées : Obtenir les autorisations de stockage en lecture et en écriture sur le téléphone mobile, obtenir les autorisations d'utilisation de la caméra ",
    "",
    " Objectif : Pour sélectionner des images dans la bibliothèque d'images et prendre de nouvelles photos avec l'appareil photo. ",
    "",
    " Autorisations système sensibles utilisées : autorisations de lecture et d'écriture sur le stockage externe, autorisations de la caméra ",
    "",
    " Scénarios d'utilisation spécifiques : paramètres, modification des fonctions photo du produit à l'aide des autorisations de l'appareil photo, autorisations de stockage. ",
    "",
    "",
    "",
    " Nom du SDK : vibration ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations de collecte : obtenir l'autorisation de vibration du téléphone portable ",
    "",
    " But d'utilisation : Vibrez le téléphone après avoir scanné avec succès le code QR. ",
    "",
    " Autorisations système sensibles utilisées : autorisation de vibration du téléphone ",
    "",
    " Scénario d'utilisation spécifique : après avoir scanné avec succès le code QR, le téléphone vibre et les autorisations vibrent. ",
    "",
    "",
    "",
    " Nom du SDK : qr_code_scanner ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations de collecte : obtenir l'autorisation d'utilisation de la caméra ",
    "",
    " But d'utilisation : Scanner les codes QR et les codes-barres. ",
    "",
    " Utilisation des autorisations système sensibles : autorisations de la caméra ",
    "",
    " Scénarios d'utilisation spécifiques : scanner les codes-barres des produits, les droits d'utilisation de l'appareil photo, scanner les codes QR des documents, les droits d'utilisation de l'appareil photo. ",
    "",
    "",
    "",
    " Nom du SDK : path_provider ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations collectées : Obtenir les autorisations de stockage en lecture et en écriture sur le téléphone mobile ",
    "",
    " Objectif d'utilisation : Utilisé pour rechercher des emplacements communs sur le système de fichiers. ",
    "",
    " Autorisations système sensibles utilisées : autorisations de lecture et d'écriture de stockage externe ",
    "",
    " Scénarios d'utilisation spécifiques : obtenez l'adresse de stockage externe de l'APP, lisez et écrivez les autorisations de stockage externe, créez un dossier à l'adresse de stockage externe, lisez et écrivez les autorisations de stockage externe. ",
    "",
    "",
    " Nom du SDK : open_filex ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations collectées : Obtenir les autorisations de stockage en lecture et en écriture sur le téléphone mobile ",
    "",
    " But d'utilisation : utilisé pour ouvrir le fichier spécifié dans le système de fichiers. ",
    "",
    " Autorisations système sensibles utilisées : autorisations de lecture et d'écriture de stockage externe ",
    "",
    " Scénarios d'utilisation spécifiques : après avoir téléchargé le package d'installation de la mise à jour logicielle sur un stockage externe, ouvrez le fichier pour terminer la mise à jour, avec des autorisations de lecture et d'écriture sur le stockage externe. ",
    "",
    "",
    "",
    " Nom du SDK : flutter_downloader ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations collectées : Obtenir l'autorisation de stockage en lecture et en écriture sur le téléphone mobile, obtenir l'autorisation de notification sur le téléphone mobile ",
    "",
    " But d'utilisation : Plugin de création et de gestion des tâches de téléchargement. ",
    "",
    " Autorisations système sensibles utilisées : autorisations de lecture et d'écriture de stockage externe, obtention des autorisations de notification sur téléphone mobile ",
    "",
    " Scénarios d'utilisation spécifiques : téléchargement de logiciels et mise à jour du package d'installation, stockage externe, autorisations de lecture et d'écriture sur le stockage externe, barre de notification affichant les informations et la progression du téléchargement, autorisations de notification. ",
    "",
    "",
    "",
    " Nom du SDK : permission_handler ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations collectées : Aucune exigence d'autorisation spécifique ",
    "",
    " Finalité d'utilisation : Plugin utilisé pour gérer et demander les autorisations des applications. ",
    "",
    " Autorisations système sensibles utilisées : liées à l'autorisation demandée (par exemple caméra, emplacement, contacts) ",
    "",
    " Scénarios d'utilisation spécifiques : demander l'autorisation de l'utilisateur pour accéder aux fonctions de l'appareil (telles que la caméra, la localisation), vérifier et gérer l'état des autorisations. ",
    "",
    "",
    " Nom du SDK : share_plus ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations collectées : Aucune exigence d'autorisation spécifique ",
    "",
    " Finalité d'utilisation : Plugin de partage de contenu au sein de l'application. ",
    "",
    " Autorisations système sensibles utilisées : Aucune autorisation sensible spécifique ",
    "",
    " Scénarios d'utilisation spécifiques : les utilisateurs peuvent partager du texte, des images, des fichiers et d'autres contenus avec d'autres applications ou plates-formes via des plug-ins, tels que les réseaux sociaux, les applications de messagerie ou les clients de messagerie. ",
    "",
    "",
    "",
    " Nom du SDK : caméra ",
    "",
    " Fabricant : Plug-in open source tiers ",
    "",
    " Type d'informations collectées : Aucune exigence d'autorisation spécifique ",
    "",
    " Objectif d'utilisation : Plug-in permettant d'accéder et de contrôler la caméra de l'appareil au sein d'une application. ",
    "",
    " Autorisation du système sensible utilisée : autorisation de la caméra (pour accéder à la caméra de l'appareil) ",
    "",
    " Scénarios d'utilisation spécifiques : prise de photos, enregistrement de vidéos, prévisualisation du contenu de l'appareil photo, etc., généralement utilisés dans les applications nécessitant des fonctions d'appareil photo. ",
    "",
    "",
    "",
    " ",
    " Définition et liste exhaustive des champs d'informations personnelles ",
    "",
    " Adresse MAC de l'appareil : L'adresse mac de l'appareil, l'adresse qui confirme la localisation de l'appareil mobile ",
    "",
    " Code d'identification unique de l'appareil : IDFA pour les appareils iOS, IMEI, oaid, AndroidId, UUID pour les appareils Android (plusieurs codes d'identification pour Android et supérieurs seront collectés) ",
    "",
    " Adresse IP de l'appareil : Mobile Internet Link Protocol, confirme le service de l'opérateur de connexion réseau ",
    "",
    " Informations sur l'appareil : marque de l'appareil, modèle de l'appareil, système d'exploitation, version du système d'exploitation ",
    "",
    " Informations réseau : environnement réseau actuel (tel que WIFI, 5G, 4G, 3G, 2G) ",
    "",
    "",
    " (2) Transfert ",
    "",
    " Nous ne transférerons vos informations personnelles à aucune entreprise, organisation ou individu, sauf dans les circonstances suivantes : ",
    "",
    " 1. Transfert avec consentement explicite : Après avoir obtenu votre consentement explicite, nous transférerons vos informations personnelles à des tiers ; ",
    "",
    " 2. Lorsque le fournisseur de services [########] subit une fusion, une acquisition ou une liquidation de faillite, ou d'autres situations impliquant des fusions, des acquisitions ou une liquidation de faillite, si le transfert de renseignements personnels est impliqué, nous exigerons de nouveaux Les entreprises et les organisations qui détiennent vos informations personnelles continueront d'être liées par cette politique, sinon nous exigerons que l'entreprise, l'organisation et l'individu demandent à nouveau votre autorisation ",
    "",
    " (3) Divulgation publique ",
    "",
    " Nous ne divulguerons publiquement vos informations personnelles que dans les circonstances suivantes : ",
    "",
    " 1. Nous divulguerons publiquement vos informations personnelles avec votre consentement explicite ou sur la base de votre choix actif ; ",
    "",
    " 2. Si nous déterminons que vous avez violé les lois et réglementations ou gravement violé les accords et règles pertinents de la plateforme [########], ou pour protéger les utilisateurs de la plateforme [########] ou du public Afin de protéger la sécurité des personnes et des biens contre toute violation, nous divulguons vos informations personnelles conformément aux lois et réglementations ou avec votre consentement, y compris les violations pertinentes et les mesures que la plateforme [########] a prises contre toi. ",
    "",
    " (4) Exceptions à l'obtention d'une autorisation et d'un consentement préalables lors du partage, du transfert et de la divulgation publique d'informations personnelles ",
    "",
    " Dans les circonstances suivantes, le partage, le transfert et la divulgation publique de vos informations personnelles ne nécessitent pas votre autorisation et votre consentement préalables : ",
    "",
    " 1. Relatifs à la sécurité nationale et à la sécurité de la défense nationale ; ",
    "",
    " 2. Liés à la sécurité publique, à la santé publique et aux intérêts publics majeurs ; ",
    "",
    " 3. Pertinent pour les enquêtes criminelles, les poursuites judiciaires, l'exécution des procès et des jugements, l'application des lois judiciaires ou administratives ; ",
    "",
    " 4. Pour protéger vos principaux droits et intérêts légitimes ou ceux d'autres personnes liés à la vie et à la propriété, mais il est difficile d'obtenir mon consentement ; ",
    "",
    " 5. Les informations personnelles que vous divulguez vous-même au public ; ",
    "",
    " 6. Recueillir des informations personnelles à partir d'informations légalement divulguées publiquement, telles que des reportages juridiques et la divulgation d'informations gouvernementales. ",
    "",
    " Veuillez être informé que, conformément aux lois applicables, si nous prenons des mesures techniques et autres mesures nécessaires pour traiter les informations personnelles afin que le destinataire des données ne puisse pas réidentifier une personne spécifique et ne puisse pas les récupérer, le partage, le transfert et la divulgation publique de ces données traitées Aucun autre avis ne vous est adressé et votre consentement n'est requis ",
    "",
    " 4. Comment nous stockons vos informations ",
    " (1) Lieu de stockage ",
    "",
    " Les informations personnelles que nous collectons et générons au cours de nos opérations seront stockées dans la région d'hébergement du serveur. Dans les circonstances suivantes, nous fournirons vos informations personnelles aux entités concernées après avoir rempli nos obligations en vertu de la loi : ",
    "",
    " 1. Les lois applicables sont clairement stipulées ; ",
    "",
    " 2. Obtenir votre autorisation explicite ; ",
    "",
    " 3. Votre initiative personnelle dans la réalisation de transactions transfrontalières via Internet. ",
    "",
    " En réponse aux circonstances ci-dessus, nous utiliserons un contrat pour garantir que vos informations personnelles sont protégées à un niveau non inférieur à celui spécifié dans cette politique. ",
    "",
    " (2) Période de stockage ",
    "",
    " Nous prendrons des mesures raisonnables et réalisables pour faire de notre mieux pour éviter de collecter et de traiter des informations personnelles non pertinentes. Nous ne conserverons vos informations personnelles que pendant la période nécessaire pour atteindre les objectifs énoncés dans cette politique, à moins que la loi n'impose des exigences de conservation obligatoires, de transaction. information La période de stockage ne doit pas être inférieure à trois ans à compter de la date de réalisation de la transaction. Nous nous référons principalement aux normes suivantes pour déterminer la période de stockage, selon la période la plus longue : ",
    "",
    " 1. Compléter les objectifs de transaction vous concernant, conserver les dossiers de transaction et commerciaux correspondants et répondre à vos demandes de renseignements ou à vos plaintes ; ",
    "",
    " 2. Assurer la sécurité et la qualité des services que nous vous fournissons ; ",
    "",
    " 3. Acceptez-vous une période de conservation plus longue ; ",
    "",
    " 4. Existe-t-il un autre accord particulier sur la durée de conservation ? ",
    "",
    " Une fois la période de conservation dépassée, nous supprimerons ou anonymiserons vos informations personnelles dans les 15 jours conformément aux exigences des lois applicables. De plus, après avoir annulé votre compte, nous cesserons de vous fournir des produits et services dans les 15 jours, et selon votre demande, vos informations personnelles seront supprimées ou anonymisées, sauf disposition contraire des lois et règlements ",
    "",
    " 5. Comment nous protégeons vos informations ",
    " (1) Nous avons adopté des mesures de sécurité raisonnables et réalisables qui sont conformes aux normes de l'industrie pour protéger vos informations et empêcher tout accès non autorisé, divulgation publique, utilisation, modification, dommage ou perte entre votre navigateur et votre serveur lors de l'échange de données. ######】Le site Web offre une navigation sécurisée à l'aide du protocole HTTPS ; nous utiliserons la technologie de cryptage pour améliorer la sécurité des informations personnelles ; nous utiliserons des mécanismes de protection fiables pour empêcher les informations personnelles d'être attaquées de manière malveillante ; nous déploierons des mécanismes de contrôle d'accès ; . Tous les efforts sont déployés pour garantir que seul le personnel autorisé a accès aux informations personnelles. ",
    "",
    "",
    "",
    " (2) Nous prendrons des mesures raisonnables et réalisables pour faire de notre mieux pour éviter de collecter des informations personnelles non pertinentes. Nous ne conserverons vos informations personnelles que pendant la période nécessaire pour atteindre les objectifs énoncés dans cette politique, à moins qu'il n'y ait des exigences de conservation obligatoires par la loi. . La période de conservation des informations ne sera pas inférieure à trois ans à compter de la date de réalisation de la transaction, et nos critères pour juger de la période susmentionnée comprennent : ",
    "",
    " 1. Compléter les objectifs de transaction vous concernant, conserver les dossiers de transaction et commerciaux correspondants et répondre à vos demandes ou plaintes ; ",
    "",
    " 2. Assurer la sécurité et la qualité des services que nous vous fournissons ; ",
    "",
    " 3. Acceptez-vous une période de conservation plus longue ; ",
    "",
    " 4. Existe-t-il un autre accord particulier sur la durée de conservation ? ",
    "",
    " Une fois que vos informations personnelles auront dépassé la période de conservation, nous supprimerons ou anonymiserons vos informations personnelles conformément aux exigences des lois applicables. ",
    "",
    " (3) Internet n'est pas un environnement absolument sécurisé. Lorsque vous utilisez les services de la plateforme [########], nous vous recommandons fortement de ne pas utiliser de méthodes de communication qui ne sont pas recommandées par le [##### ###] pour vous envoyer des informations. Vous pouvez établir des connexions et partager entre vous via nos services. Lorsque vous créez des communications, des transactions ou partagez via nos services, vous pouvez choisir qui peut voir le contenu de votre transaction et échanger des informations. ou partager des informations relatives au contenu avec des tiers ",
    "",
    " Lorsque vous utilisez les services [########] pour des transactions en ligne, vous devrez inévitablement divulguer vos informations personnelles, telles que vos coordonnées ou votre adresse, à la contrepartie ou à la contrepartie potentielle. Veuillez vous protéger correctement. Vos informations personnelles les informations ne seront fournies à des tiers que lorsque cela est nécessaire. Si vous constatez que vos informations personnelles, en particulier votre compte ou votre mot de passe, ont été divulguées, veuillez contacter immédiatement le service client de [########] afin que nous puissions agir en conséquence. Prenez les mesures appropriées concernant votre candidature ",
    "",
    " Veuillez noter que les informations que vous partagez volontairement ou même publiquement lors de l'utilisation de nos services impliqueront vos informations personnelles ou celles de tiers ou même des informations personnelles sensibles. Veuillez réfléchir plus attentivement si vous partagez ou même partagez publiquement des informations pertinentes lorsque vous utilisez nos services. information .​",
    "",
    " Veuillez nous aider à assurer la sécurité de votre compte en utilisant un mot de passe complexe. Nous ferons de notre mieux pour assurer la sécurité de toutes les informations que vous nous envoyez. Si nos installations de protection physiques, techniques ou administratives sont violées, entraînant un accès non autorisé aux informations , Si vos droits et intérêts légitimes sont endommagés en raison d'une divulgation publique, d'une falsification ou d'une destruction, nous assumerons la responsabilité légale correspondante ",
    "",
    " (4) Nous mettrons à jour et divulguerons de temps à autre du contenu pertinent sur les risques de sécurité et les impacts sur la sécurité des informations personnelles, que vous pouvez obtenir via l'annonce [########]. ",
    "",
    " (5) Après qu'un incident malheureux de sécurité des informations personnelles se produise, nous vous informerons conformément aux exigences des lois et réglementations : la situation de base et l'impact de l'incident de sécurité, les mesures d'élimination que nous avons prises ou prendrons, et comment vous peut prévenir et réduire les risques par vous-même. Suggestions et mesures correctives pour vous. Nous vous informerons des informations relatives à l'incident par e-mail, appel téléphonique et notification push. Lorsqu'il est difficile d'informer les personnes concernées une par une, nous vous en informerons. publiera des annonces de manière raisonnable et efficace .",
    "",
    "",
    " 6. Comment gérez-vous vos informations ",
    " Vous pouvez accéder et gérer vos informations des manières suivantes : ",
    "",
    " (1) Interroger, corriger et compléter vos informations ",
    "",
    " Vous disposez du droit d'interroger, de rectifier ou de compléter les informations vous concernant. Vous pouvez le faire vous-même des manières suivantes : ",
    "",
    ' 1. Connectez-vous au client [########], accédez à la page "Compte" et corrigez les informations personnelles et les informations relatives au compte personnel ; ',
    " 2. Autoriser les responsables concernés à apporter des modifications ",
    "",
    " (2) Supprimez vos informations ",
    "",
    "",
    " Vous pouvez nous faire une demande de suppression de vos informations personnelles dans les situations suivantes : ",
    "",
    " 1. Si notre traitement des informations personnelles viole les lois et réglementations ; ",
    "",
    " 2. Si nous collectons et utilisons vos informations personnelles sans votre consentement explicite ; ",
    "",
    " 3. Si notre traitement des informations personnelles viole gravement notre accord avec vous ; ",
    "",
    " 4. Si vous n'utilisez plus nos produits ou services, ou si vous demandez activement l'annulation de votre compte ; ",
    "",
    " 5. Si nous ne vous fournissons plus définitivement de produits ou de services. ",
    "",
    " Si nous décidons de répondre à votre demande de suppression, nous informerons également les sujets qui ont obtenu vos informations personnelles de notre part et leur demanderons de les supprimer en temps opportun (sauf disposition contraire des lois et réglementations, ou si ces sujets ont obtenu indépendamment votre autorisation) ",
    "",
    " Lorsque vous ou nous vous aidons à supprimer des informations pertinentes, nous ne sommes pas en mesure de supprimer immédiatement les informations correspondantes du système de sauvegarde en raison des lois applicables et des technologies de sécurité. Nous stockerons en toute sécurité vos informations personnelles et les isolerons de tout traitement ultérieur, jusqu'à ce que la sauvegarde peut être effacée ou anonymisée ",
    "",
    " (3) Modifier la portée de votre autorisation et consentement ",
    "",
    " Si vous choisissez de ne plus nous fournir vos informations personnelles ou si vous refusez d'utiliser certains des services que nous proposons, vous pouvez désactiver certaines des autorisations que vous avez accordées à [########] en configurant votre appareil mobile intelligent ",
    "",
    " Lorsque vous retirez votre consentement, nous ne traiterons plus les informations personnelles correspondantes. Toutefois, votre décision de retirer votre consentement n'affectera pas le traitement antérieur des informations personnelles basé sur votre autorisation. ",
    "",
    " (4) Annulez votre compte ",
    "",
    " Vous pouvez demander la suppression de votre compte via les méthodes suivantes : ",
    "",
    " 1. Vous pouvez contacter votre administrateur backend pour annuler votre compte. ",
    "",
    " Après avoir annulé votre compte, nous cesserons de vous fournir des biens ou des services et supprimerons ou anonymiserons vos informations personnelles conformément aux exigences des lois applicables. ",
    "",
    " (5) Prise de décision automatique du système d'information sur les contraintes ",
    "",
    " Dans certaines fonctions commerciales, nous prenons uniquement des décisions basées sur des mécanismes décisionnels automatiques non artificiels, notamment des systèmes d'information et des algorithmes. Si ces décisions affectent de manière significative vos droits et intérêts légitimes, vous avez le droit de nous demander une explication, et nous fournira également des méthodes de réclamation sans porter atteinte aux secrets commerciaux de [########] ou à d'autres droits d'utilisateur et intérêts publics sociaux ",
    "",
    " (6) Répondre à votre demande ci-dessus ",
    "",
    " Pour des raisons de sécurité, vous devrez fournir une demande écrite ou prouver autrement votre identité. Nous vous demanderons de vérifier votre identité avant de traiter votre demande. ",
    "",
    " Nous vous répondrons dans les 15 jours. Si vous n'êtes pas satisfait, vous pouvez également déposer une réclamation auprès du service client [########]. ",
    "",
    " En principe, nous ne facturons pas de frais pour vos demandes raisonnables, mais pour les demandes répétées dépassant les limites raisonnables, nous facturerons certains frais à notre discrétion. Pour les informations qui ne sont pas directement liées à votre identité, les informations répétées sans raison, ou besoins Nous rejetterons les demandes qui nécessitent des moyens techniques excessifs (nécessitant le développement de nouveaux systèmes ou des changements fondamentaux dans les pratiques actuelles), qui présentent des risques pour les droits et intérêts légitimes d'autrui, ou qui sont irréalistes ",
    "",
    " Dans les circonstances suivantes, conformément aux exigences légales et réglementaires, nous ne pourrons pas répondre à votre demande : ",
    "",
    " 1. Relatifs à la sécurité nationale et à la sécurité de la défense nationale ; ",
    "",
    " 2. Liés à la sécurité publique, à la santé publique et aux intérêts publics majeurs ; ",
    "",
    " 3. Pertinents pour l'enquête pénale, les poursuites, le procès et l'exécution des jugements ; ",
    "",
    " 4. Il existe des preuves suffisantes que la personne concernée a fait preuve de malveillance subjective ou a abusé de ses droits ; ",
    "",
    " 5. Répondre à votre demande causera de graves dommages à vos droits et intérêts légitimes ou à ceux d'autres personnes ou organisations ; ",
    "",
    " 6. Impliquant des secrets d'affaires. ",
    "",
    "",
    " 7. Comment vos informations sont transférées à l'échelle mondiale ",
    "",
    " 1. Les lois applicables sont clairement stipulées ; ",
    "",
    " 2. Obtenir votre autorisation explicite ; ",
    "",
    " 3. Votre initiative personnelle dans la réalisation de transactions transfrontalières via Internet. ",
    "",
    " Compte tenu des circonstances ci-dessus, nous veillerons à ce que vos informations personnelles soient protégées de manière adéquate conformément à cette politique de confidentialité. ",
    "",
    " 8. Comment mettre à jour cette politique de confidentialité ",
    " Modifications de notre politique de confidentialité. ",
    "",
    " Nous ne limiterons pas vos droits en vertu de cette politique de confidentialité sans votre consentement explicite. Nous publierons des mises à jour de la politique de confidentialité sur l'application. ",
    "",
    " Pour les changements majeurs, nous fournirons également des notifications plus visibles (y compris des notifications via des annonces publiques [########] ou même des invites contextuelles). ",
    "",
    " Les changements majeurs mentionnés dans cette politique comprennent, sans s'y limiter : ",
    "",
    " 1. Changements majeurs dans notre modèle de service. Tels que la finalité du traitement des informations personnelles, les types d'informations personnelles traitées et l'utilisation des informations personnelles ; ",
    "",
    " 2. Nous avons des changements majeurs dans le contrôle. Tels que des changements dans les contrôleurs de l'information provoqués par des fusions, des acquisitions et des réorganisations ; ",
    "",
    " 3. Les principaux objets avec lesquels les informations personnelles sont partagées, transférées ou divulguées publiquement changent ; ",
    "",
    " 4. Modifications significatives de vos droits de participation au traitement des informations personnelles et de la manière dont vous les exercez ; ",
    "",
    " 5. Nos services responsables, nos coordonnées et nos canaux de réclamation pour le traitement de la sécurité des informations personnelles ont changé ; ",
    "",
    " 6. Le rapport d'évaluation d'impact sur la sécurité des renseignements personnels indique qu'il existe un risque élevé. ",
    "",
    " 9. Comment nous contacter ",
    " Vous pouvez nous contacter via les méthodes suivantes et nous répondrons à votre demande dans un délai de 15 jours : ",
    "",
    " 1. Si vous avez des questions, des commentaires ou des suggestions sur le contenu de cette politique, vous pouvez nous contacter via l'e-mail du service client [########] : [support@########. com]. ",
    "",
    " 2. Si vous constatez que vos informations personnelles ont été divulguées, vous pouvez contacter notre service client pour déposer une plainte ; ",
    "",
    " Si vous n'êtes pas satisfait de notre réponse, notamment si vous estimez que notre comportement en matière de traitement des informations personnelles a porté atteinte à vos droits et intérêts légitimes, vous pouvez également rechercher une solution en intentant une action en justice auprès du tribunal compétent pour le domicile du défendeur . ",
  ],
  es_ES: [
    " ######## Política de privacidad ",
    " 【Tiempo de vigencia】: 2024-7-23 ",
    " 【Hora de actualización】：2024-7-23 ",
    "",
    " Consejos ",
    " Antes de utilizar los productos o servicios de [########], asegúrese de leer atentamente y comprender a fondo esta política, especialmente los términos marcados en negrita/negrita subrayada. Debe leerlos detenidamente. Asegúrese de comprenda y acepte completamente antes de comenzar a usarla. Si tiene alguna pregunta, comentario o sugerencia sobre el contenido de esta política, puede contactarnos a través de los diversos métodos de contacto proporcionados por [########] ",
    "",
    ' Su confianza es muy importante para nosotros. Somos muy conscientes de la importancia que tiene la información personal para usted. Tomaremos las medidas de protección de seguridad adecuadas de acuerdo con los requisitos legales y reglamentarios y haremos todo lo posible para proteger su información personal de una manera segura y controlable. En vista de esto, [### #####] El proveedor de servicios (o denominado "nosotros" o "[########]") formula esta política de privacidad (en adelante denominada. como "esta política/esta política de privacidad") y le recuerda: La política se aplica a [Toposfy Todos los productos y servicios proporcionados por Dashboard ',
    "",
    " Cabe señalar que esta política no se aplica a los servicios que le brindan otros terceros, ni se aplica a productos o servicios en [########] que tienen políticas de privacidad establecidas por separado. ",
    "",
    " Parte 1 Definición ",
    " 【########】Proveedor de servicios:【Chengdu Huitong Times Software Co., Ltd.】 ",
    "",
    " Información personal: se refiere a diversa información registrada electrónicamente o por otros medios que pueden identificar la identidad de una persona física específica o reflejar las actividades de una persona física específica sola o en combinación con otra información. ",
    "",
    " Información personal sensible: incluye dirección de correo electrónico e información del pedido. ",
    "",
    " Eliminación de información personal: se refiere al acto de eliminar información personal de los sistemas involucrados en la realización de funciones comerciales diarias, de modo que no se pueda recuperar ni acceder a ella. ",
    "",
    " Niños: se refiere a los menores de catorce años. ",
    "",
    " A menos que se acuerde lo contrario, las definiciones utilizadas en esta política tienen el mismo significado que las del Acuerdo de usuario de la plataforma [########]. ",
    "",
    " Parte 2 Política de privacidad ",
    " Esta sección de política de privacidad le ayudará a comprender lo siguiente: ",
    "",
    " 1. Cómo recopilamos y utilizamos su información ",
    "",
    " 2. Cómo utilizamos las cookies ",
    "",
    " 3. Cómo compartimos, transferimos y divulgamos públicamente su información ",
    "",
    " 4. Cómo almacenamos su información ",
    "",
    " 5. Cómo protegemos su información ",
    "",
    " 6. ¿Cómo gestionas tu información ",
    "",
    " 7. Cómo se transfiere su información globalmente ",
    "",
    " 8. Cómo actualizar esta política de privacidad ",
    "",
    " 9. Cómo contactarnos ",
    "",
    " 1. Cómo recopilamos y utilizamos su información ",
    " Cuando utiliza nuestros productos y/o servicios, la información personal que necesitamos/necesitamos recopilar y utilizar incluye los dos tipos siguientes: ",
    "",
    " 1. Para brindarle las funciones básicas de nuestros productos y/o servicios, recopilaremos de acuerdo con las regulaciones pertinentes. Obtendremos su información personal y la información del dispositivo (IMEI, dirección MAC del dispositivo, portapapeles, número de serie del dispositivo). , andridID), lista de instalación de aplicaciones. Si se niega a proporcionar la información correspondiente, no podrá utilizar nuestros productos y/o servicios con normalidad, pero no afectará su navegación por nuestras páginas web y páginas de clientes ",
    "",
    " 2. Para proporcionarle funciones adicionales de nuestros productos y/o servicios, puede optar por autorizarnos a recopilar y utilizar su información personal sensible. Si se niega a proporcionarla, no podrá utilizar la información pertinente. funciones adicionales normalmente o lograr lo que pretendemos. Los efectos funcionales logrados no afectarán su uso normal de las funciones básicas de nuestros productos y/o servicios .",
    "",
    " Usted comprende y acepta: ",
    "",
    " 1. Estamos comprometidos a crear una variedad de productos y servicios para satisfacer sus necesidades. Debido a que le brindamos muchos tipos de productos y servicios, y la gama específica de productos/servicios que diferentes usuarios eligen utilizar es diferente, el básico /funciones adicionales y El tipo y alcance de la información personal recopilada y utilizada variará; consulte las funciones específicas del producto/servicio ",
    "",
    " 2. Para brindarle una mejor experiencia de producto y servicio, continuamos trabajando arduamente para mejorar nuestra tecnología y lanzaremos funciones nuevas u optimizadas de vez en cuando, lo que requiere que recopilemos y usemos su información personal o cambiar su información personal. El propósito o método de uso de la información se actualizará mediante la actualización de esta política, las ventanas emergentes y las páginas. El método rápido le explicará por separado el propósito, alcance y uso de la información correspondiente, y solicitará su consentimiento expreso. Durante este proceso, si tiene alguna pregunta, comentario o sugerencia, puede contactarnos a través de [#####. #. ##】Contáctanos utilizando los distintos métodos de contacto proporcionados y te responderemos lo antes posible. ",
    "",
    " Recopilaremos y utilizaremos su información personal para implementar las funciones que se describen a continuación en esta política: ",
    "",
    " (1) Ayudarle a convertirse en nuestro usuario registrado ",
    "",
    " 1. Servicio básico de usuario registrado ",
    "",
    " Para registrarse como usuario registrado y utilizar nuestros servicios de usuario registrado, debe proporcionar su número de teléfono móvil, dirección de correo electrónico y nombre de usuario y contraseña registrados previstos para crear una cuenta de plataforma [########]. Si solo necesita navegar, buscar el servicio, no necesita registrarse como nuestro usuario registrado y proporcionar la información anterior ",
    "",
    " Cuando inicie sesión en su cuenta, verificaremos su identidad de usuario registrado según la información anterior que proporcionó para garantizar que le brindamos servicios. Si hay escenarios en los que es necesario determinar su identidad de usuario registrado de acuerdo con la ley (incluyendo aplicación de la ley administrativa o judicial cuando las partes relevantes sean identificadas en un litigio), usted nos autoriza a obtener la información de autenticación relevante de su cuenta de pago correspondiente para los fines anteriores ",
    "",
    " Le proporcionaremos los derechos e intereses básicos correspondientes al usuario registrado correspondiente en función del uso de su cuenta de usuario registrado. ",
    "",
    " 2. Servicios adicionales para usuarios registrados ",
    "",
    " Si elige proporcionar su nombre real, sexo, fecha de nacimiento e información personal de residencia, podemos brindarle servicios de usuario registrado más personalizados. Para garantizar la identificación de la transacción, el apodo y el avatar de su cuenta se mostrarán públicamente. ",
    "",
    " Inicio de sesión autorizado: obtendremos la información de su cuenta de terceros de un tercero según su autorización y la vincularemos a su cuenta de la plataforma [########] para que pueda acceder a ella directamente a través de su cuenta de terceros. Iniciar sesión y utilizar nuestros productos y/o servicios. Utilizaremos su información relevante dentro del alcance de su autorización .",
    "",
    " 3. Visualización de información de la cuenta: si ya tiene una cuenta de la plataforma [########], mostraremos su información personal anterior en el servicio de la plataforma [########], así como su [########] servicio de plataforma ########】Operaciones realizadas en la plataforma o en productos y servicios asociados a la cuenta de plataforma 【########】 (puedes hacerlo). usar 【########】 Las cuentas de la plataforma utilizan los productos o servicios que brindamos en el portal [########] u otros portales de productos/servicios que brindamos), incluida la visualización centralizada de su información a través del [########] cuenta de plataforma Información personal, órdenes comerciales Respetaremos sus elecciones sobre los servicios de la plataforma [########] y la configuración de la cuenta de la plataforma [########]. ",
    "",
    " (2) Proporcionarle información sobre el producto o servicio ",
    "",
    " Cuando utiliza nuestros servicios, para identificar estados anormales de la cuenta, comprender la idoneidad del producto y brindarle visualización de páginas y resultados de búsqueda que se adapten mejor a sus necesidades, recopilaremos automáticamente su uso y lo almacenaremos como información de registro web. Incluimos: ",
    "",
    " Información del dispositivo: recibiremos y registraremos la información relacionada con el dispositivo que utilice (incluido el modelo del dispositivo, la versión del sistema operativo, la configuración del dispositivo, el identificador único del dispositivo, el software del entorno del dispositivo, etc.) en función de sus operaciones específicas durante la instalación del software y/o uso de información de funciones de hardware), información relacionada con la ubicación del dispositivo (incluida su ubicación GPS autorizada) ",
    "",
    " Información de registro de servicio: cuando utiliza los productos o servicios proporcionados por nuestro sitio web o cliente, recopilaremos automáticamente su uso detallado de nuestros servicios y los guardaremos como registros de servicio, incluida la navegación, los clics para ver, las consultas de búsqueda, las colecciones, Agregar a carrito de compras, transacción, posventa, seguir compartiendo información, publicar información, así como dirección IP, tipo de navegador, operador de telecomunicaciones, idioma utilizado, fecha y hora de acceso ",
    "",
    " Tenga en cuenta que la información separada del dispositivo y la información del registro de servicio son información que no puede identificar a una persona física específica. Si combinamos dicha información no personal con otra información para identificar a una persona física específica, o la usamos en combinación con información personal, entonces Durante Durante el período de uso combinado, este tipo de información no personal será tratada como información personal, a menos que su autorización o las leyes y regulaciones dispongan lo contrario, anonimizaremos y desidentificaremos este tipo de información .",
    "",
    "",
    " Además, también utilizaremos su información anterior para mejorar y optimizar continuamente las funciones anteriores. ",
    "",
    "",
    " Para que podamos conocer y confirmar el progreso y el estado de su pago de manera oportuna, y para brindarle servicios posventa y de resolución de disputas, usted acepta que podemos recopilar y pagar información sobre el progreso del socio de transacciones elegido, la compañía de pagos. u otra institución financiera de su elección. Información relacionada .",
    "",
    " (3) Ayudar a completar la entrega de bienes o servicios a usted ",
    "",
    " Para garantizar que los bienes y/o servicios que compró puedan entregarse sin problemas, de forma segura y precisa, divulgaremos la entrega relacionada con el pedido a la empresa de logística y distribución que proporciona sistemas de información logística y servicios técnicos para el [### #####] La información de la plataforma, y la información de entrega relevante se sincronizarán con las entidades de logística y entrega correspondientes de acuerdo con la designación de los proveedores de bienes y/o servicios. Usted sabe y acepta que las entidades de logística y entrega correspondientes lo harán inevitablemente. conocer y utilizar su información de entrega para fines de entrega .",
    "",
    "",
    " (4) Servicio al cliente y manejo de disputas ",
    "",
    " Cuando se comunica con nosotros o envía una solicitud de venta o resolución de disputas, para proteger la seguridad de su cuenta y sistema, necesitamos que proporcione la información personal necesaria para verificar su identidad de usuario registrado. ",
    "",
    " Para facilitar el contacto con usted, ayudarle a resolver el problema lo antes posible o registrar las soluciones y resultados de problemas relacionados, guardaremos los registros de comunicación/llamadas y el contenido relacionado entre usted y nosotros (incluida la información de la cuenta, el pedido información, y la información que proporciona para probar hechos relevantes, otra información, o la información de contacto que dejó), si realiza consultas, quejas o proporciona sugerencias para pedidos específicos, utilizaremos la información de su cuenta y la información del pedido .",
    "",
    " (5) Para brindarle seguridad ",
    "",
    " Para mejorar la seguridad de su uso de los servicios proporcionados por nosotros y nuestras empresas y socios afiliados, proteger su seguridad personal y de propiedad o la de otros usuarios o del público contra infracciones, y prevenir mejor los sitios web de phishing, el fraude y las vulnerabilidades de la red. , virus informáticos y ataques de red e intrusiones en la red, riesgos de seguridad e identificar con mayor precisión violaciones de leyes y regulaciones o [########] regulaciones de protocolo relacionadas En este caso, utilizamos o integramos su información de usuario registrado, información de transacciones, información del dispositivo, información de registro de servicios e información que nuestras empresas afiliadas y socios obtuvieron su autorización o compartieron de acuerdo con la ley para juzgar de manera integral los riesgos de su cuenta y transacción. y realizar Verificar la identidad, detectar y prevenir incidentes de seguridad, y tomar las medidas necesarias de registro, auditoría, análisis y eliminación de acuerdo con la ley. ",
    "",
    " (6) Proporcionarle otros servicios adicionales ",
    "",
    " Para brindarle productos y/o servicios más convenientes, mejores y personalizados, y esforzarnos por mejorar su experiencia, recopilaremos, almacenaremos en caché y utilizaremos su información personal en los siguientes servicios adicionales que se le brindarán. Si no lo hace Esta información no afectará su uso de los servicios básicos de navegación y búsqueda de [########], pero no podrá obtener la experiencia de usuario que le brindan estos servicios adicionales. Estos servicios adicionales incluyen: ",
    "",
    " 1. Servicios adicionales basados en cámara/cámara: Puede usar esta función después de activar el permiso de la cámara (cámara). Incluso si ha aceptado activar el permiso de la cámara (cámara), solo la usaremos cuando haga clic activamente en el ícono correspondiente o grabe un video Obtenga información de la foto a través de la cámara .",
    "",
    " 2. Servicios adicionales basados en lectura y escritura en tarjetas de memoria externas: después de activar el permiso de almacenamiento, puede usar esta función para cargar sus fotos/imágenes/videos para comentar/compartir o comunicarse con el servicio de atención al cliente para proporcionar pruebas. ",
    "",
    " 3. Servicios adicionales basados en la lectura del estado del teléfono y la información de identidad: recopilaremos su IMEI, dirección MAC del dispositivo, número de serie del dispositivo, ID de Android y lista de aplicaciones instaladas después de que habilite los permisos y confirmemos su cuenta según la seguridad de su dispositivo. lograr el control de riesgos de seguridad ,",
    "",
    " 4. Funciones adicionales basadas en la visualización de conexiones WLAN: visualización del estado de la conexión wifi para garantizar la confiabilidad de la red. ",
    "",
    "",
    " 5. Servicios adicionales basados en el acceso y carga de imágenes del álbum de fotos (biblioteca de imágenes), almacenamiento externo, almacenamiento en caché y funciones de publicación de imágenes: puede usar esta función para cargar sus fotos/imágenes, publicar comentarios/compartir y tomar fotos para comprar después. activar los permisos del álbum. O comunicarse con el servicio de atención al cliente para proporcionarnos funciones de prueba. Los bienes o servicios que necesita comprar se identificarán a través de las fotos/imágenes que cargue, o se utilizará la información de revisión que contiene las fotos o imágenes que cargue. Si se niega a autorizar la provisión, no podrá utilizar esto. función, pero no afectará su uso normal. Otras funciones de [########]. ",
    "",
    " 6. Servicios adicionales basados en las aplicaciones instaladas: la plataforma actualizará periódicamente el cliente de la aplicación. Al actualizar, puede abrir la APLICACIÓN para actualizar, instalar y usar directamente la nueva versión. ",
    "",
    " 7. Servicios adicionales basados en la aplicación que obtiene información sobre las tareas actualmente en ejecución: la plataforma juzgará la página de la APLICACIÓN al contar los datos de la APLICACIÓN. ",
    "",
    " 8. Servicios adicionales basados en mantener la APLICACIÓN activa: Cuando utilices esta aplicación, los datos se sincronizarán automáticamente en segundo plano. ",
    "",
    " 9. Para brindarle de manera más completa la información del producto o servicio que necesita y brindarle servicios personalizados o no personalizados, extraeremos su información en función de sus registros de navegación y búsqueda, información del dispositivo, información de ubicación e información de pedidos. Navegación, preferencias de búsqueda, hábitos de comportamiento y características de información de ubicación .",
    "",
    " Usted comprende y acepta que los servicios adicionales anteriores requieren que habilite el acceso a la información de su ubicación (ubicación geográfica), cámara (cámara), álbum de fotos (biblioteca de imágenes), calendario y almacenamiento externo en su dispositivo para cumplir con los requisitos de estos. permisos. Con respecto a la recopilación y el uso de información, tenga en cuenta que cuando habilita cualquier permiso, nos autoriza a recopilarla y utilizarla. Usar información personal relevante para brindarle los servicios correspondientes. Una vez que cierre cualquier permiso, significa que ha cancelado la autorización. Ya no continuaremos recopilando ni usando información personal relevante en función de los permisos correspondientes, y no podremos hacerlo. proporcionarle los servicios correspondientes a este permiso. Sin embargo, su decisión de desactivar el permiso no afectará la recopilación y el uso previo de la información basada en su autorización. ",
    "",
    " (7) Otros ",
    "",
    " 1. Si la información que proporciona contiene información personal de otros usuarios, debe asegurarse de haber obtenido autorización legal antes de proporcionar dicha información personal a [########]. Si se trata de información personal de niños, debe obtener el consentimiento del tutor del niño correspondiente antes de publicar. En las circunstancias anteriores, el tutor tiene derecho a contactarnos a través de los canales en el Artículo 10 de esta política para solicitar la corrección o eliminación de contenido que involucre información personal de los niños .",
    "",
    " 2. Si utilizamos la información para otros fines no especificados en esta política, o utilizamos la información recopilada para un propósito específico para otros fines, o si obtenemos activamente su información personal de un tercero, la obtendremos por adelantado. Tu consentimiento ",
    "",
    " Si obtenemos su información indirectamente de un tercero, le solicitaremos claramente al tercero por escrito antes de la recopilación que recopile información personal después de obtener su consentimiento de acuerdo con la ley, y le informaremos sobre el contenido de la información compartida, y si implica información sensible Se requiere su confirmación explícita antes de que se nos proporcione la información para su uso, y se requiere que los terceros confirmen la legalidad y el cumplimiento de la fuente de información personal. Prometemos que si un tercero comete una infracción, exigiremos claramente a la otra parte que asuma las responsabilidades legales correspondientes y, al mismo tiempo, fortaleceremos la seguridad de la información personal (incluidos los informes de información confidencial, el almacenamiento cifrado de información confidencial y la información confidencial); control de acceso). Protegeremos la información personal obtenida indirectamente con no menos de los mismos métodos y medidas de protección que utilizamos para la información personal de nuestros propios usuarios. ",
    "",
    " 3. Excepciones con consentimiento autorizado ",
    "",
    " Usted comprende y acepta plenamente que no requerimos su autorización y consentimiento para recopilar y utilizar su información personal en las siguientes circunstancias, y no responderemos a sus solicitudes de corrección/modificación, eliminación, cancelación, retirada de consentimiento o solicitud. para información: ",
    "",
    " (1) Relevante para la seguridad nacional y la seguridad de la defensa nacional; ",
    "",
    " (2) Relevante para la seguridad pública, la salud pública y los principales intereses públicos; ",
    "",
    " (3) Aplicación de la ley judicial o administrativa relacionada con la investigación, procesamiento, juicio y ejecución de sentencias penales; ",
    "",
    " (4) Para proteger sus principales derechos e intereses legítimos o los de otras personas relacionados con la vida y la propiedad, pero es difícil obtener su consentimiento; ",
    "",
    " (5) Información personal que usted divulga al público por su cuenta; ",
    "",
    " (6) La información personal se recopila a partir de información divulgada públicamente legalmente, como informes de noticias legales y canales de divulgación de información gubernamental. ",
    "",
    " (7) Según sea necesario para firmar y ejecutar acuerdos relevantes u otros documentos escritos con usted; ",
    "",
    " (8) Necesario para mantener el funcionamiento seguro y estable de los productos y/o servicios proporcionados, y para descubrir y manejar fallas de productos y/o servicios; ",
    "",
    " (9) Necesario para informar noticias legítimas; ",
    "",
    " (10) Cuando sea necesario que una institución de investigación académica realice investigaciones estadísticas o académicas basadas en intereses públicos, y cuando proporcione los resultados de una investigación o descripción académica al mundo exterior, la información personal contenida en los resultados será de- identificado; ",
    "",
    " (11) Otras situaciones previstas en las leyes y reglamentos. ",
    "",
    " Tenga en cuenta que, de conformidad con las leyes aplicables, si tomamos medidas técnicas y otras medidas necesarias para procesar información personal de modo que el destinatario de los datos no pueda volver a identificar a un individuo específico y no pueda recuperarlo, o realizaremos la desidentificación de la información recopilada, análisis estadístico y predicción para mejorar. El contenido y el diseño de [########], para proporcionar soporte de productos o servicios para decisiones comerciales y para mejorar nuestros productos y servicios (incluido el uso de datos anónimos para el aprendizaje automático o el entrenamiento de algoritmos de modelos), entonces dicho procesamiento Los datos se utilizan sin previo aviso y sin su consentimiento. ",
    "",
    " 4. Si dejamos de operar [########] productos o servicios, dejaremos de recopilar su información personal de inmediato, le notificaremos el cese de operaciones en forma de entrega o anuncio uno por uno, y Si la información personal que tenemos relacionada con el negocio cerrado involucra información personal de niños, notificaremos de inmediato a los tutores de los niños sobre la suspensión de operaciones ",
    "",
    " 2. Cómo utilizamos las cookies ",
    " Para brindarle una experiencia de acceso más fácil y recomendar contenido que le interese, almacenaremos un pequeño archivo de datos llamado Cookie en su dispositivo móvil. Las cookies generalmente contienen identificadores, nombres de sitios y algunos números y caracteres. Con la ayuda de cookies, podemos almacenar la información de su cuenta, registros de productos, registros de pedidos y datos de productos ",
    "",
    " Si no está de acuerdo con que almacenemos pequeños archivos de datos de cookies en su dispositivo móvil, puede dejar de usar [########] ",
    "",
    " 3. Cómo compartimos, transferimos y divulgamos públicamente su información ",
    " (1) Compartir ",
    "",
    " No compartiremos su información personal con empresas, organizaciones e individuos distintos de los proveedores de servicios de [########], excepto en las siguientes circunstancias: ",
    "",
    " 1. Compartir en circunstancias legales: Compartiremos su información personal externamente de acuerdo con las leyes y regulaciones, litigios, necesidades de resolución de disputas o de acuerdo con los requisitos de las autoridades administrativas y judiciales de conformidad con la ley. ",
    "",
    " 2. Compartir con consentimiento explícito: Después de obtener su consentimiento explícito, compartiremos su información personal con otras partes. ",
    "",
    " 3. Compartir cuando usted elige activamente: Cuando compra bienes o servicios a través de la plataforma [########], compartiremos la información necesaria relacionada con la transacción en la información de su pedido con los Proveedores de bienes o servicios relacionados. para satisfacer sus necesidades de transacción y servicio postventa ",
    "",
    " 4. Compartir con empresas afiliadas: para facilitarnos la entrega de productos y servicios basados en la cuenta de la plataforma [########], recomendar información que le interese, identificar anomalías en las cuentas de usuarios registrados y proteger [#### ####] La seguridad personal y de propiedad de las empresas afiliadas u otros usuarios o del público está protegida contra infracciones. La información se compartirá con nuestros afiliados y/o sus proveedores de servicios designados. Solo compartiremos la información personal necesaria y sujeta a los propósitos establecidos en esta Política de Privacidad si compartimos su información personal confidencial o nuestros afiliados. y tratamiento de información personal, se solicitará nuevamente su autorización y consentimiento. ",
    "",
    " 5. Compartir con socios autorizados: Confiamos a socios autorizados para que le proporcionen ciertos servicios o realicen funciones en nuestro nombre. Solo compartiremos su información para los fines legales, legítimos, necesarios, específicos y claros establecidos en esta política de privacidad. Autorizados Los socios solo tendrán acceso a la información necesaria para desempeñar sus funciones y no podrán utilizar esta información para ningún otro propósito. No permitimos que los socios subdeleguen .",
    "",
    " Actualmente, nuestros socios autorizados incluyen los siguientes tipos: ",
    "",
    " (1) Comerciantes, proveedores de servicios y otros socios. Enviamos información a comerciantes, proveedores de servicios y otros socios que respaldan nuestro negocio. Estos soportes incluyen servicios de infraestructura técnica que nos han confiado para brindar, análisis de nuestros servicios, métodos de uso, brindar servicio al cliente. , conveniencia de pago o realizar investigaciones y encuestas académicas. Esta información es difícil de asociar con su información de identidad. Esta información nos ayudará a analizar la efectividad de los servicios relacionados .",
    "",
    " Para garantizar el funcionamiento estable y la realización de funciones de nuestro cliente, para que pueda utilizar y disfrutar de más servicios y funciones, nuestra aplicación incorporará el SDK de los socios autorizados. Proporcionaremos interfaces de programación de aplicaciones para que los socios autorizados obtengan información relevante (API) y el kit de desarrollo de herramientas de software (SDK) se someten a estrictas pruebas de seguridad y acuerdan estrictas medidas de protección de datos con socios autorizados para procesar información personal de acuerdo con esta política y cualquier otra medida de confidencialidad y seguridad relevante .",
    "",
    " Los detalles son los siguientes: ",
    "",
    " ",
    " Nombre del SDK: KTPsdk ",
    "",
    " Fabricante: Shenzhen Jintaiyi Electronics Co., Ltd. ",
    "",
    " Tipo de información de recopilación: obtenga la lista de Bluetooth del teléfono móvil y escanee los dispositivos Bluetooth alrededor del teléfono móvil ",
    "",
    " Propósito de uso: Se utiliza para leer la lista de Bluetooth en un dispositivo específico, conectarse a Bluetooth y enviar información a través de Bluetooth. ",
    " (Equipo específico: terminal POS inalámbrico producido por Shenzhen Jintaiyi Electronics Co., Ltd.) ",
    "",
    " Se utilizan permisos sensibles del sistema: permiso de escaneo de Bluetooth, permiso de conexión de Bluetooth, permiso de posicionamiento. ",
    "",
    " Escenarios de uso específicos: conexión a Bluetooth, permiso de conexión Bluetooth. Escaneo de Bluetooth cercano, permiso de escaneo de Bluetooth, permiso de posicionamiento. ",
    "",
    "",
    "",
    " Nombre del SDK: flutter_bluetooth_serial ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información de recopilación: obtenga la lista de Bluetooth del teléfono móvil y escanee los dispositivos Bluetooth alrededor del teléfono móvil ",
    "",
    " Propósito de uso: leer la lista de Bluetooth del dispositivo, conectarse a Bluetooth y enviar información a través de Bluetooth. ",
    "",
    " Se utilizan permisos sensibles del sistema: permiso de escaneo de Bluetooth, permiso de conexión de Bluetooth, permiso de posicionamiento. ",
    "",
    " Escenarios de uso específicos: conexión a Bluetooth, permiso de conexión Bluetooth. Escaneo de Bluetooth cercano, permiso de escaneo de Bluetooth, permiso de posicionamiento. ",
    "",
    "",
    " Nombre del SDK: image_picker ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información recopilada: Obtener permisos de almacenamiento de lectura y escritura en el teléfono móvil, obtener permisos de uso de la cámara ",
    "",
    " Propósito: seleccionar imágenes de la biblioteca de imágenes y tomar nuevas fotografías con la cámara. ",
    "",
    " Se utilizan permisos sensibles del sistema: permisos de lectura y escritura de almacenamiento externo, permisos de cámara ",
    "",
    " Escenarios de uso específicos: configuración, modificación de funciones de fotografías del producto mediante permisos de cámara, permisos de almacenamiento. ",
    "",
    "",
    "",
    " Nombre del SDK: vibración ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información de recopilación: obtener permiso de vibración del teléfono móvil ",
    "",
    " Propósito de uso: hacer vibrar el teléfono después de escanear exitosamente el código QR. ",
    "",
    " Se utilizan permisos sensibles del sistema: permiso de vibración del teléfono ",
    "",
    " Escenario de uso específico: después de escanear con éxito el código QR, el teléfono vibra y los permisos vibran. ",
    "",
    "",
    "",
    " Nombre del SDK: qr_code_scanner ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información de recopilación: obtener permiso de uso de la cámara ",
    "",
    " Propósito de uso: Escanear códigos QR y códigos de barras. ",
    "",
    " Uso de permisos sensibles del sistema: permisos de cámara ",
    "",
    " Escenarios de uso específicos: escanear códigos de barras de productos, derechos de uso de la cámara, escanear códigos QR de documentos, derechos de uso de la cámara. ",
    "",
    "",
    "",
    " Nombre del SDK: path_provider ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información recopilada: Obtener permisos de almacenamiento de lectura y escritura en el teléfono móvil ",
    "",
    " Propósito de uso: Se utiliza para encontrar ubicaciones comunes en el sistema de archivos. ",
    "",
    " Se utilizan permisos confidenciales del sistema: permisos de lectura y escritura de almacenamiento externo ",
    "",
    " Escenarios de uso específicos: obtenga la dirección de almacenamiento externo de la APLICACIÓN, lea y escriba permisos de almacenamiento externo, cree una carpeta en la dirección de almacenamiento externo, lea y escriba permisos de almacenamiento externo. ",
    "",
    "",
    " Nombre del SDK: open_filex ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información recopilada: Obtener permisos de almacenamiento de lectura y escritura en el teléfono móvil ",
    "",
    " Propósito de uso: se utiliza para abrir el archivo especificado en el sistema de archivos. ",
    "",
    " Se utilizan permisos confidenciales del sistema: permisos de lectura y escritura de almacenamiento externo ",
    "",
    " Escenarios de uso específicos: después de descargar el paquete de instalación de la actualización de software en el almacenamiento externo, abra el archivo para completar la actualización, con permisos de lectura y escritura en el almacenamiento externo. ",
    "",
    "",
    "",
    " Nombre del SDK: flutter_downloader ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información recopilada: obtener permiso de almacenamiento de lectura y escritura en el teléfono móvil, obtener permiso de notificación en el teléfono móvil ",
    "",
    " Propósito de uso: complemento para crear y administrar tareas de descarga. ",
    "",
    " Se utilizan permisos sensibles del sistema: permisos de lectura y escritura de almacenamiento externo, obtención de permisos de notificación del teléfono móvil ",
    "",
    " Escenarios de uso específicos: descarga de software y actualización del paquete de instalación, almacenamiento externo, permisos de lectura y escritura de almacenamiento externo, la barra de notificaciones muestra información y progreso de la descarga, permisos de notificación .",
    "",
    "",
    "",
    " Nombre del SDK: permiso_handler ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información recopilada: Sin requisitos de permiso específicos ",
    "",
    " Propósito de uso: complemento utilizado para administrar y solicitar permisos de aplicaciones. ",
    "",
    " Permisos sensibles del sistema utilizados: relacionados con el permiso solicitado (por ejemplo, cámara, ubicación, contactos) ",
    "",
    " Escenarios de uso específicos: solicite autorización del usuario para acceder a las funciones del dispositivo (como cámara, ubicación), verifique y administre el estado de los permisos. ",
    "",
    "",
    " Nombre del SDK: share_plus ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información recopilada: Sin requisitos de permiso específicos ",
    "",
    " Propósito de uso: complemento para compartir contenido dentro de la aplicación. ",
    "",
    " Se utilizaron permisos confidenciales del sistema: no hay permisos confidenciales específicos ",
    "",
    " Escenarios de uso específicos: los usuarios pueden compartir texto, imágenes, archivos y otros contenidos con otras aplicaciones o plataformas a través de complementos, como redes sociales, aplicaciones de mensajería o clientes de correo electrónico. ",
    "",
    "",
    "",
    " Nombre del SDK: cámara ",
    "",
    " Fabricante: complemento de código abierto de terceros ",
    "",
    " Tipo de información recopilada: Sin requisitos de permiso específicos ",
    "",
    " Propósito de uso: complemento para acceder y controlar la cámara del dispositivo dentro de una aplicación. ",
    "",
    " Permiso sensible del sistema utilizado: permiso de cámara (para acceder a la cámara del dispositivo) ",
    "",
    " Escenarios de uso específicos: tomar fotografías, grabar videos, obtener una vista previa del contenido de la cámara, etc., generalmente utilizados en aplicaciones que requieren funciones de la cámara. ",
    "",
    "",
    "",
    " ",
    " Definición de campos de información personal y lista exhaustiva ",
    "",
    " Dirección MAC del dispositivo: la dirección mac del dispositivo, la dirección que confirma la ubicación del dispositivo móvil ",
    "",
    " Código de identificación de dispositivo único: IDFA para dispositivos iOS, IMEI, oaid, AndroidId, UUID para dispositivos Android (se recopilarán múltiples códigos de identificación para Android y superiores) ",
    "",
    " Dirección IP del dispositivo: Protocolo de enlace de Internet móvil, confirma el servicio del operador de conexión de red ",
    "",
    " Información del dispositivo: marca del dispositivo, modelo del dispositivo, sistema operativo, versión del sistema operativo ",
    "",
    " Información de red: entorno de red actual (como WIFI, 5G, 4G, 3G, 2G) ",
    "",
    "",
    " (2) Transferir ",
    "",
    " No transferiremos su información personal a ninguna empresa, organización o individuo, excepto en las siguientes circunstancias: ",
    "",
    " 1. Transferencia con consentimiento explícito: Después de obtener su consentimiento explícito, transferiremos su información personal a otras partes; ",
    "",
    " 2. Cuando el [########] proveedor de servicios sufra una fusión, adquisición o liquidación concursal, u otras situaciones que involucren fusiones, adquisiciones o liquidación concursal, si se trata de transferencia de información personal, requeriremos nuevos Las empresas y organizaciones que conservan su información personal seguirán sujetas a esta política; de lo contrario, solicitaremos a la empresa, organización e individuo que soliciten su autorización nuevamente .",
    "",
    " (3) Divulgación pública ",
    "",
    " Sólo divulgaremos públicamente su información personal en las siguientes circunstancias: ",
    "",
    " 1. Divulgaremos públicamente su información personal con su consentimiento explícito o según su elección activa; ",
    "",
    " 2. Si determinamos que ha violado leyes y regulaciones o ha violado gravemente los acuerdos y reglas relevantes de la plataforma [########], o para proteger a los usuarios de la plataforma [########] o el público Para proteger la seguridad personal y de la propiedad contra infracciones, divulgamos su información personal de acuerdo con las leyes y regulaciones o con su consentimiento, incluidas las violaciones relevantes y las medidas que la plataforma [########] ha tomado contra tú. ",
    "",
    " (4) Excepciones a la obtención de autorización y consentimiento previo al compartir, transferir y divulgar públicamente información personal ",
    "",
    " En las siguientes circunstancias, compartir, transferir y divulgar públicamente su información personal no requiere su autorización y consentimiento previo: ",
    "",
    " 1. Relacionados con la seguridad nacional y la seguridad de la defensa nacional; ",
    "",
    " 2. Relacionados con la seguridad pública, la salud pública y los principales intereses públicos; ",
    "",
    " 3. Relevantes para la investigación penal, el procesamiento, el juicio y la ejecución de sentencias, la aplicación de la ley judicial o administrativa; ",
    "",
    " 4. Para proteger sus principales derechos e intereses legítimos o los de otras personas relacionados con la vida y la propiedad, pero es difícil obtener mi consentimiento; ",
    "",
    " 5. Información personal que usted divulga al público por su cuenta; ",
    "",
    " 6. Recopilar información personal a partir de información divulgada públicamente legalmente, como informes de noticias legales y divulgación de información gubernamental. ",
    "",
    " Tenga en cuenta que, de conformidad con las leyes aplicables, si tomamos medidas técnicas y otras medidas necesarias para procesar información personal de modo que el destinatario de los datos no pueda volver a identificar a un individuo específico y no pueda recuperarla, el intercambio, la transferencia y la divulgación pública de dichos datos procesados no se le notifican más y se requiere su consentimiento ",
    "",
    " 4. Cómo almacenamos su información ",
    " (1) Ubicación de almacenamiento ",
    "",
    " La información personal que recopilamos y generamos durante nuestras operaciones se almacenará en la región de alojamiento del servidor. En las siguientes circunstancias, proporcionaremos su información personal a las entidades pertinentes después de cumplir con nuestras obligaciones según la ley: ",
    "",
    " 1. Se estipulan claramente las leyes aplicables; ",
    "",
    " 2. Obtener su autorización explícita; ",
    "",
    " 3. Su iniciativa personal al realizar transacciones transfronterizas a través de Internet. ",
    "",
    " En respuesta a las circunstancias anteriores, utilizaremos un contrato para garantizar que su información personal esté protegida a un nivel no inferior al especificado en esta política. ",
    "",
    " (2) Período de almacenamiento ",
    "",
    " Tomaremos medidas razonables y factibles para hacer nuestro mejor esfuerzo para evitar recopilar y procesar información personal irrelevante. Solo conservaremos su información personal durante el período necesario para lograr los propósitos establecidos en esta política, a menos que la ley establezca requisitos obligatorios de retención, transacciones información El período de almacenamiento no será inferior a tres años a partir de la fecha de finalización de la transacción. Nos referimos principalmente a los siguientes estándares al determinar el período de almacenamiento, el que sea más largo: ",
    "",
    " 1. Completar los propósitos de transacción relacionados con usted, mantener los registros comerciales y de transacciones correspondientes y responder a sus consultas o quejas; ",
    "",
    " 2. Garantizar la seguridad y calidad de los servicios que le brindamos; ",
    "",
    " 3. ¿Está de acuerdo con un período de retención más largo? ",
    "",
    " 4. ¿Existe algún otro acuerdo especial sobre el período de conservación? ",
    "",
    " Después de exceder el período de retención, eliminaremos o anonimizaremos su información personal dentro de los 15 días de acuerdo con los requisitos de las leyes aplicables. Además, después de cancelar su cuenta, dejaremos de brindarle productos y servicios dentro de los 15 días, y de acuerdo con su solicitud, su información personal será eliminada o anonimizada, a menos que las leyes y reglamentos dispongan lo contrario ",
    "",
    " 5. Cómo protegemos su información ",
    " (1) Hemos adoptado medidas de seguridad razonables y factibles que cumplen con los estándares de la industria para proteger su información y evitar el acceso no autorizado, la divulgación pública, el uso, la modificación, el daño o la pérdida de la información personal entre su navegador y el servidor. ######】El sitio web proporciona navegación segura utilizando el protocolo HTTPS; utilizaremos tecnología de cifrado para mejorar la seguridad de la información personal; utilizaremos mecanismos de protección confiables para evitar ataques maliciosos a la información personal; Se hace todo lo posible para garantizar que sólo el personal autorizado tenga acceso a la información personal. ",
    "",
    "",
    "",
    " (2) Tomaremos medidas razonables y factibles para hacer nuestro mejor esfuerzo para evitar recopilar información personal irrelevante. Solo conservaremos su información personal durante el período necesario para lograr los propósitos establecidos en esta política, a menos que existan requisitos de retención obligatorios por ley. El período de retención de la información no será inferior a tres años a partir de la fecha de finalización de la transacción, y nuestros criterios para juzgar el período antes mencionado incluyen: ",
    "",
    " 1. Completar los propósitos de transacción relacionados con usted, mantener los registros comerciales y de transacciones correspondientes y responder a sus consultas o quejas; ",
    "",
    " 2. Garantizar la seguridad y calidad de los servicios que le brindamos; ",
    "",
    " 3. ¿Está de acuerdo con un período de retención más largo? ",
    "",
    " 4. ¿Existe algún otro acuerdo especial sobre el período de conservación? ",
    "",
    " Después de que su información personal exceda el período de retención, la eliminaremos o la haremos anónima de acuerdo con los requisitos de las leyes aplicables. ",
    "",
    " (3) Internet no es un entorno absolutamente seguro. Al utilizar los servicios de la plataforma [########], le recomendamos encarecidamente que no utilice métodos de comunicación que no estén recomendados por [##### ###] para enviarle información. Pueden establecer conexiones y compartir entre sí a través de nuestros servicios. Cuando crean comunicaciones, transacciones o comparten a través de nuestros servicios, pueden elegir quién puede ver el contenido de su transacción. o compartir información relacionada con el contenido con terceros ",
    "",
    " Al utilizar los servicios de [########] para transacciones en línea, inevitablemente tendrá que revelar su información personal, como información de contacto o dirección de contacto, a la contraparte o posible contraparte. Protéjase adecuadamente. Su información personal La información solo se proporcionará a otras personas cuando sea necesario. Si descubre que su información personal, especialmente su cuenta o contraseña, se ha filtrado, comuníquese con el servicio de atención al cliente de [########] de inmediato para que podamos actuar en consecuencia. Tome las medidas adecuadas en su solicitud ",
    "",
    " Tenga en cuenta que la información que comparte voluntariamente o incluso públicamente cuando utiliza nuestros servicios incluirá información personal suya o de otras personas o incluso información personal confidencial. Considere más detenidamente si comparte o incluso comparte públicamente información relevante cuando utiliza nuestros servicios. ",
    "",
    " Ayúdenos a garantizar la seguridad de su cuenta mediante el uso de una contraseña compleja. Haremos todo lo posible para garantizar la seguridad de cualquier información que nos envíe. Si se violan nuestras instalaciones de protección física, técnica o administrativa, lo que resulta en un acceso no autorizado a la información. , Si sus derechos e intereses legítimos se ven perjudicados debido a la divulgación pública, manipulación o destrucción, asumiremos la responsabilidad legal correspondiente .",
    "",
    " (4) Actualizaremos y divulgaremos contenido relevante sobre riesgos de seguridad e impactos en la seguridad de la información personal de vez en cuando, que puede obtener a través del anuncio [########]. ",
    "",
    " (5) Después de que ocurra un incidente desafortunado de seguridad de la información personal, le informaremos de acuerdo con los requisitos de las leyes y regulaciones: la situación básica y el impacto del incidente de seguridad, las medidas de eliminación que hemos tomado o tomaremos, y cómo usted puede prevenir y reducir riesgos por su cuenta. Sugerencias y medidas correctivas para usted. Le informaremos sobre la información relacionada con el incidente por correo electrónico, llamada telefónica y notificación automática. Cuando sea difícil notificar a los interesados uno por uno, lo haremos. emitirá anuncios de manera razonable y efectiva .",
    "",
    "",
    " 6. ¿Cómo gestionas tu información ",
    " Puedes acceder y administrar tu información de las siguientes maneras: ",
    "",
    " (1) Consultar, rectificar y complementar su información ",
    "",
    " Tiene derecho a consultar, rectificar o complementar su información. Puede hacerlo usted mismo de las siguientes maneras: ",
    "",
    ' 1. Inicie sesión en el cliente [########], ingrese a la página "Cuenta" y corrija la información personal y la información relacionada con la cuenta personal; ',
    " 2. Autorizar a los directivos pertinentes para realizar modificaciones ",
    "",
    " (2) Elimina tu información ",
    "",
    "",
    " Puede solicitarnos que eliminemos su información personal en las siguientes situaciones: ",
    "",
    " 1. Si nuestro manejo de información personal viola las leyes y regulaciones; ",
    "",
    " 2. Si recopilamos y utilizamos su información personal sin su consentimiento explícito; ",
    "",
    " 3. Si nuestro manejo de información personal viola gravemente nuestro acuerdo con usted; ",
    "",
    " 4. Si ya no utiliza nuestros productos o servicios, o solicita activamente cancelar su cuenta; ",
    "",
    " 5. Si ya no le proporcionamos productos o servicios de forma permanente. ",
    "",
    " Si decidimos responder a su solicitud de eliminación, también notificaremos a las personas que obtuvieron su información personal de nosotros y les exigiremos que la eliminen de manera oportuna (a menos que las leyes y regulaciones dispongan lo contrario, o que estas personas hayan obtenido su información de forma independiente). autorización) ",
    "",
    " Cuando usted o nosotros le ayudamos a eliminar información relevante, no podemos eliminar inmediatamente la información correspondiente del sistema de respaldo debido a las leyes y tecnologías de seguridad aplicables. Almacenaremos de forma segura su información personal y la aislaremos de cualquier procesamiento posterior, hasta que la copia de seguridad se puede borrar o anonimizar ",
    "",
    " (3) Cambiar el alcance de su autorización y consentimiento ",
    "",
    " Si ya no elige proporcionarnos su información personal o se niega a utilizar algunos de los servicios que brindamos, puede desactivar algunos de los permisos que ha autorizado a [########] configurando su dispositivo móvil inteligente ",
    "",
    " Cuando retire su consentimiento, ya no procesaremos la información personal correspondiente. Sin embargo, su decisión de retirar su consentimiento no afectará el procesamiento previo de información personal basado en su autorización. ",
    "",
    " (4) Cancelar tu cuenta ",
    "",
    " Puede solicitar la cancelación de su cuenta a través de los siguientes métodos: ",
    "",
    " 1. Puede comunicarse con su administrador backend para cancelar su cuenta. ",
    "",
    " Después de cancelar su cuenta, dejaremos de proporcionarle bienes o servicios y eliminaremos o anonimizaremos su información personal de acuerdo con los requisitos de las leyes aplicables. ",
    "",
    " (5) Sistema de información de restricciones para la toma de decisiones automática ",
    "",
    " En determinadas funciones comerciales, solo tomamos decisiones basadas en mecanismos automáticos de toma de decisiones no artificiales, incluidos sistemas y algoritmos de información. Si estas decisiones afectan significativamente sus derechos e intereses legítimos, tiene derecho a solicitarnos una explicación y nosotros También proporcionará métodos de queja sin infringir los secretos comerciales de [########] u otros derechos del usuario e intereses públicos sociales ",
    "",
    " (6) Responder a su solicitud anterior ",
    "",
    " Por razones de seguridad, deberá presentar una solicitud por escrito o demostrar su identidad de otro modo. Le pediremos que verifique su identidad antes de procesar su solicitud. ",
    "",
    " Responderemos dentro de los 15 días. Si no está satisfecho, también puede iniciar una queja a través del servicio de atención al cliente de [########]. ",
    "",
    " En principio, no cobramos tarifas por sus solicitudes razonables, pero por solicitudes repetidas que excedan los límites razonables, cobraremos una tarifa determinada a nuestra discreción. Para información que no esté directamente relacionada con su identidad, información repetida sin motivo, o necesidades Rechazaremos solicitudes que requieran medios técnicos excesivos (que requieran el desarrollo de nuevos sistemas o cambios fundamentales en las prácticas actuales), que representen riesgos para los derechos e intereses legítimos de otros, o que no sean realistas .",
    "",
    " En las siguientes circunstancias, de conformidad con los requisitos legales y reglamentarios, no podremos responder a su solicitud: ",
    "",
    " 1. Relacionados con la seguridad nacional y la seguridad de la defensa nacional; ",
    "",
    " 2. Relacionados con la seguridad pública, la salud pública y los principales intereses públicos; ",
    "",
    " 3. Relevantes para la investigación penal, el procesamiento, el juicio y la ejecución de sentencias; ",
    "",
    " 4. Existe evidencia suficiente de que el titular de la información personal tiene dolo subjetivo o abusó de sus derechos; ",
    "",
    " 5. Responder a su solicitud causará daños graves a los derechos e intereses legítimos de usted o de otras personas u organizaciones; ",
    "",
    " 6. Involucrando secretos comerciales. ",
    "",
    "",
    " 7. Cómo se transfiere su información globalmente ",
    "",
    " 1. Se estipulan claramente las leyes aplicables; ",
    "",
    " 2. Obtener su autorización explícita; ",
    "",
    " 3. Su iniciativa personal al realizar transacciones transfronterizas a través de Internet. ",
    "",
    " En vista de las circunstancias anteriores, nos aseguraremos de que su información personal esté adecuadamente protegida de acuerdo con esta política de privacidad. ",
    "",
    " 8. Cómo actualizar esta política de privacidad ",
    " Cambios en nuestra política de privacidad. ",
    "",
    " No limitaremos sus derechos según esta Política de privacidad sin su consentimiento explícito. Publicaremos actualizaciones de la Política de privacidad en la aplicación. ",
    "",
    " Para cambios importantes, también proporcionaremos notificaciones más destacadas (incluidas notificaciones a través de [########] anuncios públicos o incluso le brindaremos mensajes emergentes). ",
    "",
    " Los cambios principales a los que se hace referencia en esta política incluyen, entre otros: ",
    "",
    " 1. Cambios importantes en nuestro modelo de servicio. Como el propósito del procesamiento de información personal, los tipos de información personal procesada y el uso de la información personal; ",
    "",
    " 2. Tenemos cambios importantes en el control. Como cambios en los controladores de la información causados por fusiones, adquisiciones y reorganizaciones; ",
    "",
    " 3. Los principales objetos con quienes se comparte, transfiere o divulga públicamente la información personal cambian; ",
    "",
    " 4. Cambios significativos en sus derechos a participar en el procesamiento de información personal y la forma en que los ejerce; ",
    "",
    " 5. Nuestros departamentos responsables, información de contacto y canales de quejas para el manejo de la seguridad de la información personal han cambiado; ",
    "",
    " 6. El informe de evaluación del impacto en la seguridad de la información personal indica que existe un riesgo alto. ",
    "",
    " 9. Cómo contactarnos ",
    " Puede contactarnos a través de los siguientes métodos y responderemos a su solicitud dentro de los 15 días siguientes: ",
    "",
    " 1. Si tiene alguna pregunta, comentario o sugerencia sobre el contenido de esta política, puede contactarnos a través del correo electrónico de atención al cliente de [########]: [support@########.com] ",
    "",
    " 2. Si descubre que su información personal se ha filtrado, puede comunicarse con nuestro personal de servicio al cliente para presentar una queja; ",
    "",
    " Si no está satisfecho con nuestra respuesta, especialmente si cree que nuestro comportamiento de procesamiento de información personal ha perjudicado sus derechos e intereses legítimos, también puede buscar una solución presentando una demanda ante el tribunal con jurisdicción sobre el domicilio del demandado .",
  ],
  pt_PT: [
    "######## Política de Privacidade",
    "[Hora de entrada em vigor]: 23/07/2024",
    "[Hora de atualização]: 23/07/2024",
    "",
    "Pontas",
    "Antes de utilizar os produtos ou serviços de [########], leia atentamente e compreenda completamente esta política, especialmente os termos assinalados a negrito/negrito sublinhado. Deve lê-los com atenção. Certifique-se de compreender e concordar plenamente antes de começar a utilizá-la. Se tiver alguma dúvida, comentário ou sugestão sobre o conteúdo desta política, pode contactar-nos através dos vários métodos de contacto fornecidos por [######## ]",
    "",
    'A sua confiança é muito importante para nós. Estamos bem cientes da importância das informações pessoais para si. Tomaremos as medidas de proteção de segurança correspondentes de acordo com os requisitos das leis e regulamentos e faremos o nosso melhor para proteger as suas informações pessoais de forma segura e controlável Em vista disso, [### #####] O fornecedor de serviços (ou referido como "nós" ou "[########]") formula esta política de privacidade (doravante). referida como "esta política/esta política de privacidade") e recorda-lhe: A política aplica-se a [Toposfy Todos os produtos e serviços fornecidos pela Dashboard',
    "",
    "Deve notar-se que esta política não se aplica a serviços que lhe são fornecidos por terceiros, nem a produtos ou serviços em [########] que tenham políticas de privacidade definidas separadamente.",
    "",
    "Parte 1 Definição",
    "[########] Prestador de serviços: [Chengdu Huitong Times Software Co., Ltd.]",
    "",
    "Informações pessoais: referem-se a diversas informações registadas eletronicamente ou por outros meios que podem identificar uma pessoa singular específica, isoladamente ou combinadas com outras informações, ou refletir as atividades de uma pessoa singular específica.",
    "",
    "Informações pessoais confidenciais: incluem o endereço de e-mail e as informações de encomenda.",
    "",
    "Exclusão de informações pessoais: refere-se ao ato de remover informações pessoais de sistemas envolvidos na realização de funções comerciais diárias, para que não possam ser recuperadas ou acedidas.",
    "",
    "Crianças: refere-se a menores de catorze anos.",
    "",
    "Salvo acordo em contrário, as definições utilizadas nesta política têm o mesmo significado que as do [########] Contrato de Utilizador da Plataforma.",
    "",
    "Parte 2 Política de Privacidade",
    "Esta secção da política de privacidade irá ajudá-lo a compreender o seguinte:",
    "",
    "1. Como recolhemos e utilizamos as suas informações",
    "",
    "2. Como utilizamos cookies",
    "",
    "3. Como partilhamos, transferimos e divulgamos publicamente as suas informações",
    "",
    "4. Como armazenamos as suas informações",
    "",
    "5. Como protegemos as suas informações",
    "",
    "6. Como gere as suas informações",
    "",
    "7. Como as suas informações são transferidas globalmente",
    "",
    "8. Como atualizar esta política de privacidade",
    "",
    "9. Como contactar-nos",
    "",
    "1. Como recolhemos e utilizamos as suas informações",
    "Quando utiliza os nossos produtos e/ou serviços, as informações pessoais que necessitamos/necessitamos de recolher e utilizar incluem os dois tipos seguintes:",
    "",
    "1. Para lhe fornecer as funções básicas dos nossos produtos e/ou serviços, iremos recolher de acordo com os regulamentos relevantes. Obteremos as suas informações pessoais e informações do dispositivo (IMEI, endereço MAC do dispositivo, área de transferência, número de série do dispositivo , andridID), lista de instalação de aplicações Se se recusar a fornecer as informações correspondentes, não poderá utilizar os nossos produtos e/ou serviços normalmente, mas tal não afetará a sua navegação nas nossas páginas web e páginas de clientes;",
    "",
    "2. Para fornecer funções adicionais dos nossos produtos e/ou serviços, pode optar por nos autorizar a recolher e utilizar as suas informações pessoais confidenciais. Se se recusar a fornecê-las, não poderá utilizar os dados relevantes funções adicionais normalmente ou alcançar aquilo que pretendemos. Os efeitos funcionais alcançados não afectarão a utilização normal das funções básicas dos nossos produtos e/ou serviços ",
    "",
    "Você compreende e concorda:",
    "",
    "1. Estamos empenhados em criar uma variedade de produtos e serviços para satisfazer as suas necessidades. Como fornecemos muitos tipos de produtos e serviços, e a gama específica de produtos/serviços que os diferentes utilizadores escolhem utilizar é diferente, as funções básicas/adicionais e O tipo e o âmbito das informações pessoais recolhidas e utilizadas variam, consulte as funções específicas do produto/serviço;",
    "",
    "2. Para lhe oferecer uma melhor experiência de produto e serviço, continuamos a trabalhar arduamente para melhorar a nossa tecnologia e lançaremos funções novas ou otimizadas de tempos a tempos, o que exige que recolhamos e utilizemos novamente as suas informações pessoais ou alteremos a sua finalidade ou método de utilização de informações pessoais Atualizaremos esta política, janelas pop-up e páginas. O método imediato explicará separadamente a finalidade, o âmbito e a utilização das informações correspondentes e solicitará o seu consentimento expresso. ##】Contacte-nos através dos diversos meios de contacto disponibilizados e responderemos o mais breve possível ",
    "",
    "Recolheremos e utilizaremos as suas informações pessoais para implementar as funções descritas abaixo nesta política:",
    "",
    "(1) Ajudá-lo a tornar-se nosso utilizador registado",
    "",
    "1. Serviço básico de utilizador registado",
    "",
    "Para se registar como utilizador registado e utilizar os nossos serviços de utilizador registado, precisa de fornecer o seu número de telemóvel, endereço de e-mail e nome de utilizador e palavra-passe registados pretendidos para criar uma conta na plataforma [####### #]. Se apenas precisa de navegar, pesquisar serviço, não precisa de se registar como nosso utilizador registado e fornecer as informações acima ",
    "",
    "Quando fizer login na sua conta, verificaremos a sua identidade de utilizador registado com base nas informações fornecidas acima para garantir que lhe estamos a prestar serviços. Se houver cenários em que seja necessário determinar a sua identidade de utilizador registado de acordo com a lei (incluindo aplicação da lei administrativa ou judicial Quando as partes relevantes no processo são identificadas), autoriza-nos a obter as informações de autenticação relevantes da sua conta de pagamento correspondente para os fins acima referidos",
    "",
    "Forneceremos os direitos e interesses básicos correspondentes ao utilizador registado correspondente com base na utilização da sua conta de utilizador registado.",
    "",
    "2. Serviços adicionais para utilizadores registados",
    "",
    "Se optar por fornecer o seu nome real, sexo, data de nascimento e informações pessoais de residência, poderemos fornecer serviços de utilizador registado mais personalizados. Para garantir a identificação da transação, o apelido e o avatar da sua conta serão apresentados publicamente.",
    "",
    "Login autorizado: obteremos as informações da sua conta de terceiros com base na sua autorização e ligá-las-emos à sua conta da plataforma [########] para que possa aceder diretamente através da conta de terceiros. Faça iniciar sessão e utilizar os nossos produtos e/ou serviços Utilizaremos as suas informações relevantes no âmbito da sua autorização ",
    "",
    "3. Exibição de informações da conta: Se já tiver uma conta na plataforma [########], exibiremos as suas informações pessoais acima no serviço da plataforma [########], bem como as suas Operações realizadas na plataforma [########] ou em produtos e serviços associados à conta da plataforma [########] (pode utilizar [########] plano A conta Taiwan utiliza os produtos ou serviços que fornecemos no portal [########] ou outros portais de produtos/serviços que fornecemos), incluindo a exibição centralizada de si através da plataforma [###### ##] conta Informações pessoais, ordens de negociação Respeitaremos as suas escolhas sobre os serviços da plataforma [########] e as definições da conta da plataforma [########],",
    "",
    "(2) Fornecer exibição de informações sobre produtos ou serviços",
    "",
    "Quando utiliza os nossos serviços, a fim de identificar o estado anormal da conta, compreender a adequação do produto e fornecer a exibição de página e os resultados de pesquisa que melhor se adaptem às suas necessidades, recolheremos automaticamente a sua utilização e armazená-la-emos como informações de registo da web. incluem: ",
    "",
    "Informações sobre o dispositivo: receberemos e registaremos as informações relacionadas com o dispositivo que utiliza (incluindo o modelo do dispositivo, a versão do sistema operativo, as definições do dispositivo, o identificador único do dispositivo, o software de ambiente do dispositivo, etc.) com base nas suas operações específicas durante a instalação do software e/ou informações de recursos de hardware), informações relacionadas com a localização do dispositivo (incluindo a sua localização GPS autorizada) ",
    "",
    "Informações de registo de serviços: Quando utiliza os produtos ou serviços fornecidos pelo nosso website ou cliente, recolheremos automaticamente a sua utilização detalhada dos nossos serviços e guardá-los-emos como registos de serviços, incluindo navegação, cliques para visualizar, consultas de pesquisa, coleções, Adicionar ao carrinho de compras, transação, pós-venda, acompanhamento da partilha de informação, publicação de informação, bem como endereço IP, tipo de browser, operador de telecomunicações, idioma utilizado, data e hora de acesso”,",
    "",
    "Tenha em atenção que as informações separadas do dispositivo e as informações de registo de serviço são informações que não podem identificar uma pessoa singular específica. Se combinarmos estas informações não pessoais com outras informações para identificar uma pessoa singular específica ou as utilizarmos em combinação com informações pessoais, então Durante durante o período de utilização combinado, este tipo de informação não pessoal será tratada como informação pessoal, salvo disposição em contrário pela sua autorização ou leis e regulamentos, anonimizaremos e desidentificaremos este tipo de informação",
    "",
    "",
    "Além disso, também utilizaremos as informações acima para melhorar e otimizar continuamente as funções acima.",
    "",
    "",
    "Para que possamos saber e confirmar o progresso e o estado do seu pagamento em tempo útil, e para fornecer serviços pós-venda e resolução de litígios, concorda que podemos recolher e pagar informações sobre o progresso do parceiro de transação escolhido, empresa de pagamento ou outra instituição financeira à sua escolha.",
    "",
    "(3) Ajudar a concluir a entrega de bens ou serviços para si",
    "",
    "Para garantir que os bens e/ou serviços que adquiriu possam ser entregues de forma suave, segura e precisa, divulgaremos a entrega relacionada com a encomenda à empresa de logística e distribuição que fornece sistemas de informação logística e serviços técnicos para o [## # #####] As informações da plataforma e as informações de entrega relevantes serão sincronizadas com as entidades de logística e entrega correspondentes de acordo com a designação dos bens e/ou prestadores de serviços. e entrega correspondentes irão inevitavelmente.",
    "",
    "",
    "(4) Atendimento ao Cliente e Tratamento de Litígios",
    "",
    "Quando nos contacta ou envia um pedido de venda ou de resolução de litígios, para proteger a segurança da sua conta e sistema, precisamos que forneça as informações pessoais necessárias para verificar a sua identidade de utilizador registada.",
    "",
    "Para facilitar o contacto consigo, ajudá-lo a resolver o problema o mais rapidamente possível ou registar as soluções e resultados de problemas relacionados, guardaremos os registos de comunicação/chamada e conteúdo relacionado entre si e nós (incluindo informações de conta, encomendas informações e as informações que fornece para comprovar factos relevantes, outras informações ou as informações de contacto que deixou), se fizer perguntas, reclamações ou fornecer sugestões para pedidos específicos, utilizaremos as informações da sua conta e do pedido",
    "",
    "(5) Para lhe proporcionar uma garantia de segurança",
    "",
    "A fim de melhorar a segurança da sua utilização dos serviços prestados por nós e pelas nossas empresas afiliadas e parceiros, proteger a segurança pessoal e patrimonial sua ou de outros utilizadores ou do público contra infrações e prevenir melhor sites de phishing, fraudes, vulnerabilidades de rede , vírus de computador e ataques de rede e riscos de segurança de intrusão de rede, identificam com maior precisão violações de leis e regulamentos ou [########] regras de protocolo relacionadas Neste caso, utilizamos ou integramos as suas informações de utilizador registado, informações de transações, informações de dispositivos, informações de registo de serviços e informações que as nossas empresas afiliadas e parceiros obtiveram a sua autorização ou partilharam de acordo com a lei para avaliar de forma abrangente a sua conta e riscos de transação , e identificar a sua identidade, verificar, detetar e prevenir incidentes de segurança e tomar as medidas necessárias de registo, auditoria, análise e eliminação de acordo com a lei.",
    "",
    "(6) Prestar-lhe outros serviços adicionais",
    "",
    "Para lhe fornecer produtos e/ou serviços mais convenientes, melhores e personalizados, e para nos esforçarmos por melhorar a sua experiência, iremos recolher, armazenar em cache e utilizar as suas informações pessoais nos seguintes serviços adicionais que lhe são fornecidos. Se não fornecer essas informações não afetarão a utilização dos serviços básicos de navegação e pesquisa de [########], mas não poderá obter a experiência de utilizador proporcionada por estes serviços adicionais.",
    "",
    "1. Serviços adicionais baseados em câmara/câmara: pode utilizar esta função após ativar a permissão da câmara (câmara). Mesmo que tenha concordado em ativar a permissão da câmara (câmara), só a utilizaremos quando clicar ativamente no ícone correspondente ou gravar um vídeo Obtenha informações da fotografia através da câmara.",
    "",
    "2. Serviços adicionais baseados na leitura e gravação de cartões de memória externos: Depois de ativar a permissão de armazenamento, pode utilizar esta função para carregar as suas fotografias/fotos/vídeos para comentar/partilhar ou comunicar com o serviço de apoio ao cliente cliente para fornecer provas.",
    "",
    "3. Serviços adicionais com base na leitura do estado do telemóvel e informações de identidade: recolheremos o seu IMEI, o endereço MAC do dispositivo, o número de série do dispositivo, o ID Android e a lista de aplicações instaladas depois de ativar as permissões e confirmar a sua conta com base na segurança do seu dispositivo , alcançar o controlo dos riscos de segurança ",
    "",
    "4. Funções adicionais baseadas na visualização de ligações WLAN: Visualize o estado da ligação wifi para garantir a fiabilidade da rede.",
    "",
    "",
    "5. Serviços adicionais baseados no acesso e carregamento de imagens de álbuns de fotografias (biblioteca de imagens), funções de armazenamento externo, cache e publicação de imagens: pode utilizar esta função para carregar as suas fotografias/imagens, comentar/partilhar e tirar fotografias após ligar as permissões do álbum. Fornecemos a funcionalidade de prova ao fazer compras ou comunicar com o serviço de apoio ao cliente. Os bens ou serviços que necessita de adquirir serão identificados através das fotos/imagens que carregar, ou serão utilizadas as informações de avaliação que contenham as fotos ou imagens que carregar. . função, mas não afetará a sua utilização normal de [########].",
    "",
    "6. Serviços adicionais baseados em aplicações instaladas: A plataforma irá atualizar regularmente o cliente da aplicação. Ao atualizar, pode abrir a APP para atualizar, instalar e utilizar diretamente a nova versão.",
    "",
    "7. Serviços adicionais baseados na obtenção de informações pela aplicação sobre as tarefas em execução no momento: A plataforma julgará a página da APP ao contar os dados da APP.",
    "",
    "8. Serviços adicionais baseados em manter a APP ativa: Quando utiliza esta aplicação, os dados serão sincronizados automaticamente em segundo plano.",
    "",
    "9. Para lhe fornecer de forma mais abrangente as informações de produtos ou serviços de que necessita e para lhe fornecer serviços personalizados ou não personalizados, extrairemos as suas informações com base nos seus registos de navegação e pesquisa, informações de dispositivos, informações de localização e informações de pedidos. Características de navegação, preferências de pesquisa, hábitos comportamentais e informações de localização””,",
    "",
    "Compreende e concorda que os serviços adicionais acima referidos exigem que ative as suas informações de localização no seu dispositivo (localização geográfica), câmara fotográfica (câmara fotográfica), álbum de fotografias (biblioteca de imagens), calendário e armazenamento externo para realizar a recolha e utilização das informações envolvidas nestas permissões recolher e utilizar informações pessoais relevantes para lhe prestar os serviços correspondentes, Depois de fechar qualquer permissão, cancelou a autorização. Não continuaremos a recolher e a utilizar informações pessoais relevantes com base na permissão correspondente e não poderemos prestar-lhe os serviços correspondentes à permissão. e utilização prévia de informações com base na sua autorização ",
    "",
    "(7) Outros",
    "",
    "1. Se as informações fornecidas contiverem informações pessoais de outros utilizadores, terá de garantir que obteve autorização legal antes de fornecer tais informações pessoais a [########]. Se envolver informações pessoais de crianças, precisa de obter o consentimento do responsável da criança correspondente antes de publicar. Nas circunstâncias acima referidas, o responsável tem o direito de nos contactar através dos canais do Artigo 10 desta política para solicitar a correção ou eliminação de conteúdos que envolvam informações pessoais de crianças.",
    "",
    "2. Se utilizarmos as informações para outros fins não especificados nesta política, ou utilizarmos as informações recolhidas para uma finalidade específica para outros fins, ou se obtivermos ativamente as suas informações pessoais de terceiros, iremos obtê-las antecipadamente. O seu consentimento.",
    "",
    "Se obtivermos as suas informações indiretamente de terceiros, solicitaremos claramente ao terceiro, por escrito, antes da recolha, que recolha informações pessoais após obter o seu consentimento de acordo com a lei, e informá-lo-emos sobre o conteúdo das informações partilhadas, e se envolve informações confidenciais . Prometemos que se um terceiro cometer uma violação, exigiremos claramente que a outra parte assuma as responsabilidades legais correspondentes, ao mesmo tempo, reforçaremos a segurança das informações pessoais (incluindo relatórios de informações confidenciais, armazenamento encriptado de informações confidenciais e; controlo de acesso). Protegeremos as informações pessoais obtidas indiretamente com os mesmos métodos e medidas de proteção que protegemos as informações pessoais dos nossos próprios utilizadores.",
    "",
    "3. Exceções com consentimento autorizado",
    "",
    "Compreende e concorda plenamente que a nossa recolha e utilização das suas informações pessoais não requerem a sua autorização e consentimento nas seguintes circunstâncias, e não responderemos aos seus pedidos de correção/modificação, eliminação, cancelamento, retirada de consentimento ou pedido de informação:",
    "",
    "(1) Relacionado com a segurança nacional e a segurança da defesa nacional;",
    "",
    "(2) Relevante para a segurança pública, a saúde pública e os principais interesses públicos;",
    "",
    "(3) Aplicação da lei judicial ou administrativa relacionada com a investigação criminal, acusação, julgamento e execução de sentenças;",
    "",
    "(4) Para proteger os principais direitos e interesses legítimos seus ou de outros indivíduos relacionados com a vida e a propriedade, mas é difícil obter o meu consentimento;",
    "",
    "(5) Informações pessoais que divulga ao público por conta própria;",
    "",
    "(6) As informações pessoais são recolhidas de informações legalmente divulgadas publicamente, tais como relatórios de notícias jurídicas e canais de divulgação de informações governamentais.",
    "",
    "(7) Conforme necessário para assinar e executar acordos relevantes ou outros documentos escritos consigo;",
    "",
    "(8) Necessário para manter o funcionamento seguro e estável dos produtos e/ou serviços fornecidos, e descobrir e tratar falhas de produtos e/ou serviços;",
    "",
    "(9) Necessário para reportagens legítimas;",
    "",
    "(10) Quando for necessário que uma instituição de investigação académica realize investigação estatística ou académica com base em interesses públicos, e ao fornecer externamente os resultados de investigação académica ou descrições, as informações pessoais contidas nos resultados serão desidentificadas;",
    "",
    "(11) Outras situações previstas nas leis e regulamentos.",
    "",
    "Informamos que, de acordo com as leis aplicáveis, se tomarmos as medidas técnicas e outras medidas necessárias para processar informações pessoais de modo a que o destinatário dos dados não possa reidentificar um indivíduo específico e não possa recuperá-lo, ou conduziremos a desidentificação das informações recolhidos, análise estatística e previsão, utilizados para melhorar [. ########], fornecer suporte de produto ou serviço para decisões de negócio e melhorar os nossos produtos e serviços (incluindo a utilização de dados anónimos para aprendizagem automática ou treino de algoritmo de modelo), então esses dados processados ​​​​A utilização não requer notificação adicional para si e para o seu consentimento",
    "",
    "4. Se deixarmos de operar produtos ou serviços [########], deixaremos imediatamente de recolher as suas informações pessoais e notificá-lo-emos da cessação das operações sob a forma de entrega ou de anúncios um a um, e Se as informações pessoais que mantemos relacionadas com o negócio encerrado envolverem informações pessoais de crianças, notificaremos imediatamente os responsáveis ​​pelas crianças sobre a suspensão das operações",
    "",
    "2. Como utilizamos cookies",
    "Para lhe proporcionar uma experiência de acesso mais fácil e recomendar conteúdos do seu interesse, armazenaremos um pequeno ficheiro de dados denominado Cookie no seu dispositivo móvel. Os cookies contêm frequentemente identificadores, nomes de sites e alguns números e caracteres. Com a ajuda de cookies , podemos armazenar informações da sua conta, registos de produtos, registos de encomendas e dados de produtos",
    "",
    "Se não concorda com o armazenamento de pequenos ficheiros de dados de cookies no seu dispositivo móvel, pode deixar de utilizar [########].",
    "",
    "3. Como partilhamos, transferimos e divulgamos publicamente as suas informações",
    "(1) Partilha",
    "",
    "Não partilharemos as suas informações pessoais com empresas, organizações e indivíduos que não sejam [########] prestadores de serviços, exceto nas seguintes circunstâncias:",
    "",
    "1. Partilha em circunstâncias legais: Partilharemos as suas informações pessoais externamente de acordo com as leis e regulamentos, litígios, necessidades de resolução de litígios ou de acordo com os requisitos das autoridades administrativas e judiciais de acordo com a lei.",
    "",
    "2. Partilha com consentimento explícito: Após obtermos o seu consentimento explícito, partilharemos as suas informações pessoais com outras partes.",
    "",
    "3. Partilha quando escolhe ativamente: Quando compra bens ou serviços através da plataforma [########], partilharemos as informações necessárias relacionadas com a transação nas informações do seu pedido com fornecedores de bens ou serviços relacionados para satisfazer às suas necessidades de transação e serviço pós-venda ",
    "",
    "4. Partilha com empresas afiliadas: Para nos facilitar o fornecimento de produtos e serviços com base na conta da plataforma [########], recomende informações do seu interesse, identifique anormalidades nas contas de utilizadores registados, e proteja [### #####】A segurança pessoal e patrimonial das empresas afiliadas ou de outros utilizadores ou do público está protegida contra violações. As informações serão partilhadas com os nossos afiliados e/ou os seus prestadores de serviços designados. solicitados novamente ",
    "",
    "5. Partilha com parceiros autorizados: Confiamos aos parceiros autorizados o fornecimento de determinados serviços ou a execução de funções em nosso nome. Apenas partilharemos as suas informações para os fins legais, legítimos, necessários, específicos e claros declarados nesta política de privacidade. Autorizado os parceiros apenas terão acesso à informação necessária ao desempenho das suas funções e não poderão utilizar essa informação para qualquer outra finalidade.",
    "",
    "Atualmente, os nossos parceiros autorizados incluem os seguintes tipos:",
    "",
    "(1) Comerciantes, prestadores de serviços e outros parceiros. Enviamos informações a comerciantes, prestadores de serviços e outros parceiros que apoiam o nosso negócio. Estes suportes incluem serviços de infraestruturas técnicas que nos são confiados para fornecer, análise dos nossos serviços Utilizar métodos , prestar atendimento ao cliente , conveniência de pagamento ou realizar pesquisas e pesquisas académicas.",
    "",
    "A fim de garantir o funcionamento estável e a realização de funções do nosso cliente, para que possa utilizar e usufruir de mais serviços e funções, a nossa aplicação incorporará o SDK de parceiros autorizados. Forneceremos interfaces de programação de aplicações para os parceiros autorizados obterem informações relevantes . (API) e kit de desenvolvimento de ferramentas de software (SDK) passam por testes de segurança rigorosos e concordam com medidas rigorosas de proteção de dados com parceiros autorizados para processar informações pessoais de acordo com esta política e quaisquer outras medidas relevantes de confidencialidade e segurança ",
    "",
    "Os detalhes são os seguintes:",
    "",
    " ",
    "Nome do SDK: KTPsdk",
    "",
    "Fabricante: Shenzhen Jintaiyi Electronics Co., Ltd.",
    "",
    "Tipo de informação de recolha: Obtenha a lista Bluetooth do telemóvel e verifique os dispositivos Bluetooth em redor do telemóvel",
    "",
    "Finalidade de utilização: Utilizado para ler a lista de Bluetooth num dispositivo específico, ligar-se ao Bluetooth e enviar informações via Bluetooth.",
    "(Equipamento específico: Terminal POS sem fios produzido por Shenzhen Jintaiyi Electronics Co., Ltd.)",
    "",
    "Permissões sensíveis do sistema utilizadas: permissão de digitalização Bluetooth, permissão de ligação Bluetooth, permissão de posicionamento.",
    "",
    "Cenários de utilização específicos: ligação a Bluetooth, permissão de ligação Bluetooth. Digitalização Bluetooth próxima, permissão de digitalização Bluetooth, permissão de posicionamento.",
    "",
    "",
    "",
    "Nome do SDK: flutter_bluetooth_serial",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação de recolha: Obtenha a lista Bluetooth do telemóvel e verifique os dispositivos Bluetooth em redor do telemóvel",
    "",
    "Finalidade de utilização: ler a lista de dispositivos Bluetooth, ligar ao Bluetooth e enviar informação via Bluetooth.",
    "",
    "Permissões sensíveis do sistema utilizadas: permissão de digitalização Bluetooth, permissão de ligação Bluetooth, permissão de posicionamento.",
    "",
    "Cenários de utilização específicos: ligação a Bluetooth, permissão de ligação Bluetooth. Digitalização Bluetooth próxima, permissão de digitalização Bluetooth, permissão de posicionamento.",
    "",
    "",
    "Nome do SDK: image_picker",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação recolhida: Obter permissões de armazenamento de leitura e escrita no telemóvel, obter permissões de utilização da câmara",
    "",
    "Objectivo: Escolher imagens da biblioteca de imagens e tirar novas fotografias com a câmara.",
    "",
    "Permissões confidenciais do sistema utilizadas: permissões de leitura e escrita de armazenamento externo, permissões de câmara",
    "",
    "Cenários de utilização específicos: definições, modificação de funções de fotografias de produtos utilizando permissões de câmaras, permissões de armazenamento.",
    "",
    "",
    "",
    "Nome do SDK: vibração",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação de recolha: Obter permissão de vibração do telemóvel",
    "",
    "Finalidade de utilização: Vibrar o telefone após digitalizar o código QR com sucesso.",
    "",
    "Permissões sensíveis do sistema utilizadas: permissão de vibração do telefone",
    "",
    "Cenário de utilização específica: após a leitura bem-sucedida do código QR, o telefone vibra e as permissões são vibradas.",
    "",
    "",
    "",
    "Nome do SDK: qr_code_scanner",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação de recolha: Obter permissão de utilização da câmara",
    "",
    "Finalidade de utilização: Digitalizar códigos QR e códigos de barras.",
    "",
    "Utilizar permissões confidenciais do sistema: permissões da câmara",
    "",
    "Cenários de utilização específicos: Digitalize o código de barras do produto e obtenha acesso à câmara. Digitalize o código QR do documento e obtenha acesso à câmara.",
    "",
    "",
    "",
    "Nome do SDK: path_provider",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação recolhida: Obter permissões de armazenamento de leitura e escrita no telemóvel",
    "",
    "Objectivo: Utilizado para encontrar locais comuns no sistema de ficheiros.",
    "",
    "Permissões confidenciais do sistema utilizadas: permissões de leitura e escrita de armazenamento externo",
    "",
    "Cenários de utilização específicos: obter o endereço de armazenamento externo da APP, ler e escrever permissões de armazenamento externo, criar uma pasta no endereço de armazenamento externo, ler e gravar permissões de armazenamento externo.",
    "",
    "",
    "Nome do SDK: open_filex",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação recolhida: Obter permissões de armazenamento de leitura e escrita no telemóvel",
    "",
    "Finalidade de utilização: utilizado para abrir o ficheiro especificado no sistema de ficheiros.",
    "",
    "Permissões confidenciais do sistema utilizadas: permissões de leitura e escrita de armazenamento externo",
    "",
    "Cenário de utilização específica: após descarregar o pacote de instalação da atualização de software para o armazenamento externo, abra o ficheiro para concluir a atualização e tenha permissões de leitura e escrita no armazenamento externo.",
    "",
    "",
    "",
    "Nome do SDK: flutter_downloader",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação recolhida: Obter permissão de armazenamento de leitura e escrita no telemóvel, obter permissão de notificação no telemóvel",
    "",
    "Finalidade de utilização: Plug-in para criação e gestão de tarefas de download.",
    "",
    "Permissões confidenciais do sistema utilizadas: permissões de leitura e escrita de armazenamento externo, obtenção de permissões de notificação de telemóvel",
    "",
    "Cenários de utilização específicos: armazenamento externo de downloads de software, atualizações e pacotes de instalação, permissões de leitura e escrita de armazenamento externo, barra de notificações que mostra informações e progresso do download, permissões de notificação.",
    "",
    "",
    "",
    "Nome do SDK: permission_handler",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação recolhida: Sem requisitos de permissão específicos",
    "",
    "Finalidade de utilização: Plug-in para gestão e pedido de permissões de aplicações.",
    "",
    "Permissões confidenciais do sistema utilizadas: relacionadas com a permissão solicitada (por exemplo, câmara, localização, contactos)",
    "",
    "Cenários de utilização específicos: Solicitar autorização ao utilizador para aceder a funções do dispositivo (como câmara, localização), verificar e gerir o estado de permissão.",
    "",
    "",
    "Nome do SDK: share_plus",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação recolhida: Sem requisitos de permissão específicos",
    "",
    "Finalidade de utilização: Plug-in para partilha de conteúdos dentro da aplicação.",
    "",
    "Permissões confidenciais do sistema utilizadas: nenhuma permissão confidencial específica",
    "",
    "Cenários de utilização específicos: os utilizadores podem partilhar textos, imagens, ficheiros e outros conteúdos com outras aplicações ou plataformas através de plug-ins, como redes sociais, aplicações de mensagens ou clientes de e-mail.",
    "",
    "",
    "",
    "Nome do SDK: câmara",
    "",
    "Fabricante: Plug-in de código aberto de terceiros",
    "",
    "Tipo de informação recolhida: Sem requisitos de permissão específicos",
    "",
    "Finalidade de utilização: Plug-in para aceder e controlar a câmara do dispositivo dentro de uma aplicação.",
    "",
    "Permissão de sistema sensível utilizada: permissão de câmara (para acesso à câmara do dispositivo)",
    "",
    "Cenários de utilização específicos: tirar fotografias, gravar vídeos, visualizar o conteúdo da câmara, etc., geralmente utilizados em aplicações que requerem funções de câmara.",
    "",
    "",
    "",
    " ",
    "Definição do campo de informação pessoal e lista exaustiva",
    "",
    "Endereço MAC do dispositivo: O endereço MAC do dispositivo, o endereço que confirma a localização do dispositivo móvel",
    "",
    "Código de identificação único do dispositivo: IDFA para dispositivos iOS, IMEI, oaid, AndroidId, UUID para dispositivos Android (serão recolhidos vários códigos de identificação para Android e superiores)",
    "",
    "Endereço IP do dispositivo: Mobile Internet Link Protocol, confirma o serviço do operador de ligação de rede",
    "",
    "Informações do dispositivo: marca do dispositivo, modelo do dispositivo, sistema operativo, versão do sistema operativo",
    "",
    "Informação de rede: ambiente de rede atual (como WIFI, 5G, 4G, 3G, 2G)",
    "",
    "",
    "(2) Transferência",
    "",
    "Não transferiremos as suas informações pessoais para qualquer empresa, organização ou indivíduo, exceto nas seguintes circunstâncias:",
    "",
    "1. Transferência com consentimento explícito: Após obter o seu consentimento explícito, transferiremos as suas informações pessoais para outras partes;",
    "",
    "2. Quando o prestador de serviços [########] passar por uma fusão, aquisição ou liquidação por falência, ou outras situações que envolvam fusões, aquisições ou liquidação por falência, se a transferência de informações pessoais estiver envolvida , exigiremos novas As empresas e organizações que detêm as suas informações pessoais continuarão sujeitas a esta política, caso contrário, exigiremos que a empresa, organização e indivíduo obtenham novamente a sua autorização e consentimento",
    "",
    "(3) Divulgação pública",
    "",
    "Só divulgaremos publicamente as suas informações pessoais nas seguintes circunstâncias:",
    "",
    "1. Divulgaremos publicamente as suas informações pessoais com o seu consentimento explícito ou com base na sua escolha ativa;",
    "",
    "2. Se determinarmos que violou leis e regulamentos ou violou gravemente [########] acordos e regras relacionados com a plataforma, ou para proteger [########] os utilizadores da plataforma ou o público Para proteger a segurança pessoal e patrimonial contra infrações, divulgamos as suas informações pessoais de acordo com as leis e regulamentos ou com o seu consentimento, incluindo violações relevantes e as medidas que a plataforma [########] tomou contra si. ",
    "",
    "(4) Exceções à obtenção de autorização e consentimento prévio ao partilhar, transferir ou divulgar publicamente informações pessoais",
    "",
    "Nas seguintes circunstâncias, a partilha, transferência e divulgação pública das suas informações pessoais não requer a sua autorização e consentimento prévios:",
    "",
    "1. Relacionado com a segurança nacional e a segurança da defesa nacional;",
    "",
    "2. Relevante para a segurança pública, a saúde pública e os principais interesses públicos;",
    "",
    "3. Relevantes para a investigação criminal, a ação penal, o julgamento e a execução de sentenças, a aplicação da lei judicial ou administrativa;",
    "",
    "4. Para proteger os principais direitos e interesses legítimos seus ou de outros indivíduos relacionados com a vida e a propriedade, mas é difícil obter o meu consentimento;",
    "",
    "5. Informações pessoais que divulga ao público por conta própria;",
    "",
    "6. Recolher informações pessoais de informações legalmente divulgadas publicamente, tais como notícias jurídicas e divulgação de informações governamentais.",
    "",
    "Informamos que, de acordo com as leis aplicáveis, se tomarmos as medidas técnicas e outras medidas necessárias para processar informações pessoais de modo a que o destinatário dos dados não possa reidentificar um indivíduo específico e não possa recuperá-lo, a partilha, transferência e divulgação pública de tais dados tratados ​​​​Não é necessário qualquer aviso adicional e o seu consentimento",
    "",
    "4. Como armazenamos as suas informações",
    "(1) Local de armazenamento",
    "",
    "As informações pessoais que recolhemos e geramos durante as nossas operações serão armazenadas na região de alojamento do servidor. Nas seguintes circunstâncias, forneceremos as suas informações pessoais às entidades relevantes após cumprirmos as nossas obrigações nos termos da lei:",
    "",
    "1.As leis aplicáveis ​​estão claramente estipuladas;",
    "",
    "2. Obtenha a sua autorização explícita;",
    "",
    "3. A sua iniciativa pessoal na realização de transações transfronteiriças através da Internet.",
    "",
    "Em resposta às circunstâncias acima referidas, utilizaremos um contrato para garantir que as suas informações pessoais são protegidas a um nível não inferior ao especificado nesta política.",
    "",
    "(2) Período de armazenamento",
    "",
    "Tomaremos medidas razoáveis ​​e viáveis ​​para tentar o nosso melhor para evitar a recolha e o processamento de informações pessoais irrelevantes. Apenas conservaremos as suas informações pessoais durante o período necessário para atingir os objetivos declarados nesta política, a menos que a lei tenha requisitos de retenção obrigatórios, informação da transação O período de armazenamento não será inferior a três anos a partir da data de conclusão da transação.",
    "",
    "1. Concluir os fins de transação relacionados consigo, manter os registos de transações e negócios correspondentes e responder às suas dúvidas ou reclamações;",
    "",
    "2. Garantir a segurança e qualidade dos serviços que lhe prestamos;",
    "",
    "3. Concorda com um período de retenção mais longo;",
    "",
    "4. Existe algum outro acordo especial sobre o período de retenção?",
    "",
    "Após o período de retenção ser excedido, eliminaremos ou anonimizaremos as suas informações pessoais no prazo de 15 dias, de acordo com os requisitos das leis aplicáveis. Além disso, após cancelar a sua conta, deixaremos de lhe fornecer produtos e serviços no prazo de 15 dias, e de acordo com o seu pedido, as suas informações pessoais serão eliminadas ou anonimizadas, salvo disposição em contrário das leis e regulamentos",
    "",
    "5. Como protegemos as suas informações",
    "(1) Adotamos medidas de segurança razoáveis ​​e viáveis ​​que cumprem as normas da indústria para proteger as suas informações e evitar que as informações pessoais sejam acedidas não autorizadas, divulgadas publicamente, utilizadas, modificadas, danificadas ou perdidas. No seu navegador e servidor Ao trocar dados entre Proteção de encriptação de protocolo SSL; também fornecemos navegação segura de protocolo HTTPS para o site [########] utilizaremos tecnologia de encriptação para melhorar a segurança das informações pessoais sendo comprometidas por ataques maliciosos; acesso para garantir que apenas o pessoal autorizado pode aceder a informações pessoais",
    "",
    "",
    "",
    "(2) Tomaremos medidas razoáveis ​​e viáveis ​​para tentar o nosso melhor para evitar a recolha de informações pessoais irrelevantes. Apenas conservaremos as suas informações pessoais durante o período necessário para atingir os objetivos declarados nesta política, a menos que existam requisitos de retenção obrigatórios por lei.",
    "",
    "1. Concluir os fins de transação relacionados consigo, manter os registos de transações e negócios correspondentes e responder às suas dúvidas ou reclamações;",
    "",
    "2. Garantir a segurança e qualidade dos serviços que lhe prestamos;",
    "",
    "3. Concorda com um período de retenção mais longo;",
    "",
    "4. Existe algum outro acordo especial sobre o período de retenção?",
    "",
    "Depois de as suas informações pessoais excederem o período de conservação, eliminaremos ou anonimizaremos as suas informações pessoais de acordo com os requisitos das leis aplicáveis.",
    "",
    "(3) A Internet não é um ambiente absolutamente seguro. Quando utilizar os serviços da plataforma [########], recomendamos vivamente que não utilize métodos de comunicação que não sejam recomendados pelo [##### ###] plataforma para lhe enviar informações. Pode estabelecer ligações e partilhar entre si através dos nossos serviços. ou partilhar informações relacionadas com o conteúdo com terceiros ",
    "",
    "Ao utilizar serviços [########] para transações online, terá inevitavelmente de divulgar as suas informações pessoais, tais como informações de contacto ou endereço de contacto, à contraparte ou potencial contraparte. Por favor, proteja-se adequadamente. Os seus dados pessoais as informações só serão fornecidas a terceiros quando necessário. imediatamente para que possamos agir em conformidade. Tome as medidas adequadas na sua candidatura.",
    "",
    "Tenha em atenção que as informações que partilha voluntariamente ou mesmo publicamente ao utilizar os nossos serviços envolverão informações pessoais suas ou de terceiros ou mesmo informações pessoais confidenciais. Considere com mais cuidado se partilha ou mesmo partilha publicamente informações relevantes ao utilizar os nossos serviços. ",
    "",
    "Por favor, ajude-nos a garantir a segurança da sua conta utilizando uma palavra-passe complexa. Faremos o nosso melhor para garantir a segurança de qualquer informação que nos envie. Se as nossas instalações de proteção física, técnica ou administrativa forem violadas, resultando acesso não autorizado às informações , Se os seus direitos e interesses legítimos forem prejudicados devido à divulgação pública, adulteração ou destruição, assumiremos a responsabilidade legal correspondente ",
    "",
    "(4) Atualizaremos e divulgaremos conteúdo relevante sobre os riscos de segurança e os impactos na segurança das informações pessoais de tempos a tempos, que poderá obter através de anúncios [########].",
    "",
    "(5) Após a ocorrência de um infeliz incidente de segurança de informações pessoais, iremos informá-lo de acordo com os requisitos das leis e regulamentos: a situação básica e o impacto do incidente de segurança, as medidas de eliminação que tomamos ou tomaremos , e como pode prevenir e reduzir os riscos por conta própria Sugestões e medidas corretivas para si. um.",
    "",
    "",
    "6. Como gere as suas informações",
    "Pode aceder e gerir as suas informações das seguintes formas:",
    "",
    "(1) Consultar, corrigir e complementar a sua informação",
    "",
    "Tem o direito de consultar, corrigir ou complementar as suas informações. Pode fazê-lo sozinho das seguintes formas:",
    "",
    '1. Faça login no cliente [########], entre na página "Conta" e corrija as informações pessoais e informações relacionadas com a conta pessoal;',
    "2. Autorizar os gestores relevantes a fazer modificações",
    "",
    "(2) Apague as suas informações",
    "",
    "",
    "Pode solicitar-nos a eliminação das suas informações pessoais nas seguintes circunstâncias:",
    "",
    "1. Se o nosso tratamento de informações pessoais violar as leis e regulamentos;",
    "",
    "2. Se recolhermos e utilizarmos as suas informações pessoais sem o seu consentimento explícito;",
    "",
    "3. Se o nosso tratamento de informações pessoais violar gravemente o nosso acordo consigo;",
    "",
    "4. Se já não utiliza os nossos produtos ou serviços, ou se solicitar ativamente o cancelamento da sua conta;",
    "",
    "5.Se não fornecermos mais produtos ou serviços de forma permanente.",
    "",
    "Se decidirmos responder ao seu pedido de eliminação, também notificaremos os sujeitos que obtiveram as suas informações pessoais de nós e exigiremos que as apaguem em tempo útil (a menos que seja disposto de outra forma por leis e regulamentos, ou se esses sujeitos obtiverem independentemente as suas informação pessoal).",
    "",
    "Quando você ou nós o ajudarmos a eliminar informações relevantes, não poderemos eliminar imediatamente as informações correspondentes do sistema de cópia de segurança devido às leis e tecnologias de segurança aplicáveis. Armazenaremos em segurança as suas informações pessoais e isolá-las-emos de qualquer processamento posterior, até que o o backup pode ser apagado ou anonimizado.",
    "",
    "(3) Alterar o âmbito da sua autorização e consentimento",
    "",
    "Se optar por não nos fornecer as suas informações pessoais ou se recusar a utilizar alguns dos serviços que oferecemos, pode desativar algumas das permissões que autorizou para [########] configurando o seu dispositivo móvel inteligente",
    "",
    "Quando retirar o seu consentimento, deixaremos de tratar as informações pessoais correspondentes. No entanto, a sua decisão de retirar o seu consentimento não afetará o tratamento anterior de informações pessoais com base na sua autorização.",
    "",
    "(4) Cancele a sua conta",
    "",
    "Pode solicitar o cancelamento da sua conta através dos seguintes métodos:",
    "",
    "1. Pode contactar o seu administrador de back-end para cancelar a sua conta.",
    "",
    "Depois de cancelar a sua conta, deixaremos de fornecer bens ou serviços e eliminaremos ou anonimizaremos as suas informações pessoais de acordo com os requisitos das leis aplicáveis.",
    "",
    "(5) Tomada de decisão automática do sistema de informação restrita",
    "",
    "Em determinadas funções empresariais, tomamos decisões apenas com base em mecanismos de tomada de decisão automáticos não artificiais, incluindo sistemas de informação e algoritmos. Se estas decisões afetarem significativamente os seus direitos e interesses legítimos, tem o direito de solicitar uma explicação da nossa parte, e também forneceremos métodos de reclamação sem infringir os segredos comerciais de [########] ou outros direitos do utilizador e interesses públicos sociais",
    "",
    "(6) Responder ao seu pedido acima",
    "",
    "Por razões de segurança, terá de fornecer um pedido por escrito ou provar a sua identidade. Solicitaremos que verifique a sua identidade antes de processar o seu pedido.",
    "",
    "Responderemos no prazo de 15 dias. Se não estiver satisfeito, também pode iniciar uma reclamação através do serviço de apoio ao cliente [########].",
    "",
    "Em princípio, não cobramos taxas pelos seus pedidos razoáveis, mas por pedidos repetidos que excedam os limites razoáveis, cobraremos uma determinada taxa a nosso critério. Para informações que não estejam diretamente relacionadas com a sua identidade, informações repetidas sem motivo, ou necessidades Rejeitaremos os pedidos que exijam meios técnicos excessivos (exigindo o desenvolvimento de novos sistemas ou mudanças fundamentais nas práticas atuais), que representem riscos para os direitos e interesses legítimos de terceiros, ou que sejam irrealistas",
    "",
    "Nas seguintes circunstâncias, de acordo com os requisitos legais e regulamentares, não poderemos responder ao seu pedido:",
    "",
    "1. Relacionado com a segurança nacional e a segurança da defesa nacional;",
    "",
    "2. Relevante para a segurança pública, a saúde pública e os principais interesses públicos;",
    "",
    "3. Relevantes para a investigação criminal, ação penal, julgamento e execução de sentenças;",
    "",
    "4. Existem indícios suficientes de que o titular da informação pessoal cometeu dolo subjetivo ou abusou dos seus direitos;",
    "",
    "5. Responder ao seu pedido causará graves danos aos seus direitos e interesses legítimos ou a outros indivíduos ou organizações;",
    "",
    "6. Envolvendo segredos comerciais.",
    "",
    "",
    "7. Como as suas informações são transferidas globalmente",
    "",
    "1.As leis aplicáveis ​​estão claramente estipuladas;",
    "",
    "2. Obtenha a sua autorização explícita;",
    "",
    "3. A sua iniciativa pessoal na realização de transações transfronteiriças através da Internet.",
    "",
    "Tendo em conta as circunstâncias acima referidas, asseguraremos que as suas informações pessoais são adequadamente protegidas de acordo com esta política de privacidade.",
    "",
    "8. Como atualizar esta política de privacidade",
    "Alterações à nossa política de privacidade.",
    "",
    "Não limitaremos os seus direitos ao abrigo da presente Política de Privacidade sem o seu consentimento explícito. Publicaremos atualizações da Política de Privacidade na Aplicação.",
    "",
    "Para alterações importantes, também forneceremos notificações mais proeminentes (incluindo notificações através de anúncios [########] ou até mesmo fornecendo avisos pop-up).",
    "",
    "As principais alterações referidas nesta política incluem, mas não estão limitadas a:",
    "",
    "1. O nosso modelo de serviço sofreu grandes alterações. Como a finalidade do processamento de informações pessoais, os tipos de informações pessoais processadas e a utilização de informações pessoais;",
    "",
    "2. Temos grandes mudanças no controlo. Como alterações nos controladores de informação causadas por fusões, aquisições e reorganizações;",
    "",
    "3. Os principais objetos para os quais as informações pessoais são partilhadas, transferidas ou divulgadas publicamente mudam;",
    "",
    "4. Alterações significativas nos seus direitos de participação no tratamento de informações pessoais e na forma como as exerce;",
    "",
    "5. Os nossos departamentos responsáveis, informações de contacto e canais de reclamação para lidar com a segurança das informações pessoais mudaram;",
    "",
    "6. O relatório de avaliação de impacto na segurança da informação pessoal indica que existe um risco elevado.",
    "",
    "9. Como contactar-nos",
    "Pode contactar-nos através dos seguintes métodos e responderemos ao seu pedido no prazo de 15 dias:",
    "",
    "1. Se tiver alguma dúvida, comentário ou sugestão sobre o conteúdo desta política, por favor contacte-nos através do e-mail de apoio ao cliente [########]: [support@########.com]",
    "2.Se descobrir que as suas informações pessoais foram divulgadas, pode contactar a nossa equipa de atendimento ao cliente para fazer uma reclamação;",
    "",
    "Se não estiver satisfeito com a nossa resposta, especialmente se acreditar que o nosso comportamento de tratamento de informações pessoais prejudicou os seus direitos e interesses legítimos, também pode procurar uma solução, apresentando uma ação judicial no tribunal com jurisdição sobre o domicílio do réu.",
  ],
};
