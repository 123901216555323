export const datas = {
  en_GB: [
"######## User Agreement",
"Before registering ######## to become a user, please read this User Agreement (hereinafter referred to as the \"Agreement\") carefully to ensure that you fully understand the terms of this Agreement. Please read carefully and choose to accept or not accept this Agreement. Unless you accept all the terms of this Agreement, you have no right to register, log in or use the services involved in this Agreement. Your registration, login, use and other behaviors will be deemed as acceptance of this Agreement, and you agree to be bound by the terms of this Agreement.",
"This Agreement stipulates the rights and obligations of this platform and user software services (hereinafter referred to as \"Services\"). \"User\" refers to an individual who registers, logs in, and uses this platform. This Agreement may be updated by this platform at any time. Once the updated terms of the Agreement are announced, they will replace the original terms of the Agreement without further notice. Users can check the latest version of the terms of the Agreement on this platform. After modifying the terms of the Agreement, if the user does not accept the modified terms, please stop using the services provided by this platform immediately. The user's continued use of the services provided by this platform will be deemed to accept the modified agreement.",
"I. Account Registration",
"1. Users may need to register an account when using this platform. This platform may change the account registration and binding method according to user needs or product needs without prior notice to users.",
"2. In view of the account binding registration method, you agree to use the mobile phone number, email and automatic extraction of your mobile device identification code and other information provided by you for registration.",
"II. Service Content",
"The specific functions of this platform are provided according to actual conditions, and the service content provided may change at any time.",
"III. User Personal Privacy Information Protection",
"1. When registering an account or using this platform, users may need to fill in or submit some necessary information, such as identity information required by laws, regulations, and regulatory documents (hereinafter referred to as \"laws and regulations\"). If the information submitted by the user is incomplete or does not comply with the provisions of laws and regulations, the user may not be able to use this platform or be restricted in the process of using this platform.",
"2. Personal privacy information refers to information related to the user's personal identity or personal privacy, such as the user's real name, ID number, mobile phone number, mobile device identification code, and IP address. Non-personal privacy information refers to the basic record information of the user's operation status and usage habits of this platform that is clearly and objectively reflected on the server side of this platform, other general information outside the scope of personal privacy information, and the above privacy information that the user agrees to disclose.",
"3. Respecting the privacy of users' personal privacy information is a consistent system of this platform. This platform will take technical measures and other necessary measures to ensure the security of users' personal privacy information and prevent the leakage, damage or loss of users' personal privacy information collected on this platform. When the aforementioned situation occurs or this platform finds that there is a possibility of the aforementioned situation, it will take remedial measures in a timely manner.",
"4. This platform will not disclose or reveal users' personal privacy information to any third party without the user's consent. However, the following specific circumstances are excluded:",
"(1) Providing the user's personal privacy information in accordance with the provisions of laws and regulations or the instructions of the competent authorities;",
"(2) Any leakage of personal information caused by the user telling his or her user password to others or sharing the registered account and password with others, or other leakage of personal privacy information not caused by this platform;",
"(3) The user discloses his or her personal privacy information to a third party on his or her own initiative;",
"(4) The user and this platform and its partners have reached an agreement on the use and disclosure of the user's personal privacy information, and this platform therefore discloses the user's personal privacy information to its partners;",
"(5) Any leakage of the user's personal privacy information caused by hacker attacks, computer virus intrusions and other force majeure events. ",
"5. The user agrees that this platform may use the user's personal privacy information in the following matters:",
"(1) This platform sends important notifications or reminders to users in a timely manner, such as the software will use the user's personal privacy information for any other purpose. ",
"6. This platform attaches great importance to the protection of minors' personal privacy information. It will rely on the personal information provided by the user to determine whether the user is a minor. ",
"7. Users confirm that their geographic location information is non-personal privacy information. Users who successfully register an account are deemed to have confirmed their authorization to this platform to extract, disclose and use the user's geographic location information. ",
"8. In order to improve technology and services and provide users with a better service experience, this platform may collect and use users' non-personal privacy information or provide it to third parties. ",
"IV. Content Standards",
"1. The content referred to in this article refers to any content produced, uploaded, copied, published, and disseminated by users in the process of using this platform, including but not limited to account avatars, names, user descriptions and other registration information and authentication materials, or text, voice, pictures, videos, graphics, etc., replies or automatic replies to messages and related link pages, as well as other content generated by using accounts or this platform. ",
"2. Users shall not use their accounts or this platform to produce, upload, copy, publish, or disseminate content prohibited by laws, regulations and policies:",
"(1) Opposing the basic principles established by the Constitution;",
"(2) Endangering national security, leaking state secrets, subverting the state power, and undermining national unity;",
"",
"Modification and Termination",
"Modification",
"This Agreement is subject to change. If there are any substantial changes to this Agreement, we will notify you by email. After the change notification, continued use means that you have been informed of such changes and agree to be bound by the terms;",
"",
"We reserve the right to modify, retain or close any service at any time without notice;",
"",
"You have agreed that we do not have the right to modify, retain or close any service;",
"",
"You have agreed that we do not have the right to modify, retain or close any service;",
"",
"You have agreed that we do not have to be liable to you or any third party for modifying, retaining or closing any service. ",
"",
"Termination",
"This Agreement shall take effect from the date of your acceptance and shall remain effective during your use until terminated in accordance with this Agreement;",
"",
"Notwithstanding the above provisions, if you use the product earlier than the time you accept this Agreement, you hereby acknowledge and agree that this Agreement shall take effect when you first use the product, unless terminated earlier in accordance with this Agreement;",
"",
"We may reserve your right to use or this account in accordance with the law; we will terminate this Agreement at any time for any reason, whether or not notified, including in good faith that you have violated our Acceptable Use Policy or other provisions of this Agreement;",
"",
"Not limited to the preceding paragraph, if a user infringes the copyright of a third party and we receive a notice from the copyright owner or the copyright owner's legal agent, we reserve the right to terminate this Agreement;",
"",
"Once this Agreement is terminated, your right to use the product shall terminate. You should be aware that the termination of your product means that your user content will be deleted from our active database. We shall not be liable to you for any termination of this Agreement, including termination of your user account and deletion of your user content. ",
    ],
  zh_CN: [
    "########用户协议",
    "在注册########成为用户之前，请认真阅读本用户协议（以下简称“协议”），确保您充分理解本协议中各条款。请您审慎阅读并选择接受或不接受本协议。除非您接受本协议所有条款，否则您无权注册、登录或使用本协议所涉服务。您的注册、登录、使用等行为将视为对本协议的接受，并同意接受本协议各项条款的约束。",
    "本协议约定本平台与用户软件服务（以下简称“服务”）的权利义务。“用户”是指注册、登录、使用本平台的个人。本协议可由本平台随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，用户可在本平台查阅最新版协议条款。在修改协议条款后，如果用户不接受修改后的条款，请立即停止使用本平台提供的服务，用户继续使用本平台提供的服务将被视为接受修改后的协议。",
    "一、账号注册",
    "1、用户在使用本平台时可能需要注册一个账号。本平台可以根据用户需求或产品需要对账号注册和绑定的方式进行变更，而无须事先通知用户。",
    "2、鉴于账号的绑定注册方式，您同意在注册时将使用您提供的手机号码、邮件及自动提取您的手机设备识别码等信息用于注册。",
    "二、服务内容",
    "本平台的具体功能根据实际情况提供，提供的服务内容可能随时变更。",
    "三、用户个人隐私信息保护",
    "1、用户在注册账号或使用本平台的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不完整或不符合法律法规的规定，则用户可能无法使用本平台或在使用本平台的过程中受到限制。",
    "2、个人隐私信息是指涉及用户个人身份或个人隐私的信息，比如，用户真实姓名、身份证号、手机号码、手机设备识别码、IP地址。非个人隐私信息是指用户对本平台的操作状态以及使用习惯等明确且客观反映在本平台服务器端的基本记录信息、个人隐私信息范围外的其它普通信息，以及用户同意公开的上述隐私信息。",
    "3、尊重用户个人隐私信息的私有性是本平台的一贯制度，本平台将采取技术措施和其他必要措施，确保用户个人隐私信息安全，防止在本平台中收集的用户个人隐私信息泄露、毁损或丢失。在发生前述情形或者本平台发现存在发生前述情形的可能时，将及时采取补救措施。",
    "4、本平台未经用户同意不向任何第三方公开、 透露用户个人隐私信息。但以下特定情形除外：",
    "(1)根据法律法规规定或有权机关的指示提供用户的个人隐私信息；",
    "(2) 由于用户将其用户密码告知他人或与他人共享注册帐户与密码，由此导致的任何个人信息的泄漏，或其他非因本平台原因导致的个人隐私信息的泄露；",
    "(3) 用户自行向第三方公开其个人隐私信息；",
    "(4) 用户与本平台及合作方之间就用户个人隐私信息的使用公开达成约定，本平台因此向合作方公开用户个人隐私信息；",
    "(5) 任何由于黑客攻击、电脑病毒侵入及其他不可抗力事件导致用户个人隐私信息的泄露。",
    "5、用户同意本平台可在以下事项中使用用户的个人隐私信息：",
    "(1) 本平台向用户及时发送重要通知或提示，如软件会将用户个人隐私信息使用于任何其他用途。",
    "6、本平台重视对未成年人个人隐私信息的保护。将依赖用户提供的个人信息判断用户是否为未成年人。",
    "7、用户确认，其地理位置信息为非个人隐私信息，用户成功注册账号视为确认授权本平台提取、公开及使用用户的地理位置信息。",
    "8、为了改善技术和服务，向用户提供更好的服务体验，本平台可能会自行收集使用或向第三方提供用户的非个人隐私信息。",
    "四、内容规范",
    "1、本条所述内容是指用户使用本平台过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等、回复或自动回复消息和相关链接页面，以及其他使用账号或本平台所产生的内容。",
    "2、用户不得利用账号或本平台制作、上载、复制、发布、传播法律、法规和政策禁止的内容：",
    "(1) 反对宪法所确定的基本原则的；",
    "(2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；",
    "",
    "修改和终止",
    "修改",
    "本协议容许变更。如果本协议有任何实质性变更，我们将通过电子邮件来通知您。变更通知之后，继续使用则为您已知晓此类变更并同意条款约束；",
    "",
    "我们保留在任何时候无需通知而修改、保留或关闭任何服务之权利；",
    "",
    "您已同意我们无需因修改、保留或关闭任何服务之权利；",
    "",
    "您已同意我们无需因修改、保留或关闭任何服务的行为对您或第三方承担责任。",
    "",
    "终止",
    "本协议自您接受之日起生效，在您使用的过程中持续有效，直至依据本协议终止；",
    "",
    "尽管有上述规定，如果您使用的时间早于您接受本协议的时间，您在此知晓并同意本协议于您接受本协议的时间，您在此知晓并同意本协议于您第一次使用时生效，除非依据本协议提前终止；",
    "",
    "我们可能会依据法律的规定，保留您使用或者本账户的权利；无论是否通知，我们将在任何时间以任何原因终止本协议，包括出于善意的相信您违反了我们可接受使用政策或本协议的其他规定；",
    "",
    "不受前款规定所限，如果用户侵犯第三人的版权且我们接到版权所有人或版权所有人的合法代理人的通知后，我们保留终止本协议的权利；",
    "",
    "一旦本协议终止，您使用的权利即告终止。您应当知晓您的产品终止意味着您的用户内容将从我们的活动数据库中删除。我们不因终止本协议对您承担任何责任，包括终止您的用户账户和删除您的用户内容。",
  ],
  zh_TW: [
"########使用者協定",
"在註冊########成為使用者之前，請認真閱讀本使用者協議（以下簡稱「協議」），確保您充分瞭解本協議中各條款。請您審慎閱讀並選擇接受或不接受本協議。 除非您接受本協議所有條款，否則您無權註冊、登入或使用本協議所涉服務。的約束。",
"本協議約定本平台與使用者軟體服務（以下簡稱「服務」）的權利義務。「使用者」係指註冊、登入、使用本平台的個人。本協議可由本平台隨時更新，更新後的協議條款一旦公佈即代替原有的協議條款，恕不再另行通知，使用者可在本平台查閱最新版協議條款。視為接受修改後的協議。",
"一、帳號註冊",
"1、使用者在使用本平台時可能需要註冊一個帳號。本平台可以根據使用者需求或產品需要對帳號註冊和綁定的方式進行變更，而無須事先通知使用者。",
"2、鑑於帳號的綁定註冊方式，您同意在註冊時將使用您提供的手機號碼、郵件及自動提取您的手機設備識別碼等資訊用於註冊。",
"二、服務內容",
"本平台的具體功能依實際情況提供，提供的服務內容可能隨時變更。",
"三、使用者個人隱私資訊保護",
"1、使用者在註冊帳號或使用本平台的過程中，可能需要填寫或提交一些必要的信息，如法律法規、規章規範性文件（以下稱為“法律法規”）規定的需要填寫的身份信息。如使用者提交的資訊不完整或不符合法律法規的規定，則使用者可能無法使用本平台或在使用本平台的過程中受到限制。",
"2.個人隱私資訊是指涉及使用者個人身分或個人隱私的訊息，例如，使用者真實姓名、身分證字號、手機號碼、手機設備識別碼、IP位址。非個人隱私資訊是指使用者對本平台的操作狀態以及使用習慣等明確且客觀反映在本平台伺服器端的基本記錄信息、個人隱私信息範圍外的其它普通信息，以及用戶同意公開的上述隱私信息。",
"3、尊重使用者個人隱私資訊的私有性是本平台的一貫制度，本平台將採取技術措施和其他必要措施，確保使用者個人隱私資訊安全，防止在本平台中收集的使用者個人隱私資訊外洩、毀損或遺失。",
"4、本平台未經使用者同意不公開、 透露使用者個人隱私資訊。但以下特定情況除外：",
"(1)依法令規定或有權機關的指示提供使用者的個人隱私資訊；",
"(2) 由於使用者將其使用者密碼告知他人或與他人共享註冊帳戶與密碼，由此導致的任何個人資訊的洩漏，或其他非因本平台原因導致的個人隱私資訊的洩露；",
"(3) 用戶自行向第三方公開其個人隱私資訊；",
"(4) 用戶與本平台及合作方之間就用戶個人隱私資訊的使用公開達成約定，本平台因此向合作方公開用戶個人隱私資訊；",
"(5) 任何因駭客攻擊、電腦病毒侵入及其他不可抗力事件導致使用者個人隱私資訊的洩漏。",
"5、使用者同意本平台可在下列事項中使用使用者的個人隱私資訊：",
"(1) 本平台向使用者及時發送重要通知或提示，如軟體會將使用者個人隱私資訊使用於任何其他用途。",
"6、本平台重視對未成年人個人隱私資訊的保護。將依賴使用者提供的個人資訊判斷使用者是否為未成年人。",
"7.使用者確認，其地理位置資訊為非個人隱私訊息，使用者成功註冊帳號視為確認授權本平台擷取、公開及使用使用者的地理位置資訊。",
"8、為了改善技術和服務，提供使用者更好的服務體驗，本平台可能會自行收集使用或向第三方提供使用者的非個人隱私資訊。",
"四、內容規範",
"1、本條所述內容是指使用者使用本平台過程中所製作、上載、複製、發布、傳播的任何內容，包括但不限於帳號頭像、名稱、使用者說明等註冊資訊及認證資料，或文字、語音、圖片、影片、圖文等、回覆或自動回覆訊息及相關連結頁面，以及其他使用帳號或本平台所產生的內容。",
"2、使用者不得利用帳號或本平台製作、上載、複製、發布、傳播法律、法規及政策禁止的內容：",
"(1) 反對憲法所確定的基本原則的；",
"(2) 危害國家安全，洩漏國家秘密，顛覆國家政權，破壞國家統一的；",
"",
"修改和終止",
"修改",
"本協議容許變更。如果本協議有任何實質性變更，我們將透過電子郵件通知您。變更通知之後，繼續使用則為您已知曉此類變更並同意條款約束；",
"",
"我們保留在任何時候無須通知而修改、保留或關閉任何服務之權利；",
"",
"您已同意我們無須因修改、保留或關閉任何服務之權利；",
"",
"您已同意我們無須因修改、保留或關閉任何服務的行為對您或第三方承擔責任。",
"",
"終止",
"本協議自您接受之日起生效，在您使用的過程中持續有效，直至依據本協議終止；",
"",
"儘管有上述規定，如果您使用的時間早於您接受本協議的時間，您在此知曉並同意本協議於您接受本協議的時間，您在此知曉並同意本協議於您第一次使用時生效，除非依據本協議提前終止；",
"",
"我們可能會依據法律的規定，保留您使用或本帳戶的權利；無論是否通知，我們將在任何時間以任何原因終止本協議，包括出於善意的相信您違反了我們可接受使用政策或本協議的其他規定；",
"",
"不受前項規定所限，如果使用者侵犯第三人的版權且我們接到版權所有人或版權所有人的合法代理人的通知後，我們保留終止本協議的權利；",
"",
"一旦本協議終止，您使用的權利即告終止。您應知道您的產品終止意味著您的使用者內容將從我們的活動資料庫中刪除。我們不因終止本協議對您承擔任何責任，包括終止您的使用者帳號和刪除您的使用者內容。"
    ],
  fr_FR: [
"########Contrat d'utilisation",
"Avant de vous inscrire ######## pour devenir utilisateur, veuillez lire attentivement cet accord d'utilisation (ci-après dénommé \"l'accord\") pour vous assurer que vous comprenez parfaitement les termes de cet accord. Veuillez lire attentivement et choisir de accepter ou ne pas accepter cet accord. Sauf si vous acceptez tous les termes de cet accord, vous n'avez pas le droit de vous inscrire, de vous connecter ou d'utiliser les services couverts par cet accord. Votre inscription, votre connexion, votre utilisation, etc. être votre acceptation de cet accord et votre accord d'accepter les termes de cet accord ",
"Cet accord stipule les droits et obligations de cette plateforme et des services logiciels utilisateur (ci-après dénommés \"services\"). Les \"utilisateurs\" désignent les personnes qui s'inscrivent, se connectent et utilisent cette plateforme. Cet accord peut être mis à jour par cette plateforme. à tout moment. Une fois les termes de l'accord mis à jour, la publication remplacera les termes originaux de l'accord et ne les remplacera pas. Jusqu'à nouvel ordre, les utilisateurs peuvent consulter la dernière version des termes de l'accord sur cette plateforme. Après avoir modifié les termes de l'accord, si l'utilisateur n'accepte pas les conditions modifiées, veuillez cesser immédiatement d'utiliser les services fournis par cette plateforme. qui continuent à utiliser les services fournis par cette plateforme seront suspendus, ce qui vaudra acceptation de l'accord révisé.",
"1. Enregistrement du compte",
"1. Les utilisateurs peuvent avoir besoin de créer un compte lorsqu'ils utilisent cette plate-forme. Cette plate-forme peut modifier les méthodes d'enregistrement du compte et de liaison en fonction des besoins des utilisateurs ou des besoins du produit sans en informer les utilisateurs à l'avance.",
"2. Compte tenu de la méthode d'enregistrement contraignant le compte, vous acceptez d'utiliser le numéro de téléphone mobile, l'e-mail et d'extraire automatiquement le code d'identification de votre appareil de téléphone mobile et d'autres informations que vous avez fournies lors de votre inscription.",
"2. Contenu du service",
"Les fonctions spécifiques de cette plateforme sont fournies en fonction des conditions réelles et le contenu du service fourni peut changer à tout moment.",
"3. Protection des informations personnelles de la vie privée des utilisateurs",
"1. Au cours du processus d'enregistrement d'un compte ou d'utilisation de cette plateforme, les utilisateurs peuvent avoir besoin de remplir ou de soumettre certaines informations nécessaires, telles que des informations d'identité qui doivent être renseignées comme stipulé dans les lois, règlements et documents normatifs (ci-après dénommés comme \"lois et réglementations\"). Par exemple, si les informations soumises par l'utilisateur sont incomplètes ou ne sont pas conformes aux dispositions des lois et réglementations, l'utilisateur peut ne pas être en mesure d'utiliser cette plateforme ou peut être restreint dans le processus de en utilisant cette plateforme",
"2. Les informations personnelles sur la confidentialité font référence aux informations impliquant l'identité personnelle ou la vie privée de l'utilisateur, telles que le vrai nom de l'utilisateur, son numéro d'identification, son numéro de téléphone portable, le code d'identification de l'appareil de téléphone mobile et son adresse IP. Les informations non personnelles sur la confidentialité font référence à l'utilisateur. opérations sur cette plate-forme Le statut et les habitudes d'utilisation sont clairement et objectivement reflétés dans les informations d'enregistrement de base côté serveur de cette plate-forme, d'autres informations générales en dehors du champ des informations de confidentialité personnelles et les informations de confidentialité mentionnées ci-dessus que les utilisateurs acceptent de divulguer. ",
"3. Le respect de la confidentialité des informations personnelles des utilisateurs est un système cohérent de cette plate-forme. Cette plate-forme prendra des mesures techniques et d'autres mesures nécessaires pour garantir la sécurité des informations personnelles des utilisateurs et empêcher les fuites et les dommages aux données personnelles des utilisateurs. informations de confidentialité collectées sur cette plate-forme ou perdues. Lorsque la situation ci-dessus se produit ou que la plate-forme découvre qu'il existe une possibilité que la situation ci-dessus se produise, des mesures correctives seront prises rapidement ",
"4. Cette plateforme ne divulguera ni ne divulguera les informations personnelles des utilisateurs à des tiers sans le consentement de l'utilisateur. Cependant, les circonstances spécifiques suivantes sont exclues :",
"(1) Fournir les informations personnelles relatives à la confidentialité des utilisateurs conformément aux lois et réglementations ou aux instructions des autorités compétentes ;",
"(2) Toute fuite d'informations personnelles causée par le fait que les utilisateurs communiquent à d'autres leurs mots de passe d'utilisateur ou partagent des comptes enregistrés et des mots de passe avec d'autres, ou d'autres fuites d'informations personnelles non causées par cette plateforme ;",
"(3) Les utilisateurs divulguent eux-mêmes leurs informations personnelles à des tiers ;",
"(4) L'utilisateur a conclu un accord avec la plateforme et ses partenaires sur l'utilisation et la divulgation des informations personnelles de l'utilisateur, et la plateforme divulgue donc les informations personnelles de l'utilisateur au partenaire ;",
"(5) Toute fuite d'informations personnelles concernant la vie privée des utilisateurs en raison d'attaques de pirates informatiques, d'intrusions de virus informatiques et d'autres événements de force majeure.",
"5. L'utilisateur accepte que cette plateforme puisse utiliser les informations personnelles de l'utilisateur dans les domaines suivants :",
"(1) Cette plate-forme envoie des avis ou des rappels importants aux utilisateurs en temps opportun. Par exemple, le logiciel utilisera les informations personnelles des utilisateurs à toute autre fin.",
"6. Cette plateforme attache une grande importance à la protection des informations personnelles des mineurs. Elle s'appuiera sur les informations personnelles fournies par l'utilisateur pour déterminer si l'utilisateur est mineur.",
"7. L'utilisateur confirme que ses informations de localisation géographique sont des informations privées non personnelles. L'enregistrement réussi d'un compte par l'utilisateur est réputé confirmer que la plateforme est autorisée à extraire, divulguer et utiliser les informations de localisation géographique de l'utilisateur.",
"8. Afin d'améliorer la technologie et les services et de fournir aux utilisateurs une meilleure expérience de service, cette plate-forme peut collecter et utiliser elle-même les informations non personnelles relatives à la confidentialité des utilisateurs ou les fournir à des tiers.",
"4. Spécifications du contenu",
"1. Le contenu mentionné dans cet article fait référence à tout contenu produit, téléchargé, copié, publié et diffusé par les utilisateurs lors de leur utilisation de cette plateforme, y compris, mais sans s'y limiter, les avatars de compte, les noms, les descriptions d'utilisateurs et autres informations d'inscription et d'authentification. matériels, ou textes, voix, images, vidéos, graphiques, etc., messages de réponse ou de réponse automatique et pages de liens associées, et autres contenus générés par l'utilisation du compte ou de cette plateforme",
"2. Les utilisateurs ne peuvent pas utiliser leurs comptes ou cette plateforme pour créer, télécharger, copier, publier ou diffuser du contenu interdit par les lois, réglementations et politiques :",
"(1) S'opposer aux principes fondamentaux établis par la Constitution ;",
"(2) Mettre en danger la sécurité nationale, divulguer des secrets d'État, renverser le pouvoir de l'État et porter atteinte à l'unité nationale ;",
"",
"Modification et résiliation",
"Réviser",
"Cet accord autorise des modifications. S'il y a des modifications substantielles à cet accord, nous vous en informerons par e-mail. Après la notification de modification, la poursuite de l'utilisation signifie que vous êtes au courant de ces modifications et acceptez d'être lié par les conditions ;",
"",
"Nous nous réservons le droit de modifier, conserver ou fermer tout service à tout moment et sans préavis ;",
"",
"Vous acceptez que nous n'avons pas le droit de modifier, conserver ou fermer un service ;",
"",
"Vous acceptez que nous ne serons pas responsables envers vous ou des tiers de la modification, de la conservation ou de la fermeture de tout service.",
"",
"terminaison",
"Cet accord prendra effet à compter de la date à laquelle vous l'accepterez et continuera d'être en vigueur pendant votre utilisation jusqu'à sa résiliation conformément au présent accord ;",
"",
"Nonobstant les dispositions ci-dessus, si votre utilisation est antérieure au moment où vous acceptez le présent Contrat, vous reconnaissez et acceptez par la présente que le présent Contrat entrera en vigueur dès votre première utilisation à ce moment-là, à moins qu'il ne soit résilié plus tôt conformément au présent Contrat ;" ,
"",
"Nous pouvons réserver votre droit d'utiliser ce compte conformément aux dispositions de la loi ; nous résilierons cet accord à tout moment et pour quelque raison que ce soit avec ou sans préavis, y compris si vous pensez de bonne foi que vous avez violé notre utilisation acceptable. Politique ou cet accord Autres dispositions de l'accord ;",
"",
"Non limité par les dispositions du paragraphe précédent, si un utilisateur enfreint le droit d'auteur d'un tiers et que nous recevons une notification du propriétaire du droit d'auteur ou de l'agent légal du propriétaire du droit d'auteur, nous nous réservons le droit de résilier cet accord ;",
"",
"Une fois cet accord résilié, votre droit de l'utiliser prendra fin. Vous devez être conscient que la résiliation de votre produit signifiera que votre contenu utilisateur sera supprimé de notre base de données active. Nous n'aurons aucune responsabilité envers vous en cas de résiliation. du présent Contrat, y compris votre compte utilisateur et la suppression de votre contenu utilisateur ",
    ],
  es_ES: [
    "########Acuerdo de usuario",
    'Antes de registrarse ######## para convertirse en usuario, lea atentamente este Acuerdo de usuario (en lo sucesivo, el "Acuerdo") para asegurarse de que comprende completamente los términos de este Acuerdo. Lea atentamente y elija aceptar o no aceptar este Acuerdo A menos que acepte todos los términos de este Acuerdo, no tiene derecho a registrarse, iniciar sesión o utilizar los servicios cubiertos por este Acuerdo. Su registro, inicio de sesión, uso, etc. será su aceptación de este Acuerdo y su acuerdo de aceptar los términos de este Acuerdo ',
    'Este acuerdo estipula los derechos y obligaciones de esta plataforma y los servicios de software del usuario (en adelante, "servicios"). "Usuarios" se refiere a las personas que se registran, inician sesión y utilizan esta plataforma. Este acuerdo puede ser actualizado por esta plataforma. en cualquier momento una vez que se publiquen los términos del acuerdo actualizados, reemplazarán los términos originales del acuerdo y no lo harán. Hasta nuevo aviso, los usuarios pueden consultar la última versión de los términos del acuerdo en esta plataforma. Después de modificar los términos del acuerdo, si el usuario no acepta los términos modificados, deje de utilizar los servicios proporcionados por esta plataforma. que continúen utilizando los servicios proporcionados por esta plataforma se considerarán suspendidos como aceptación del acuerdo revisado.',
    "1. Registro de cuenta",
    "1. Es posible que los usuarios necesiten registrar una cuenta cuando utilicen esta plataforma. Esta plataforma puede cambiar el registro de la cuenta y los métodos vinculantes según las necesidades del usuario o del producto sin notificar a los usuarios por adelantado.",
    "2. En vista del método de registro vinculante de la cuenta, usted acepta utilizar el número de teléfono móvil, el correo electrónico y extraer automáticamente el código de identificación de su dispositivo móvil y otra información que proporcionó al registrarse.",
    "2. Contenido del servicio",
    "Las funciones específicas de esta plataforma se proporcionan en función de las condiciones reales y el contenido del servicio proporcionado puede cambiar en cualquier momento.",
    "3. Protección de la información de privacidad personal de los usuarios",
    '1. Durante el proceso de registro de una cuenta o uso de esta plataforma, es posible que los usuarios deban completar o enviar alguna información necesaria, como información de identidad que debe completarse según lo estipulado en las leyes, reglamentos y documentos normativos (en adelante denominado como "leyes y reglamentos"). Por ejemplo, si la información enviada por el usuario está incompleta o no cumple con las disposiciones de las leyes y reglamentos, es posible que el usuario no pueda utilizar esta plataforma o que se le restrinja el proceso de usando esta plataforma ',
    "2. La información de privacidad personal se refiere a información que involucra la identidad personal o la privacidad del usuario, como el nombre real del usuario, el número de identificación, el número de teléfono móvil, el código de identificación del dispositivo de teléfono móvil y la dirección IP. La información de privacidad no personal se refiere a la información de privacidad del usuario. operaciones en esta plataforma El estado y los hábitos de uso se reflejan clara y objetivamente en la información de registro básica en el lado del servidor de esta plataforma, otra información general fuera del alcance de la información de privacidad personal y la información de privacidad mencionada anteriormente que los usuarios aceptan revelar. ",
    "3. Respetar la privacidad de la información de privacidad personal de los usuarios es un sistema consistente de esta plataforma. Esta plataforma tomará medidas técnicas y otras medidas necesarias para garantizar la seguridad de la información de privacidad personal de los usuarios y evitar la filtración y daño de la información personal de los usuarios. información de privacidad recopilada en esta plataforma o perdida. Cuando ocurra la situación anterior o la plataforma descubra que existe la posibilidad de que ocurra la situación anterior, se tomarán medidas correctivas de inmediato .",
    "4. Esta plataforma no revelará ni revelará la información de privacidad personal de los usuarios a ningún tercero sin el consentimiento del usuario. Sin embargo, se excluyen las siguientes circunstancias específicas:",
    "(1) Proporcionar información de privacidad personal de los usuarios de acuerdo con las leyes y reglamentos o instrucciones de las autoridades competentes;",
    "(2) Cualquier filtración de información personal causada por usuarios que dicen a otros sus contraseñas de usuario o comparten cuentas y contraseñas registradas con otros, u otras fugas de información de privacidad personal no causadas por esta plataforma;",
    "(3) Los usuarios revelan su información de privacidad personal a terceros por su cuenta;",
    "(4) El usuario ha llegado a un acuerdo con la plataforma y sus socios sobre el uso y divulgación de la información de privacidad personal del usuario y, por lo tanto, la plataforma divulga la información de privacidad personal del usuario al socio;",
    "(5) Cualquier filtración de información de privacidad personal de los usuarios debido a ataques de piratas informáticos, intrusiones de virus informáticos y otros eventos de fuerza mayor.",
    "5. El usuario acepta que esta plataforma puede utilizar la información de privacidad personal del usuario en los siguientes asuntos:",
    "(1) Esta plataforma envía avisos o recordatorios importantes a los usuarios de manera oportuna. Por ejemplo, el software utilizará la información de privacidad personal de los usuarios para cualquier otro propósito.",
    "6. Esta plataforma concede gran importancia a la protección de la información personal de privacidad de los menores. Se basará en la información personal proporcionada por el usuario para determinar si el usuario es menor de edad.",
    "7. El usuario confirma que la información de su ubicación geográfica es información privada no personal. Se considera que el registro exitoso de una cuenta por parte del usuario confirma que la plataforma está autorizada a extraer, revelar y utilizar la información de ubicación geográfica del usuario.",
    "8. Para mejorar la tecnología y los servicios y brindar a los usuarios una mejor experiencia de servicio, esta plataforma puede recopilar y utilizar información de privacidad no personal de los usuarios por sí misma o proporcionársela a terceros.",
    "4. Especificaciones de contenido",
    "1. El contenido mencionado en este artículo se refiere a cualquier contenido producido, cargado, copiado, publicado y difundido por los usuarios durante el uso de esta plataforma, incluidos, entre otros, avatares de cuentas, nombres, descripciones de usuarios y otra información de registro y autenticación. materiales, o texto, voces, imágenes, videos, gráficos, etc., mensajes de respuesta o respuesta automática y páginas de enlaces relacionados, y otro contenido generado mediante el uso de la cuenta o esta plataforma ",
    "2. Los usuarios no podrán utilizar sus cuentas o esta plataforma para crear, cargar, copiar, publicar o difundir contenido prohibido por leyes, reglamentos y políticas:",
    "(1) Oponerse a los principios básicos establecidos por la Constitución;",
    "(2) Poner en peligro la seguridad nacional, filtrar secretos de Estado, subvertir el poder del Estado y socavar la unidad nacional;",
    "",
    "Modificación y Terminación",
    "Revisar",
    "Este Acuerdo permite cambios. Si hay cambios sustanciales en este Acuerdo, se lo notificaremos por correo electrónico. Después de la notificación de cambio, el uso continuo significa que usted es consciente de dichos cambios y acepta estar sujeto a los términos;",
    "",
    "Nos reservamos el derecho de modificar, conservar o cerrar cualquier servicio en cualquier momento sin previo aviso;",
    "",
    "Usted acepta que no tenemos derecho a modificar, conservar o cerrar ningún servicio;",
    "",
    "Usted acepta que no seremos responsables ante usted ni ante terceros por modificar, conservar o cerrar cualquier servicio.",
    "",
    "terminación",
    "Este Acuerdo entrará en vigor a partir de la fecha en que lo acepte y seguirá vigente durante su uso hasta que se rescinda de conformidad con este Acuerdo;",
    "",
    "Sin perjuicio de las disposiciones anteriores, si su uso es anterior al momento en que acepta este Acuerdo, por la presente reconoce y acepta que este Acuerdo entrará en vigor en su primer uso en ese momento, a menos que se rescinda antes de conformidad con este Acuerdo;",
    "",
    "Podemos reservarnos su derecho a usar esta cuenta de acuerdo con las disposiciones de la ley; rescindiremos este Acuerdo en cualquier momento y por cualquier motivo, con o sin previo aviso, incluso si creemos de buena fe que usted ha violado nuestro Uso Aceptable.",
    "Política o este Acuerdo Otras disposiciones del acuerdo;",
    "",
    "Sin estar limitado por las disposiciones del párrafo anterior, si un usuario infringe los derechos de autor de un tercero y recibimos una notificación del propietario de los derechos de autor o del agente legal del propietario de los derechos de autor, nos reservamos el derecho de rescindir este acuerdo;",
    "",
    "Una vez que se rescinda este Acuerdo, su derecho a usarlo terminará. Debe tener en cuenta que la rescisión de su Producto significará que su Contenido de usuario se eliminará de nuestra base de datos activa. No tendremos ninguna responsabilidad ante usted por cualquier rescisión. de este Acuerdo, incluida su cuenta de usuario y la eliminación de su contenido de usuario ",
  ],
  pt_PT: [
    [
      "########Contrato de Utilizador",
      'Antes de se registar ######## para se tornar um utilizador, leia atentamente este Contrato de Utilizador (doravante denominado "Contrato") para garantir que compreende totalmente os termos deste Contrato. Leia com atenção e escolha aceitar ou não este Contrato A menos que aceite todos os termos deste Contrato, não terá o direito de se registar, iniciar sessão ou utilizar os serviços abrangidos por este Contrato. concordância em aceitar os termos do presente Contrato ',
      'Este contrato estipula os direitos e obrigações desta plataforma e dos serviços de software do utilizador (doravante designados por "serviços"). Os "utilizadores" referem-se a indivíduos que se registam, iniciam sessão e utilizam esta plataforma. Este contrato pode ser atualizado por esta plataforma a qualquer momento. Até novo aviso, os utilizadores podem verificar a versão mais recente dos termos do contrato nesta plataforma. Após a modificação dos termos do contrato, caso o utilizador não aceite os termos modificados, pare imediatamente de utilizar os serviços prestados por esta plataforma. os serviços prestados por esta plataforma serão considerados como aceitação do acordo revisto.',
      "1. Registo de conta",
      "1. Os utilizadores podem necessitar de registar uma conta ao utilizar esta plataforma. Esta plataforma pode alterar o registo da conta e os métodos de ligação de acordo com as necessidades do utilizador ou do produto, sem notificar os utilizadores com antecedência.",
      "2. Tendo em conta o método de registo de ligação de conta, concorda em utilizar o número de telemóvel, e-mail e extrair automaticamente o código de identificação do seu dispositivo móvel e outras informações fornecidas durante o registo.",
      "2. Conteúdo do serviço",
      "As funções específicas desta plataforma são fornecidas com base nas condições reais, e o conteúdo do serviço fornecido pode mudar a qualquer momento.",
      "3. Proteção da informação de privacidade pessoal dos utilizadores",
      '1. Durante o processo de registo de uma conta ou utilização desta plataforma, os utilizadores podem necessitar de preencher ou enviar algumas informações necessárias, tais como informações de identidade que necessitam de ser preenchidas conforme estipulado nas leis, regulamentos e documentos normativos (doravante designados como "leis e regulamentos"). Por exemplo, se as informações enviadas pelo utilizador estiverem incompletas ou não cumprirem as disposições das leis e regulamentos, o utilizador poderá não conseguir utilizar esta plataforma ou poderá ser restringido no processo de utilização desta plataforma',
      "2.As informações de privacidade pessoal referem-se a informações que envolvem a identidade ou privacidade pessoal do utilizador, tais como o nome real do utilizador, número de identificação, número de telemóvel, código de identificação do dispositivo de telemóvel e endereço IP. Informações de privacidade não pessoais referem-se ao utilizador operações nesta plataforma O estado e os hábitos de utilização são refletidos de forma clara e objetiva nas informações básicas de registo no lado do servidor desta plataforma, outras informações gerais fora do âmbito das informações de privacidade pessoal e nas informações de privacidade acima mencionadas que os utilizadores concordam em divulgar.",
      "3. Respeitar a privacidade das informações de privacidade pessoal dos utilizadores é um sistema consistente desta plataforma. Esta plataforma tomará as medidas técnicas e outras medidas necessárias para garantir a segurança das informações de privacidade pessoal dos utilizadores e evitar a fuga e danos nas informações pessoais dos utilizadores informações de privacidade recolhidas nesta plataforma ou perdidas Quando a situação acima ocorrer ou a plataforma descobrir que existe a possibilidade de a situação acima acontecer, serão tomadas medidas corretivas imediatamente.",
      "4. Esta plataforma não divulgará ou divulgará informações de privacidade pessoal dos utilizadores a terceiros sem o consentimento do utilizador. No entanto, as seguintes circunstâncias específicas estão excluídas:",
      "(1) Fornecer informações de privacidade pessoal dos utilizadores de acordo com as leis e regulamentos ou instruções das autoridades competentes;",
      "(2) Qualquer fuga de informação pessoal causada por utilizadores que informem terceiros das suas palavras-passe de utilizador ou partilhem contas e palavras-passe registadas com terceiros, ou outras fugas de informação de privacidade pessoal não causadas por esta plataforma;",
      "(3) Os utilizadores divulgam as suas informações pessoais de privacidade a terceiros por conta própria;",
      "(4) O utilizador chegou a acordo com a plataforma e os seus parceiros sobre a utilização e divulgação das informações de privacidade pessoal do utilizador e, por conseguinte, a plataforma divulga as informações de privacidade pessoal do utilizador ao parceiro;",
      "(5) Qualquer fuga de informação de privacidade pessoal dos utilizadores devido a ataques de hackers, invasões de vírus informáticos e outros eventos de força maior.",
      "5. O utilizador concorda que esta plataforma pode utilizar as informações de privacidade pessoal do utilizador nos seguintes assuntos:",
      "(1) Esta plataforma envia avisos ou lembretes importantes aos utilizadores em tempo útil. Por exemplo, o software utilizará as informações de privacidade pessoal dos utilizadores para qualquer outra finalidade.",
      "6. Esta plataforma atribui grande importância à proteção das informações de privacidade pessoal dos menores. Basear-se-á nas informações pessoais fornecidas pelo utilizador para determinar se o utilizador é menor.",
      "7. O utilizador confirma que as suas informações de localização geográfica são informações privadas não pessoais. O registo bem-sucedido de uma conta pelo utilizador é considerado uma confirmação de que a plataforma está autorizada a extrair, divulgar e utilizar as informações de localização geográfica do utilizador.",
      "8. A fim de melhorar a tecnologia e os serviços e proporcionar aos utilizadores uma melhor experiência de serviço, esta plataforma pode recolher e utilizar informações de privacidade não pessoais dos utilizadores por conta própria ou fornecê-las a terceiros.",
      "4. Especificações de conteúdo",
      "1. O conteúdo mencionado neste artigo refere-se a qualquer conteúdo produzido, carregado, copiado, publicado e divulgado pelos utilizadores durante a utilização desta plataforma, incluindo, mas não se limitando a avatares de contas, nomes, descrições de utilizadores e outras informações de registo e autenticação materiais, ou texto, vozes, imagens, vídeos, gráficos, etc., mensagens de resposta ou resposta automática e páginas de links relacionados e outros conteúdos gerados pela utilização da conta ou desta plataforma",
      "2. Os utilizadores não podem utilizar as suas contas ou esta plataforma para criar, carregar, copiar, publicar ou divulgar conteúdos proibidos por leis, regulamentos e políticas:",
      "(1) Oposição aos princípios básicos estabelecidos pela Constituição;",
      "(2) Pôr em risco a segurança nacional, vazar segredos de Estado, subverter o poder do Estado e minar a unidade nacional;",
      "",
      "Modificação e Rescisão",
      "Rever",
      "Este Contrato permite alterações. Se houver quaisquer alterações substanciais neste Contrato, iremos notificá-lo por e-mail. Após a notificação de alteração, a utilização continuada significa que está ciente de tais alterações e concorda em ficar vinculado aos termos;",
      "",
      "Reservamo-nos o direito de modificar, reter ou terminar qualquer serviço a qualquer momento sem aviso prévio;",
      "",
      "Concorda que não temos o direito de modificar, reter ou terminar qualquer serviço;",
      "",
      "Concorda que não seremos responsáveis ​​perante si ou terceiros pela modificação, retenção ou encerramento de quaisquer serviços.",
      "",
      "rescisão",
      "Este Contrato entrará em vigor a partir da data em que o aceitar e continuará em vigor durante a sua utilização até ser rescindido de acordo com este Contrato;",
      "",
      "Não obstante as disposições acima, se a sua utilização for anterior ao momento em que aceita este Contrato, reconhece e concorda que este Contrato entrará em vigor na sua primeira utilização no momento, a menos que seja rescindido anteriormente de acordo com este Contrato ;",
      "",
      "Podemos reservar o seu direito de utilizar esta conta de acordo com as disposições da lei; rescindiremos este Contrato a qualquer momento e por qualquer motivo, com ou sem aviso prévio, incluindo na crença de boa fé de que violou a nossa Política de Utilização Aceitável ou este Contrato Outras disposições do contrato;",
      "",
      "Não limitado pelas disposições do parágrafo anterior, se um utilizador infringir os direitos de autor de terceiros e recebermos uma notificação do proprietário dos direitos de autor ou do representante legal do proprietário dos direitos de autor, reservamo-nos o direito de rescindir este contrato;",
      "",
      "Assim que este Contrato for rescindido, o seu direito de utilização será encerrado. Deve estar ciente de que a rescisão do seu Produto significará que o seu Conteúdo de Utilizador será eliminado da nossa base de dados ativa. Não teremos qualquer responsabilidade perante si por qualquer rescisão deste Contrato, incluindo a sua conta de utilizador e a eliminação do seu conteúdo de utilizador",
    ],
  ],
};
